import i18n from 'i18n/config';

import { TSectionPermission } from 'store/config';

import {
  CHAT,
  GAME,
  LEADER_BOARD,
  BETS,
  MY_BETS,
  TOURNAMENTS,
  PROFILE,
  BETS_MOBILE,
} from 'constants/nameTabs';

export type THeaderTabItem = {
  title: string;
  tab: string;
  isHide?: boolean;
  desktopOnly?: boolean;
  isAvailable: boolean;
  isOpen: boolean;
  iconName?: string;
  permissionKey?: TSectionPermission;
};

export type TGetHeaderTabs = (
  availContent: TSectionPermission[],
) => THeaderTabItem[];

const getHeaderTabs: TGetHeaderTabs = (availContent) =>
  [
    {
      title: i18n.t('game'),
      tab: GAME,
      isHide: true,
      isAvailable: true,
      isOpen: false,
    },
    {
      title: i18n.t('bets'),
      tab: BETS,
      desktopOnly: true,
      isAvailable: true,
      isOpen: false,
    },
    {
      title: i18n.t('chat'),
      tab: CHAT,
      isHide: true,
      isOpen: true,
      isAvailable: false,
      permissionKey: 'chat',
    },
    {
      title: i18n.t('tournaments'),
      tab: TOURNAMENTS,
      iconName: 'ic_cup_small',
      isOpen: false,
      isAvailable: false,
      permissionKey: 'tnt',
    },
    {
      title: i18n.t('profile'),
      tab: PROFILE,
      isOpen: false,
      isAvailable: false,
      permissionKey: 'usr',
    },
    {
      title: i18n.t('highScores'),
      tab: LEADER_BOARD,
      isOpen: false,
      isAvailable: false,
      permissionKey: 'hof',
    },
    {
      title: i18n.t('bets'),
      tab: BETS_MOBILE,
      isHide: true,
      isAvailable: true,
      isOpen: false,
    },
    {
      title: i18n.t('myBets'),
      tab: MY_BETS,
      isOpen: false,
      isAvailable: false,
      permissionKey: 'bh',
    },
  ].map(
    ({ permissionKey, isAvailable, ...data }) =>
      ({
        ...data,
        permissionKey,
        isAvailable:
          isAvailable ||
          (permissionKey &&
            availContent.includes(permissionKey as TSectionPermission)),
      } as THeaderTabItem),
  );

export default getHeaderTabs;
