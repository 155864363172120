import { FC } from 'react';

import Header from './Header';
import MobileHeader from './MobileHeader';
import { Container } from './style';
import { TDesktopLayoutProps } from './types';

const ChatLayout: FC<TDesktopLayoutProps> = ({
  children,
  toggleVisibleChat,
  toggleExpandChat,
  isFullScreen,
}) => (
  <Container>
    <Header
      toggleVisibleChat={toggleVisibleChat}
      toggleExpandChat={toggleExpandChat}
      isFullScreen={isFullScreen}
    />
    <MobileHeader
      toggleExpandChat={toggleExpandChat}
      isFullScreen={isFullScreen}
    />
    {children}
  </Container>
);

export default ChatLayout;
