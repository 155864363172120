import { useEffect, FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useResizeDetector } from 'react-resize-detector';

import { AStartTime } from 'store/time';
import { AIsOpenChat, AIsFullScreen } from 'store/chat';
import { AScreenHeight } from 'store/screenHeight';

import SessionTimer from 'components/SessionTimer';

import { getUrlParams } from 'shared/helpers/parseUrl';

import ScreenHeader from '../ScreenHeader';
import CanvasWrapper from '../GameWrapper';

import { SScreenWrapper, SScreenInner, SScreenBottom } from './style';
import { IGameScreenProps } from './types';

const GameScreen: FC<IGameScreenProps> = () => {
  const { show_time } = getUrlParams();
  const setStartTime = useSetRecoilState(AStartTime);
  const { ref: screenRef, height } = useResizeDetector();

  const isFullScreenChat = useRecoilValue<boolean>(AIsFullScreen);
  const isChatOpen = useRecoilValue(AIsOpenChat);
  const setScreenHeight = useSetRecoilState(AScreenHeight);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (height) setScreenHeight(height);
  }, [height]);

  const hideScreen =
    !isFullScreenChat ||
    !isChatOpen ||
    !window.matchMedia('(max-width: 720px)').matches;

  return hideScreen ? (
    <SScreenWrapper>
      <SScreenInner ref={screenRef}>
        <CanvasWrapper />
        <ScreenHeader />
        <SScreenBottom>{show_time && <SessionTimer />}</SScreenBottom>
      </SScreenInner>
    </SScreenWrapper>
  ) : (
    <ScreenHeader />
  );
};

export default GameScreen;
