import styled from 'styled-components';

export const SLoading = styled.div`
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(var(--lvl-2), 1);
  color: rgba(var(--txt-placeholder), 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SWrapper = styled.div`
  width: 100%;
  height: calc(100% - 25px);
`;
