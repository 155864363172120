import styled, { css } from 'styled-components';

const contentStyle = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SContainer = styled.div`
  font-family: 'Rubik', sans-serif;
  width: 360px;
  height: 99%;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: var(--z-index-10000);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 18px 2px rgba(var(--primary-1), 0.5);
  background-color: rgba(var(--lvl-0), 1);

  :after {
    pointer-events: none;
    content: '';
    height: 82px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(var(--dark), 0), rgba(var(--dark), 1));

    @media (max-width: 500px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    right: 15px;
  }

  @media (max-width: 500px) {
    top: 5px;
    height: 98%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 360px) {
    padding: 8px;
    width: 320px;
  }
`;

export const SWrapper = styled.div`
  font-family: 'Rubik', sans-serif;
  height: 100%;
  width: 100%;
  position: relative;
  .no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const SInfoWrapper = styled.div`
  font-family: 'Rubik', sans-serif;
  height: 100%;
  width: 100%;
  padding: 16px 16px 80px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
`;

export const SLogo = styled.img`
  display: block;
  object-fit: contain;
  height: 80px;
  min-height: 80px;
  margin-bottom: 10px;
`;

export const STitle = styled.h2`
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: rgba(var(--txt-h1), 1);
`;

export const SSubInfo = styled.span`
  ${contentStyle};
  margin-bottom: 15px;
`;

export const SSubTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(var(--txt-h2-primary-2), 1);
`;

export const SSubText = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: rgba(var(--txt-h2-primary-2), 1);
`;

export const SContent = styled.div`
  ${contentStyle};
`;

export const SContentTitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
  color: rgba(var(--primary-1), 1);
`;

export const SContentInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: rgba(var(--txt-normal), 1);
`;

export const SComponentImg = styled.img<{ isFullWidth?: boolean }>`
  display: block;
  max-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '50%')};
  margin: 6px 0;
`;

export const SBtnWrapper = styled.div`
  ${contentStyle};
`;

export const SInfoItem = styled.div`
  margin: 15px 0;
`;

export const SCloseBtn = styled.button`
  font-family: 'Rubik', sans-serif;
  height: 42px;
  max-width: 240px;
  width: 100%;
  background-color: rgba(var(--primary-1), 1);
  box-shadow: 0 2px 5px rgba(var(--shdw-primary-1));
  color: rgba(var(--txt-h2-dark), 1);
  font-weight: 500;
  font-size: 18px;
  border-radius: 6px;
  text-transform: uppercase;
  border: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: var(--z-index-500);
  transform: translateX(-50%);

  :hover {
    color: rgba(var(--txt-white), 1);
  }
`;

export const SContentSubTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(var(--txt-h2), 1);
  text-transform: uppercase;
`;

export const SLastTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(var(--txt-h2-primary-2), 1);
`;
