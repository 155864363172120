import styled from 'styled-components';

export const SList = styled.div<{ isFullHeight?: boolean }>`
  width: 100%;
  height: ${({ isFullHeight }) =>
    isFullHeight ? '100%' : 'calc(100% - 18px)'};
  min-height: 20px;
  overflow: hidden;
  position: relative;

  .no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
