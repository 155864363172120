export const API_METHOD_TIME = 'time';
export const API_METHOD_MESSAGES = 'msgs';
export const API_METHOD_SEND_MESSAGE = 'sendMsg';
export const API_METHOD_REPORT_MESSAGE = 'reportMsg';
export const API_METHOD_TRANSLATIONS = 'transl';
export const API_METHOD_AUTH = 'auth';
export const API_METHOD_AUTH_TEST = 'testAuth';
export const API_METHOD_LB_COEFFICIENT = 'leaderBoardCoefficient';
export const API_METHOD_LB_WINS = 'leaderBoardWin';
export const API_TOURNAMENTS = 'tournaments';
export const API_TOURNAMENT_IS_APPLIED = 'isTournamentApplied';
export const API_TOURNAMENT_APPLY = 'tournamentApply';
export const API_GET_TOURNAMENT_LEADERBOARDS = 'getTournamentLeaderBoard';
export const API_GET_AVATARS = 'getAvatars';
export const API_UPDATE_AVATAR = 'updateAvatar';
