import { FC, memo } from 'react';
import { areEqual } from 'react-window';

import BetsItem from '../BetsItem';

import { TRowProps } from './types';

const Row: FC<TRowProps> = memo(({ data, index, style }) => {
  const { n, a, w, i, m, wa, bf, bm, toFixedValue, currency, my } = data[index];

  return (
    <BetsItem
      n={n}
      a={a}
      w={w}
      i={i}
      m={m}
      wa={wa}
      bf={bf}
      bm={bm}
      currency={currency}
      toFixed={toFixedValue}
      style={style}
      isActive={my}
    />
  );
}, areEqual);

export default Row;
