import { FC } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';

import { ATabDimension } from 'store/tabs';
import { getItemsS, paramsA } from 'store/leaderboard/items';

import ListLayouts from 'shared/components/ListLayouts/ListLayouts';

import { FilterType } from 'constants/leaderBoard';

import LeaderItem from './LeaderItem';
import { SWrapper, SLoading } from './style';
import { ILeaderBoardProps } from './types';
import Filters from './Filters';
import { IFilters } from './Filters/types';

const LeaderBoard: FC<ILeaderBoardProps> = () => {
  const { t } = useTranslation();
  const { height } = useRecoilValue(ATabDimension);

  const [filters, setFilters] = useRecoilState(paramsA);

  const isWinsType = [FilterType.BIGWINS, FilterType.HUGEWINS].includes(
    filters.type,
  );

  const data = useRecoilValueLoadable(getItemsS);

  const handleFilter = (newFilter: IFilters) => {
    setFilters(newFilter);
  };

  return (
    <SWrapper>
      <Filters items={filters} onChange={handleFilter} />
      <ListLayouts>
        {data.state === 'hasError' && <SLoading>{t('noData')}</SLoading>}
        {data.state === 'loading' && <SLoading>{t('loading')}</SLoading>}
        {data.state === 'hasValue' &&
          (height && data.contents.items.length > 0 ? (
            <List
              className="no-scrollbars"
              height={height - 65}
              itemCount={data.contents.items.length}
              itemSize={isWinsType ? 96 : 56}
              itemData={data.contents.items}
              width="100%"
            >
              {(p) => <LeaderItem isWins={isWinsType} {...p} />}
            </List>
          ) : (
            <SLoading>{t('noData')}</SLoading>
          ))}
      </ListLayouts>
    </SWrapper>
  );
};

export default LeaderBoard;
