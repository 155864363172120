import { useState, FC } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { AInitialData } from 'store/init';

import gameManager from 'api/gameManager/GameManager';

import Icon from 'shared/components/Icon';

import Avatars from './Avatars';
import {
  SWrapper,
  SMarker,
  SHeader,
  STitleWrapper,
  SUserTitle,
  SIconWrapper,
  SAvatarImg,
  SEditButton,
  SActionBtn,
} from './style';
import { IProfileProps } from './types';

const Profile: FC<IProfileProps> = () => {
  const { t } = useTranslation();
  const {
    chat: { rp, aka: userName, al },
  } = useRecoilValue(AInitialData);

  const [isOpenAvatar, setIsOpenAvatar] = useState<boolean>(false);

  const editAvatar = () => {
    setIsOpenAvatar(true);
  };

  const closeAvatar = () => {
    setIsOpenAvatar(false);
  };

  const handleToLobby = () => {
    gameManager.navigateToLobbyURL();
  };

  return (
    <SWrapper>
      <SMarker>{t('you')}</SMarker>
      <SHeader>
        <SIconWrapper>
          {al ? <SAvatarImg src={al} /> : <Icon name="ic_chatavatar" />}
          {rp && (
            <SEditButton onClick={editAvatar} title={t('changeAvatar')}>
              <Icon name="ic_edit" />
            </SEditButton>
          )}
        </SIconWrapper>
      </SHeader>
      <STitleWrapper>
        <SUserTitle>{userName || ''}</SUserTitle>
      </STitleWrapper>

      {gameManager.showLobbyBtn() && (
        <STitleWrapper>
          <SActionBtn onClick={handleToLobby}>{t('toLobby')}</SActionBtn>
        </STitleWrapper>
      )}

      {isOpenAvatar && <Avatars onClose={closeAvatar} />}
    </SWrapper>
  );
};

export default Profile;
