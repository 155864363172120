import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'shared/components/Icon';
import Span from 'shared/components/Span';

import {
  SWrapperPopup,
  SCloseButton,
  SPopupScreenContent,
  SPopupScreenTitle,
} from './style';
import { IInfoPopupProps } from './types';

export const InfoPopup: FC<IInfoPopupProps> = ({ text, onClose }) => {
  const { t } = useTranslation();

  return (
    <SWrapperPopup>
      <SPopupScreenTitle>{t('termsAndConditions')}</SPopupScreenTitle>
      <SCloseButton onClick={onClose}>
        <Icon name="ic_arrowleft" />
      </SCloseButton>
      <SPopupScreenContent>
        <Span html={text} isBlock />
      </SPopupScreenContent>
    </SWrapperPopup>
  );
};
