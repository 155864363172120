import { useEffect, useState, useRef, FC } from 'react';
import { useSetRecoilState } from 'recoil';

import { AScrollHolder } from 'store/chat';

import { defaultScrollHolder } from 'components/Chat/constants/defaultObjects';

import MessageListItem from './MessageListItem';
import { Wrapper } from './style';
import { TMessageListProps } from './types';

const MessageList: FC<TMessageListProps> = ({
  messages,
  handleReportMessage,
  accountName,
  language,
  handleGetRecipient,
  onShowProfile,
  onShowInfoPopup,
  stickers,
  stickersMap,
}) => {
  //const bottomRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const setScrollHolder = useSetRecoilState(AScrollHolder);
  const scrollToBottom = (isSmooth = false) => {
    // eslint-disable-next-line no-undef
    /** 
    const scrollRule: ScrollIntoViewOptions = {
      block: 'start',
      behavior: 'auto',
    };

    if (isSmooth) {
      scrollRule.behavior = 'smooth';
    }
    
    bottomRef?.current?.scrollIntoView(scrollRule);
    */

    if (wrapperRef?.current) {
      wrapperRef.current.scrollTop = wrapperRef.current?.scrollHeight;
    }
  };

  const isBottom = () => {
    return (
      wrapperRef &&
      wrapperRef.current !== null &&
      wrapperRef.current?.scrollHeight -
        wrapperRef.current?.clientHeight -
        wrapperRef.current?.scrollTop <=
        20
    );
  };

  useEffect(() => {
    scrollToBottom();
    setScrollHolder({
      isScrollBottom: () => {
        return isBottom();
      },
      scrollBottom: () => {
        scrollToBottom();
      },
    });

    return () => {
      setScrollHolder(defaultScrollHolder);
    };
  }, []);

  const handleItemHover = (id: number | null) => {
    setActiveRowId(id);
  };

  return (
    <Wrapper ref={wrapperRef} className="no-scrollbars">
      {messages.map(
        ({
          id,
          from: userName,
          st: senderType,
          s_id: stickerId,
          g_id: groupId,
          t: message,
          tt: typeMessage,
          a_l: icon,
          to: recipient,
          ct: contentType,
          sm: serviceMessage,
          s: defaultSticker,
        }) => (
          <MessageListItem
            key={id}
            id={id}
            handleItemHover={handleItemHover}
            activeRowId={activeRowId}
            userName={userName}
            accountName={accountName}
            message={message}
            icon={icon}
            typeMessage={typeMessage}
            handleReportMessage={handleReportMessage}
            recipient={recipient}
            contentType={contentType}
            senderType={senderType}
            stickerId={stickerId}
            defaultSticker={defaultSticker}
            groupId={groupId}
            serviceMessage={serviceMessage}
            language={language}
            stickers={stickers}
            stickersMap={stickersMap}
            handleGetRecipient={handleGetRecipient}
            onShowProfile={onShowProfile}
            onShowInfoPopup={onShowInfoPopup}
          />
        ),
      )}
      {/* <div ref={bottomRef} /> */}
    </Wrapper>
  );
};

export default MessageList;
