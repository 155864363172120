const multiplier = 1000000;

export const calculateValuesCount = (
  min: number,
  max: number,
  step: number,
): number => {
  try {
    let count: number = Math.floor(
      Math.floor(max * multiplier - min * multiplier) /
        Math.floor(step * multiplier),
    );

    if (
      Math.floor(count * step * multiplier) + Math.floor(min * multiplier) <
      Math.floor(max * multiplier)
    ) {
      count++;
    }

    return Math.max(0, count);
  } catch (e) {
    return 0;
  }
};

export const calculateSliderPosition = (
  value: number,
  length: number,
  min: number,
  max: number,
  step: number,
): number => {
  return calculatePosition(value, min, max, step) / length;
};

export const calculatePosition = (
  value: number,
  min: number,
  max: number,
  step: number,
): number => {
  try {
    return Math.max(
      0,
      Math.floor(
        Math.floor(Math.min(max, value) * multiplier - min * multiplier) /
          (step * multiplier) +
          0.5,
      ),
    );
  } catch (e) {
    return 0;
  }
};

export const calculateSliderValue = (
  sliderPosition: number,
  length: number,
  min: number,
  max: number,
  step: number,
): number => {
  return calculateValue(
    Math.floor(length * sliderPosition),
    length,
    min,
    max,
    step,
  );
};

export const calculateValue = (
  position: number,
  length: number,
  min: number,
  max: number,
  step: number,
): number => {
  if (position >= length) {
    return max;
  }

  if (position <= 0) {
    return min;
  }

  try {
    return Math.max(
      Math.min(
        max,
        Math.floor(min * multiplier + position * step * multiplier) /
          multiplier,
      ),
      min,
    );
  } catch (e) {
    return Math.max(0, min);
  }
};
