import { FC } from 'react';

import Icon from 'shared/components/Icon';

import { PopoverWrapper, Text } from './style';
import { TPopoverProps } from './types';

const Popover: FC<TPopoverProps> = ({ onClick, text }) => (
  <PopoverWrapper onClick={onClick}>
    <Icon name="ic_report" />
    <Text>{text}</Text>
  </PopoverWrapper>
);

export default Popover;
