import styled, { css } from 'styled-components';

const BUTTON_WIDTH = 44;

export const SWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  border-radius: 4px;
  touch-action: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      & > div {
        pointer-events: none;
      }
    `}
`;

export const SInner = styled.div`
  display: flex;
  height: 56px;
`;

export const SLeft = styled.div<{ isBorder?: boolean }>`
  flex: 0 0 ${BUTTON_WIDTH}px;
  background: rgba(var(--lvl-2), 1);
  overflow: hidden;
`;

export const SRight = styled.div<{ isBorder?: boolean }>`
  flex: 0 0 ${BUTTON_WIDTH}px;
  background: rgba(var(--lvl-2), 1);
  overflow: hidden;
`;

export const SCenter = styled.div<{ isBorder?: boolean; disabled?: boolean }>`
  flex: 0 0 calc(100% - 2px - ${BUTTON_WIDTH * 2}px);
  max-width: calc(100% - 2px - ${BUTTON_WIDTH * 2}px);
  position: relative;
  background: rgba(var(--lvl-2), 1);
  margin: 0 1px;

  &:not(:disabled):hover {
    filter: brightness(1.05);
  }

  &:not(:disabled):active {
    filter: brightness(0.95);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      & > div {
        opacity: 0.2;
      }
    `};
`;

export const SCenterOver = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
`;

export const SLabel = styled.span`
  display: flex;
  width: 100%;
  border: none;
  color: rgba(var(--bet-select-cap), 0.5);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 auto;
  justify-content: center;
`;

export const SLabelText = styled.span`
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SLabelUnit = styled.span`
  margin-left: 3px;
`;

export const SValue = styled.span`
  display: block;
  width: 100%;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
  height: 22px;
  border: none;
  color: rgba(var(--bet-select-amount), 1);
`;

export const SCenterCover = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const SCenterCoverInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  transition: all 0.15s;
`;

export const SButton = styled.button<{ mode?: 'primary'; textSize?: 'lg' }>`
  background: none;
  width: 100%;
  height: 50%;
  border: none;
  font-weight: 400;
  text-align: center;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  color: rgba(var(--bet-select-state1), 1);
  background: rgba(var(--lvl-2), 1);
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s;

  ${({ mode }) =>
    mode === 'primary' &&
    css`
      color: rgba(var(--primary-1), 1);
    `}

  ${({ textSize }) =>
    textSize === 'lg' &&
    css`
      font-size: 24px;
    `}

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    transform: translate(0, 3px);
    color: rgba(var(--white), 1);
  }
`;

export const SProgress = styled.span`
  display: block;
  position: absolute;
  min-width: 10px;
  background: linear-gradient(
    90deg,
    rgba(var(--bet-select-txt), 0) 0%,
    rgba(var(--bet-select-txt), 0.5) 50%,
    rgba(var(--bet-select-txt), 1) 100%
  );
  height: 4px;
  bottom: 0;
  pointer-events: none;
  width: 50%;
`;

export const SInput = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  width: calc(100% - 10px);
  height: 100%;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
