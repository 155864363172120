import { FC } from 'react';

import Icon from 'shared/components/Icon';

import Timer from '../Timer';
import { TStickers } from '../../../../types';

import {
  Wrapper,
  StickersWrapper,
  Controls,
  TextBtn,
  StickersItem,
  StickersItemImg,
} from './style';
import { TWordsSectionProps } from './types';

const StickersSection: FC<TWordsSectionProps> = ({
  hideWordsSection,
  handleSendWord,
  stickers,
  syncTime,
  nextMessageTimeRef,
}) => (
  <Wrapper>
    <StickersWrapper>
      {stickers.map(({ id, t: sticker, m: isMedia, l: link }: TStickers) => (
        <>
          {isMedia ? (
            <StickersItemImg
              key={`${sticker}-${id}`}
              onClick={(e) => handleSendWord(e, id)}
              src={link}
            />
          ) : (
            <StickersItem
              key={`${sticker}-${id}`}
              onClick={(e) => handleSendWord(e, id)}
            >
              {sticker}
            </StickersItem>
          )}
        </>
      ))}
    </StickersWrapper>

    <Controls>
      <TextBtn onClick={hideWordsSection}>
        <Icon name="ic_text" />
      </TextBtn>

      <Timer nextMessageTimeRef={nextMessageTimeRef} syncTime={syncTime} />
    </Controls>
  </Wrapper>
);

export default StickersSection;
