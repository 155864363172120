import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
`;

export const StickersWrapper = styled.div`
  background-color: rgba(var(--lvl-2), 1);
  width: 100%;
  max-height: 124px;
  position: absolute;
  overflow-y: auto;
  bottom: 44px;
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  z-index: 70;
`;

export const Controls = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 4px 0 10px;
`;

export const TextBtn = styled.button`
  width: 28px;
  height: 28px;
  background-color: transparent;
  cursor: pointer;
  right: 0;
  top: 5px;
  padding: 0;
  margin: 0;
  border: 0;

  [data-icon] {
    color: rgba(var(--primary-1), 1);
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StickersItem = styled.span`
  background-color: rgba(var(--lvl-0), 1);
  font-weight: 400;
  font-size: 15px;
  color: rgba(var(--txt-white), 1);
  padding: 6px;
  margin: 3px;
  cursor: pointer;
`;

export const StickersItemImg = styled.img`
  background-color: rgba(var(--lvl-0), 1);
  padding: 6px;
  margin: 3px;
  max-height: 80px;
  max-widht: 160px;
  cursor: pointer;
`;
