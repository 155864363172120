import { FC, useRef } from 'react';

import { useClickOutside } from 'shared/hooks/useClickOutside';
import { useDragScroll } from 'shared/hooks/useDragScroll';

import { autoOptions } from 'constants/autoOptions';

import { SWrapper, STitle, SOptions, SOption } from './style';
import { TAutoOptionProps } from './types';

const AutoOption: FC<TAutoOptionProps> = ({
  optionTitle,
  handleOption,
  onClickOutside,
}) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  const elOptionsRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(elRef, onClickOutside);
  useDragScroll(elOptionsRef);

  return (
    <SWrapper ref={elRef}>
      <STitle>{optionTitle}</STitle>
      <SOptions className="no-scrollbars" ref={elOptionsRef}>
        {autoOptions.map(({ title, value }) => (
          <SOption key={value} onClick={() => handleOption(value)}>
            {title}
          </SOption>
        ))}
      </SOptions>
    </SWrapper>
  );
};

export default AutoOption;
