import { atom } from 'recoil';

import { TIME, START_TIME } from 'store/constants';

export const ATime = atom<number>({
  key: TIME,
  default: Date.now(),
});

export const AStartTime = atom<number>({
  key: START_TIME,
  default: Date.now(),
});
