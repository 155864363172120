import styled, { css } from 'styled-components';

export const SWrapper = styled.div<{ isFullheight: boolean }>`
  padding: 0 0 8px;
  height: 56px;

  ${({ isFullheight }) =>
    isFullheight &&
    css`
      height: 96px;
    `}
`;

export const SInner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: rgba(var(--lvl-1), 1);
  border-radius: 4px;
  padding: 8px 6px 0;
  gap: 10px;
  height: 100%;
`;

export const SItem = styled.div`
  flex: 1;
  overflow: hidden;

  &:first-child {
    flex: 0 0 150px;
  }
`;
