import { useEffect, useState, FC } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { debounce } from 'lodash';

import i18n, { DEFAULT_NS } from 'i18n/config';

import { AIsOpenInfoBanner } from 'store/infoBanner';
import {
  AAvailableContent,
  APreloader,
  FLAGS_ALL,
  AVAILABLE_CHAT,
  AUiConfig,
  IUiConfig,
} from 'store/config';
import { AScreenHeight } from 'store/screenHeight';
import { AModalSelector } from 'store/modal';
import { AUI } from 'store/ui';
import { AResize } from 'store/resize';

import useNotificationManger from 'api/notificationManager/useNotificationManager';
import useGameManager from 'api/gameManager/useGameManager';
import gameManager from 'api/gameManager/GameManager';
import services from 'api/services';

import LockScreen from 'components/LockScreen';
import ResultsHeader from 'components/ResultsHeader';
import GameScreen from 'components/GameScreen';
import Controllers from 'components/Controllers';
import GameTabs from 'components/GameTabs';
import Chat from 'components/Chat';
import InfoBanner from 'components/InfoBanner';
import ToastProvider from 'components/ToastProvider';
import Modal from 'components/Modal';
import Preloader from 'components/Preloader';
import PlayingTimer from 'components/PlayingTimer';

import DateProvider from 'shared/components/DateProvider';
import { isMobile } from 'shared/helpers/isMobile';
import { getUrlParams } from 'shared/helpers/parseUrl';
import { getScreenSize } from 'shared/helpers/getScreenSize';

import {
  SContainer,
  SInner,
  SScreen,
  SSidebar,
  SResultsDesktop,
} from './style';

const App: FC = () => {
  const { reality_check_time, disable_autobet, disable_autocollect } =
    getUrlParams();

  const isOpenInfoBanner = useRecoilValue(AIsOpenInfoBanner);
  const isGameVisible = useRecoilValue(APreloader);
  const screenHeight = useRecoilValue(AScreenHeight);
  const availableContent = useRecoilValue(AAvailableContent);
  const modal = useRecoilValue(AModalSelector);
  const ui = useRecoilValue(AUI);
  const [resize, setResize] = useRecoilState(AResize);
  const setUIConfig = useSetRecoilState<IUiConfig>(AUiConfig);

  const [isMobileDevice] = useState<boolean>(isMobile());

  setUIConfig({
    disableAutobet: disable_autobet === 'true',
    disableAutocollect: disable_autocollect === 'true',
  });

  useNotificationManger();
  useGameManager();

  const handleTabClosing = () => {
    gameManager.terminateSession();
  };

  const fetchTranslations = async () => {
    const data = await services.getTranslations();

    Object.keys(data).forEach((lang) => {
      i18n.addResources(lang, DEFAULT_NS, data[lang]);
    });
  };

  const setAppHeight = (isPortrait: boolean) => {
    document.documentElement.style.setProperty(
      '--app-height',
      isPortrait ? `${document.documentElement.clientHeight}px` : '100%',
    );
  };

  useEffect(() => {
    fetchTranslations();

    //setAppHeight();

    //window.addEventListener('resize', setAppHeight);
    window.addEventListener('beforeunload', handleTabClosing);

    return () => {
      // window.addEventListener('resize', setAppHeight);
      window.removeEventListener('beforeunload', handleTabClosing);
    };
  }, []);

  useEffect(() => {
    if (ui) {
      document.documentElement.style.removeProperty('pointer-events');
    } else {
      document.documentElement.style.setProperty('pointer-events', 'none');
    }
  }, [ui]);

  useEffect(() => {
    const debouncedResize = debounce(
      (elApp: HTMLElement, width: number, height: number) => {
        const size = elApp.getBoundingClientRect();

        setAppHeight(width <= 720);

        if (width > 720 && size.height > height) {
          const scale = Math.min(height / size.height, 1);
          elApp.style.transform = `scale(${Math.min(1, scale)})`;
          elApp.style.top = `${scale < 1 ? (height - size.height) / 2 : 0}px`;
          setResize((prev) => {
            return { scale, trigger: !prev.trigger, isPortrait: false };
          });
        } else {
          setResize((prev) => {
            return {
              scale: 1,
              trigger: !prev.trigger,
              isPortrait: width <= 720,
            };
          });
        }
      },
      100,
    );

    const update = () => {
      const elApp = document.getElementById('app');
      if (elApp) {
        const { width: SW, height: SH } = getScreenSize();
        elApp.removeAttribute('style');
        debouncedResize(elApp, SW, SH);
      }
    };

    update();

    window.addEventListener('resize', update, false);

    return () => {
      window.removeEventListener('resize', update, false);
    };
  }, []);

  return (
    <DateProvider lang="en">
      {isGameVisible !== FLAGS_ALL ? (
        <Preloader />
      ) : (
        !modal?.isNeededAuth && (
          <>
            <SContainer id="app">
              <SInner>
                <SScreen>
                  {isOpenInfoBanner && <InfoBanner />}

                  <SResultsDesktop>
                    <ResultsHeader />
                  </SResultsDesktop>
                  <GameScreen />

                  <Controllers />
                </SScreen>

                <SSidebar screenHeight={screenHeight}>
                  <GameTabs />
                </SSidebar>

                {availableContent?.includes(AVAILABLE_CHAT) && <Chat />}
                {reality_check_time && (
                  <PlayingTimer time={reality_check_time} />
                )}
              </SInner>
            </SContainer>
            <ToastProvider />
            {isMobileDevice && <LockScreen />}
          </>
        )
      )}
      <Modal />
    </DateProvider>
  );
};

export default App;
