import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { debounce } from 'lodash';

import { AAutoButtons } from 'store/gameButtons/autoButtons';
import { AUiConfig } from 'store/config';

import gameManager from 'api/gameManager/GameManager';

import FreeBet from 'components/FreeBet';

import { COLLECT, BET } from 'constants/autoButtons';
import {
  BORDER_S,
  BOTTOM_BUTTON_S,
  TOP_BUTTON_S,
} from 'constants/controllersStyle';

import MakeBtn from '../MakeBtn';
import Controller from '../Controller';
import AutoBtn from '../AutoBtn';
import AutoOption from '../AutoOption';

import {
  SWrapper,
  SContainer,
  SControllerWrapper,
  SAutoOptionsWrapper,
  SController,
  SAutoBtnWrapper,
} from './style';
import { TGroupControllersProps } from './types';

const GroupControllers: FC<TGroupControllersProps> = ({
  bet,
  collect,
  freeBet,
  onChange,
  btnClickHandler,
  status,
  price,
  priceFreeBets,
  disabled,
  changeDisabled,
  autoBtnType,
}) => {
  const { t } = useTranslation();
  const [stateAutoBtns, setStateAutoBtns] = useRecoilState(AAutoButtons);
  const { disableAutobet, disableAutocollect } = useRecoilValue(AUiConfig);

  const handleAutoClick = (event: SyntheticEvent, type: string) => {
    event.preventDefault();
    event.stopPropagation();

    setStateAutoBtns((prev) =>
      prev.map((item) => ({
        ...item,
        isOpen: item.type === type && item.groupBtnType === autoBtnType,
      })),
    );
  };

  const debouncedHandleChange = debounce(onChange, 200, {
    maxWait: 200,
  });

  const handleAutoOption = (type: string, option: number) => {
    if (type === 'bet') {
      gameManager.setAutoBet(autoBtnType, option);
    } else {
      gameManager.setAutoCollect(autoBtnType, option);
    }

    setStateAutoBtns((prev) =>
      prev.map((item) =>
        item.type === type && item.groupBtnType === autoBtnType
          ? { ...item, isOpen: false }
          : item,
      ),
    );
  };

  const handleClickOutside = () => {
    setStateAutoBtns((prev) =>
      prev.map((item) => ({
        ...item,
        isOpen: false,
      })),
    );
  };

  return (
    <SWrapper>
      <MakeBtn
        onClickHandler={btnClickHandler}
        status={status}
        price={price}
        freeBets={priceFreeBets}
        disabled={disabled}
      />
      <SContainer>
        <SControllerWrapper>
          <SController>
            {freeBet?.isActive ? (
              <FreeBet
                amountLeft={freeBet.amountLeft}
                coinValue={freeBet.coinValue}
                coinValues={freeBet.coinValues}
                unit={bet.symbol}
              />
            ) : (
              <Controller
                label={t('betC')}
                name="bet"
                options={bet.availableBets}
                min={bet.minValue}
                max={bet.maxValue}
                step={bet.step}
                value={bet.currentValue}
                unit={bet.symbol}
                disabled={disabled || changeDisabled}
                format={(value) => value.toFixed(bet.toFixed)}
                onChange={debouncedHandleChange}
                styleControl={BORDER_S}
              />
            )}
          </SController>
          {!disableAutobet && (
            <SAutoBtnWrapper position="top">
              <AutoBtn
                borderRStyle={TOP_BUTTON_S}
                onClickHandler={(event) => handleAutoClick(event, 'bet')}
                btnType={BET}
                groupBtnType={autoBtnType}
                disabled={disabled || changeDisabled}
              />
            </SAutoBtnWrapper>
          )}
        </SControllerWrapper>

        <SControllerWrapper>
          <Controller
            label={t('collect')}
            name="collect"
            min={collect.minValue}
            max={collect.maxValue}
            step={collect.step}
            value={collect.currentValue}
            unit={collect.symbol}
            disabled={disabled || changeDisabled}
            format={(value) => value.toFixed(2)}
            onChange={debouncedHandleChange}
          />

          {!disableAutocollect && (
            <SAutoBtnWrapper>
              <AutoBtn
                borderRStyle={BOTTOM_BUTTON_S}
                onClickHandler={(event) => handleAutoClick(event, 'collect')}
                btnType={COLLECT}
                groupBtnType={autoBtnType}
                disabled={disabled || changeDisabled}
              />
            </SAutoBtnWrapper>
          )}
        </SControllerWrapper>

        {stateAutoBtns.map(({ groupBtnType, type, isOpen }) =>
          groupBtnType === autoBtnType && isOpen ? (
            <SAutoOptionsWrapper
              key={`${autoBtnType}${type}`}
              isBottom={type === 'collect'}
            >
              <AutoOption
                optionTitle={t(type === 'collect' ? 'autoCollect' : 'autoBet')}
                handleOption={(option) => handleAutoOption(type, option)}
                onClickOutside={handleClickOutside}
              />
            </SAutoOptionsWrapper>
          ) : null,
        )}
      </SContainer>
    </SWrapper>
  );
};

export default GroupControllers;
