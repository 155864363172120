import {
  useMemo,
  useEffect,
  useState,
  useRef,
  CSSProperties,
  FC,
  useCallback,
  MutableRefObject,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useResizeDetector } from 'react-resize-detector';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';

import { AAvatars, ASelectedAvatar } from 'store/avatars';
import { AInitialData } from 'store/init';
import { IAvatar } from 'store/avatars/types';
import { ATabDimension } from 'store/tabs';
import { AResize } from 'store/resize';

import notificationManager from 'api/notificationManager/NotificationManager';

import Icon from 'shared/components/Icon';
import ListLayouts from 'shared/components/ListLayouts';

import {
  SAvatars,
  SHeader,
  STitle,
  SHeaderItem,
  SHeaderButton,
  SGroup,
  SList,
  SRow,
  SItem,
  SItemButton,
  SImg,
  SCheck,
  SSetButton,
  SLoading,
} from './style';
import { IAvatarsProps } from './types';

const ITEM_WIDTH = 110;
const ITEM_HEIGHT = 90;

const Item: FC<{
  id: number;
  l: string;
  isActive: boolean;
  isSelected: boolean;
  onClick: () => void;
}> = ({ id, l, isActive, isSelected, onClick }) => (
  <SItem key={id}>
    <SItemButton
      onClick={onClick}
      isActive={isActive}
      disabled={isActive}
      isSelected={isSelected}
    >
      <SImg src={l} />
    </SItemButton>
    <SCheck isActive={isActive}>
      <Icon name="ic_check" />
    </SCheck>
  </SItem>
);

const Row: FC<{
  data: IAvatar[][];
  index: number;
  style: CSSProperties;
}> = ({ style, index, data }) => {
  const [
    {
      chat: { a_id },
    },
  ] = useRecoilState(AInitialData);
  const [selectedAvatar, setSelectedAvatar] = useRecoilState(ASelectedAvatar);

  const handleClick = (id: number) => {
    setSelectedAvatar(id);
  };

  return (
    <SRow style={style}>
      {(data[index] || []).map(({ id, l }) => (
        <Item
          key={id}
          id={id}
          l={l}
          isActive={a_id === id}
          isSelected={selectedAvatar === id}
          onClick={() => handleClick(id)}
        />
      ))}
    </SRow>
  );
};

const Avatars: FC<IAvatarsProps> = ({ onClose }) => {
  const listContainerRef = useRef<{
    obs: ResizeObserver | null;
    ref: MutableRefObject<HTMLElement> | null;
  }>({ obs: null, ref: null });
  const [listHeight, setListHeight] = useState<number>(0);

  const isClosedRef = useRef<boolean>(false);
  const { t } = useTranslation();
  const avatars = useRecoilValue(AAvatars);
  const resize = useRecoilValue(AResize);
  const { width } = useRecoilValue(ATabDimension);
  const [selectedAvatar, setSelectedAvatar] = useRecoilState(ASelectedAvatar);
  const [
    {
      chat: { a_id },
    },
  ] = useRecoilState(AInitialData);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSetting, setIsSetting] = useState<boolean>(false);

  const mappedAvatars = useMemo(() => {
    const length = avatars?.length || 0;
    const colCount = Math.floor(width / ITEM_WIDTH);

    return length > 0 && width > 0
      ? Array(
          length % colCount > 0
            ? parseInt(`${length / colCount}`, 10) + 1
            : parseInt(`${length / colCount}`, 10),
        )
          .fill(1)
          .map((_, index) => {
            return avatars.slice(index * colCount, index * colCount + colCount);
          })
      : [];
  }, [avatars, width]);

  const setAvatar = () => {
    setIsSetting(true);

    notificationManager.updateAvatar(selectedAvatar, (isSet) => {
      if (isClosedRef.current) {
        return;
      }

      setIsSetting(false);
      if (isSet) {
        setSelectedAvatar(0);
        onClose();
      }
    });
  };

  const fetchData = async () => {
    setIsLoading(avatars.length === 0);
    await notificationManager.getAvatars();
    setIsLoading(false);
  };

  useEffect(() => {
    if (a_id) {
      setSelectedAvatar(a_id);
    }
    fetchData();

    return () => {
      setSelectedAvatar(0);
      isClosedRef.current = true;
    };
  }, []);

  const onRefChange = useCallback((node) => {
    if (node === null) {
      listContainerRef.current?.obs?.disconnect();
      return;
    }

    if (node === listContainerRef.current?.ref) {
      return;
    }

    listContainerRef.current.ref = node;
    const onResize = () => {
      setListHeight(node.getBoundingClientRect()?.height || 0);
    };
    listContainerRef.current.obs = new ResizeObserver(onResize);
    listContainerRef.current.obs.observe(node);
    onResize();
  }, []);

  return (
    <SAvatars>
      <SHeader>
        <SHeaderItem>
          <SHeaderButton onClick={onClose}>
            <Icon name="ic_arrowleft" />
          </SHeaderButton>
        </SHeaderItem>
        <SHeaderItem>
          <STitle>{t('setYourAvatar')}</STitle>
        </SHeaderItem>
        <SHeaderItem />
      </SHeader>

      {!isLoading && mappedAvatars.length > 0 ? (
        <>
          <SGroup>
            <SList ref={onRefChange}>
              <ListLayouts isFullHeight={true}>
                <List
                  //className="no-scrollbars"
                  height={listHeight / resize.scale}
                  itemCount={mappedAvatars.length + 1}
                  itemSize={ITEM_HEIGHT}
                  itemData={[...mappedAvatars, []]}
                  width="100%"
                  key="i"
                >
                  {Row}
                </List>
              </ListLayouts>
            </SList>
          </SGroup>
          {selectedAvatar !== a_id && (
            <SSetButton disabled={isSetting} onClick={setAvatar}>
              <span>{t('set')}</span>
            </SSetButton>
          )}
        </>
      ) : (
        <SLoading>{t('noData')}</SLoading>
      )}
      {isLoading && <SLoading>{t('loading')}</SLoading>}
    </SAvatars>
  );
};

export default Avatars;
