export interface IPrizesListItem {
  p: number;
  curr: { [key: string]: string };
  img: string | null;
  title: { [key: string]: string };
  text: { [key: string]: string };
  type: string;
  isActive: boolean;
}

export interface IPrizesTotal {
  curr: { [key: string]: string };
}

// st - start time
// et  - end time
// dlc - default language code
// dcc - default currency code
// sf - show from time
// sto - show to time
// aa - auto apply
// n - name
// d - description
// t - terms
// im - image
// r_p - prizes

export enum PrizeType {
  CASH = 'cash',
  BONUS = 'bonus',
  FREESPINS = 'freespins',
  OTHER = 'other',
}

export interface ICupsListItem {
  id: number;
  st: number;
  et: number;
  aa: boolean;
  dlc: string;
  dcc: string;
  n: { [key: string]: string };
  d: { [key: string]: string };
  t: { [key: string]: string };
  im: string;
  r_p: {
    total: IPrizesTotal;
    prize: IPrizesListItem[];
  };
}
