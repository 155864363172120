import { atom } from 'recoil';

import { defaultScrollHolder } from 'components/Chat/constants/defaultObjects';
import { TScrollHolder } from 'components/Chat/types';

import { SCROLL_HOLDER } from '../constants';

export const AScrollHolder = atom<TScrollHolder>({
  key: SCROLL_HOLDER,
  default: defaultScrollHolder,
});
