import { atom } from 'recoil';

import { IAvatar } from './types';

export const ASelectedAvatar = atom<number>({
  key: 'SELECTED_AVATARS',
  default: 0,
});

export const AAvatars = atom<IAvatar[]>({
  key: 'AVATARS',
  default: [],
});
