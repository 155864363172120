import { FC, memo } from 'react';
import { areEqual } from 'react-window';

import TournamentsListItem from '../TournamentsListItem';

import { TRowProps } from './types';

const Row: FC<TRowProps> = memo(
  ({ setIsTournamentOpen, data, index, style }) => {
    const { ...rest } = data[index];

    return (
      <TournamentsListItem
        style={style}
        setIsTournamentOpen={setIsTournamentOpen}
        {...rest}
      />
    );
  },
  areEqual,
);

export default Row;
