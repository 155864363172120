import { FC } from 'react';
import { createPortal } from 'react-dom';

import Icon from 'shared/components/Icon';

import { SWrapper, SText, SIcon, SExtraText, SExtraButton } from './style';
import { IToastProps } from './types';

const Toast: FC<IToastProps> = ({
  id,
  text,
  icon,
  type = 'default',
  extra,
}) => {
  const portalEl = document.getElementById('portal');

  return (
    portalEl &&
    createPortal(
      <SWrapper type={type}>
        {extra ? (
          <>
            <SText>{text}</SText>
            <SExtraButton mode={type} onClick={() => extra.action(id)}>
              <SExtraText>{extra.title}</SExtraText>
              {icon && (
                <SIcon>
                  <Icon name={icon} />
                </SIcon>
              )}
            </SExtraButton>
          </>
        ) : (
          <>
            <SText>{text}</SText>
            {icon && (
              <SIcon>
                <Icon name={icon} />
              </SIcon>
            )}
          </>
        )}
      </SWrapper>,
      portalEl,
    )
  );
};

export default Toast;
