import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'shared/components/Icon';

import {
  SWrapperPopup,
  SCloseButton,
  SMarker,
  SHeader,
  STitleWrapper,
  SUserTitle,
  SIconWrapper,
  SAvatarImg,
} from './style';
import { IProfilePopupProps } from './types';

export const ProfilePopup: FC<IProfilePopupProps> = ({
  name,
  avatar,
  isYourProfile,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <SWrapperPopup>
      <SCloseButton onClick={onClose}>
        <Icon name="ic_arrowleft" />
      </SCloseButton>
      {isYourProfile && <SMarker>{t('you')}</SMarker>}
      <SHeader>
        <SIconWrapper>
          {avatar ? <SAvatarImg src={avatar} /> : <Icon name="ic_chatavatar" />}
        </SIconWrapper>
      </SHeader>
      <STitleWrapper>
        <SUserTitle>{name || ''}</SUserTitle>
      </STitleWrapper>
    </SWrapperPopup>
  );
};
