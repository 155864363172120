const AUTO_RECONNECT_INTERVAL: number = 1000;

export interface IClientLifecycleListener {
  onOpen: (event: Event) => void;
  onError: (event: Event) => void;
  onClose: (event: CloseEvent) => void;
}

export class WsClient {
  host: string;
  ws: WebSocket | null;
  _onMessage: ((data: any) => void) | null;
  _lifecycleListener: IClientLifecycleListener | null;
  isForceClose: boolean;
  _isConnected: boolean;
  _reconnect: boolean;

  constructor(host: string) {
    this.host = host;
    this.ws = null;

    this._init();
    this._onMessage = null;
    this._lifecycleListener = null;
    this.isForceClose = false;
    this._isConnected = false;
    this._reconnect = true;
  }

  _init() {
    if (document.visibilityState === 'hidden') {
      this.reconnect();
      return;
    }

    this.ws = new WebSocket(this.host);
    this.ws.binaryType = 'arraybuffer';
    this.ws.onopen = (event) => this.onOpen(event);
    this.ws.onclose = (event) => this.onClose(event);
    this.ws.onmessage = (event) => this.onMessage(event);
    this.ws.onerror = (event) => this.onError(event);
  }

  destroy() {
    this.isForceClose = true;

    if (this.ws) this.ws.close();
  }

  onMessage(event: MessageEvent) {
    if (this._onMessage) this._onMessage(event.data);
  }

  onOpen(event: Event) {
    this._isConnected = true;

    if (this._lifecycleListener) {
      try {
        this._lifecycleListener.onOpen(event);
      } catch (e) {}
    }
  }

  onError(event: Event) {
    if (this._lifecycleListener) {
      try {
        this._lifecycleListener.onError(event);
      } catch (e) {}
    }
  }

  onClose(event: CloseEvent) {
    this._isConnected = false;

    if (this._lifecycleListener) {
      try {
        this._lifecycleListener.onClose(event);
      } catch (e) {}
    }

    if (this.isForceClose) return;

    this.reconnect();
  }

  reconnect() {
    if (!this._reconnect) {
      return;
    }

    setTimeout(this._init.bind(this), AUTO_RECONNECT_INTERVAL);
  }

  forceClose() {
    if (this.ws) this.ws.close();
  }

  set onMessageListener(listener: (data: any) => void) {
    this._onMessage = listener;
  }

  set lifecycleListener(listener: IClientLifecycleListener) {
    this._lifecycleListener = listener;
  }

  get isConnected() {
    return this._isConnected;
  }
  sendMessage(message: any) {
    if (!this.isConnected) {
      return false;
    }

    try {
      this.ws!.send(message);
      return true;
    } catch (e) {
      return false;
    }
  }

  setReconnect(reconnect: boolean) {
    this._reconnect = reconnect;
  }

  shouldReconnect() {
    return this._reconnect;
  }
}
