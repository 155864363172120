import { FC, memo } from 'react';
import { areEqual } from 'react-window';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Field from 'shared/components/Field';

import { ICoefficientItemType, IWinItemType } from 'constants/leaderBoard';

import { SWrapper, SInner, SItem } from './style';
import { ILeaderItemProps } from './types';

const CoefficientItem: FC<ICoefficientItemType> = ({ date, coefficient }) => {
  const { t } = useTranslation();
  const dt = dayjs(date);

  return (
    <>
      <SItem>
        <Field
          value={dt.format('HH:mm:ss')}
          label={
            <span style={{ textTransform: 'capitalize' }}>
              {dt.format('DD MMM')}
            </span>
          }
          align="left"
          unit=""
        />
      </SItem>
      <SItem>
        <Field value={coefficient} label={t('coefficients')} color="blue" />
      </SItem>
    </>
  );
};

const WinsItem: FC<IWinItemType> = ({
  date,
  betAmount,
  cashedOutMultiplier,
  winAmount,
  roundMultiplier,
  userAka,
}) => {
  const { t } = useTranslation();
  const dt = dayjs(date);

  return (
    <>
      <SItem>
        <Field
          value={userAka}
          label={
            <span style={{ textTransform: 'capitalize' }}>{`${dt.format(
              'HH:mm',
            )} • ${dt.format('DD MMM')}`}</span>
          }
          align="left"
        />
      </SItem>
      <SItem>
        <Field
          value={cashedOutMultiplier}
          label={t('cashedOut')}
          color="blue"
        />
        <Field value={winAmount} label={t('win')} color="green" unit="€" />
      </SItem>
      <SItem>
        <Field value={roundMultiplier} label={t('round')} />
        <Field value={betAmount} label={t('bet')} unit="€" />
      </SItem>
    </>
  );
};

const LeaderItem: FC<ILeaderItemProps> = ({ isWins, style, data, index }) => {
  const fields = data[index];
  return (
    <SWrapper style={style} isFullheight={isWins}>
      <SInner>
        {isWins ? <WinsItem {...fields} /> : <CoefficientItem {...fields} />}
      </SInner>
    </SWrapper>
  );
};

export default memo(LeaderItem, areEqual);
