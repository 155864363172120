import styled, { css } from 'styled-components';

export const SWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 4px;
  color: rgba(var(--grid-txt-normal), 1);
  font-size: 14px;
  font-weight: 400;

  &:nth-child(odd) {
    background: rgba(var(--lvl-1), 1);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: rgba(var(--primary-1), 1);
    `}
`;

export const SUser = styled.div`
  width: 85px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SBet = styled.div`
  width: 75px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SCollect = styled.div`
  width: 75px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SWin = styled.div`
  width: 75px;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SBonus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 15px;
`;

export const SImg = styled.img`
  display: block;
`;
