import { ISyncTime, createSyncTime } from 'api/notificationManager/SyncTime';
import { WsClient } from 'api/ws/WsClient';
import WsWrapper from 'api/ws/WsWrapper';
import { API_METHOD_MESSAGES } from 'api/notificationManager/methods';

import { TMessageBody } from 'components/Chat/types';

import { ConnectionInfo } from '../ws/WsWrapper';
import { WsBaseResponse } from '../ws/WsResponse';
import { WsBaseRequest } from '../ws/WsRequest';

import AuthRequest from './request/AuthRequest';
import TranslationsRequest from './request/TranslationsRequest';
import SendMessageRequest from './request/SendMessageRequest';
import ReportMessageRequest from './request/ReportMessageRequest';
import AuthRequestTest from './request/AuthRequestTest';
import LeaderBoardCoefficientRequest from './request/LeaderBoardCoefficientRequest';
import LeaderBoardWinsRequest from './request/LeaderBoardWinsRequest';
import TournamentsRequest from './request/Tournaments';
import TournamentCheckIsAppliedRequest from './request/TournamentCheckIsAppliedRequest';
import TournamentApplyRequest from './request/TournamentApplyRequest';
import GetAvatarsRequest from './request/GetAvatarsRequest';
import UpdateAvatarsRequest from './request/UpdateAvatarRequest';
import GetTournamentLeaderboardsRequest from './request/GetTournamentLeaderboardsRequest';

import { Censorship } from 'lib/censoreship';

export interface INotificationManager {
  connect(host: string, token: string): void;
  disconnect(): void;
  time(): number;
  sendMessage(data: any): Promise<any>;
  getLBCoefficient(data: any): Promise<any>;
  getLBWins(data: any): Promise<any>;
  reportMessage(data: any): Promise<any>;
  updateTournaments(): void;
  getAvatars(): void;
  updateAvatar(avatarID: number, callback: (result: boolean) => void): void;
  checkTournamentStatus(
    tournamentID: number,
    callback: (isApplied: boolean) => void,
  ): void;
  applyTournament(
    tournamentID: number,
    callback: (result: boolean) => void,
  ): void;
  cancelApplyTournament(): void;
  cancelCheckTournamnetStatus(): void;
  getTournamentLeaderboards(
    tournamentID: number,
    page: number,
    callback: (
      result: boolean,
      arr: {
        id: number; // id
        t_id: number; // tournament id
        p_id: number; // player id
        ua: string; // user name
        p: number; // points
        pl: number; // place
      }[],
      it: {
        id: number; // id
        t_id: number; // tournament id
        p_id: number; // player id
        ua: string; // user name
        p: number; // points
        pl: number; // place
      },
      page: number,
      pageCount: number,
    ) => void,
  ): void;
  cancelGetTournemntLeeaderboards(): void;
  subscribeListener(listener: INotificationManagerListener): void;
  unsubscribeListener(listener: INotificationManagerListener): void;
  appendChatMessage(msg: any): void;
}

export interface Cancelable {
  cancel(): void;
}

export interface INotificationManagerListener {
  onInit(data: any): void;
  onMessages(data: any): void;
  onSlowTimeUpdate(newTime: number): void;
  onTournamentsUpdate(data: any): void;
  onAvatarUpdate(avatarId: number, avatarLink: string): void;
  onGetAvatars(avatars: any[]): void;
}

const SLOW_TIME_UPDATE_PERIOD = 1000;
const TOURNAMENTS_UPDATE_DELAT = 60000;

type TournamentsData = {
  nextUpdate: number;
  updatedAt: number;
};

const createNotificationManager = (): INotificationManager => {
  const censorship = new Censorship({ placeHolder: '*' });

  let ws: WsWrapper | null;
  let syncTime: ISyncTime | null;
  let listeners: INotificationManagerListener[] = [];
  let lastInitData: any = null;
  let tournamentStatuses: { [key: number]: boolean } = {};
  let singleCall: any | null = null;
  let tournaments: TournamentsData = { nextUpdate: 0, updatedAt: 0 };
  let tournamentsCall: any | null = null;
  let tournamentStatusCall: any | null = null;
  let applyTournamentCall: any | null = null;
  let tournamentLeaderboardsCall: any | null = null;

  setInterval(() => {
    const newTime = time();
    listeners.forEach((l) => {
      l.onSlowTimeUpdate(newTime);
    });
  }, SLOW_TIME_UPDATE_PERIOD);

  const updateTournamentsPeriodically = async () => {
    updateTournaments();
    setTimeout(updateTournamentsPeriodically, TOURNAMENTS_UPDATE_DELAT);
  };

  const time = () => {
    return syncTime ? syncTime.time() : Date.now();
  };

  const connect = (host: string, token: string) => {
    disconnect();

    if (!host || !token) {
      return;
    }

    const lifecycleListener = {
      onOpen: async (event: Event) => {
        init(token);
      },

      onError: (event: Event) => {},
      onClose: (event: CloseEvent) => {},
      connectionInfo: (info: ConnectionInfo) => {},
    };

    ws = new WsWrapper(new WsClient(host), lifecycleListener);

    syncTime = createSyncTime(ws);

    ws.subscribeStatic(API_METHOD_MESSAGES, (res) => {
      listeners.forEach((l) => {
        l.onMessages(res.data.msgs);
      });
    });
  };

  const disconnect = () => {
    if (ws) {
      ws.destroy();
    }
  };

  const subscribeListener = (listener: INotificationManagerListener) => {
    if (listeners.indexOf(listener) >= 0) {
      return;
    }

    listeners.push(listener);

    if (lastInitData) {
      notifyOnInit();
    }
  };

  const unsubscribeListener = (listener: INotificationManagerListener) => {
    listeners.filter((l) => l !== listener);
  };

  const notifyOnInit = () => {
    listeners.forEach((l) => {
      l.onInit(lastInitData);
    });
  };

  const init = async (token: string) => {
    let r1 = await ws?.asyncSendMessage(
      token ? new AuthRequest(token) : new AuthRequestTest(),
    );
    if (!r1 || !r1.result) {
      return;
    }

    await syncTime?.syncTime();

    let r2 = await ws?.asyncSendMessage(new TranslationsRequest());

    let d: any = {};
    d.chat = r1.data.ch;
    d.dict = (r2 && r2.data && r2.data.d) || {};
    d.acl = d?.chat?.acl || [];
    delete d.chat.acl;

    lastInitData = d;
    tournaments = { nextUpdate: 0, updatedAt: 0 };

    notifyOnInit();

    if (Array.isArray(d.acl) && d.acl.indexOf('tnt') !== -1) {
      updateTournamentsPeriodically();
    }
  };

  const sendMessage = async (data: TMessageBody): Promise<any> => {
    if (data?.t) {
      data.t = censorship.clean(data.t);
    }

    const res = await ws?.asyncSendMessage(new SendMessageRequest(data));
    return {
      result: !!res?.result,
      s: !!res?.data?.s,
      ncmt: res?.data?.ncmt || 0,
      t: (res && res.data && res.data.t) || notificationManager.time(),
    };
  };

  const asyncSendMessageSaveCall = async (
    wsRequest: WsBaseRequest,
    timeout?: number,
  ): Promise<WsBaseResponse> => {
    singleCall?.cancel();
    return new Promise((resolve, reject) => {
      singleCall = ws?.sendMessage(
        wsRequest,
        function (resp) {
          resolve(resp);
        },
        timeout,
      );
    });
  };

  const reportMessage = async (data: any): Promise<any> => {
    return ws?.asyncSendMessage(new ReportMessageRequest(data));
  };

  const getLBCoefficient = async (data: any): Promise<any> => {
    return asyncSendMessageSaveCall(new LeaderBoardCoefficientRequest(data));
  };

  const getLBWins = async (data: any): Promise<any> => {
    return asyncSendMessageSaveCall(new LeaderBoardWinsRequest(data));
  };

  const getAvatars = async () => {
    const res = await ws?.asyncSendMessage(new GetAvatarsRequest());

    if (!res || !res.result) {
      listeners.forEach((l) => l.onGetAvatars([]));

      return;
    }

    listeners.forEach((l) => l.onGetAvatars(res?.data?.arr || []));
  };

  const updateAvatar = (
    avatarID: number,
    callback: (result: boolean) => void,
  ) => {
    ws?.sendMessage(
      new UpdateAvatarsRequest({ ai: avatarID }),
      (resp: WsBaseResponse) => {
        if (!resp || !resp.result) {
          if (callback) callback(false);
          return;
        }

        const d = resp.data;
        listeners.forEach((l) => l.onAvatarUpdate(d?.id || 0, d?.l || ''));
        if (callback) callback(true);
      },
    );
  };

  const updateTournaments = async () => {
    if (tournaments.nextUpdate > time()) {
      return;
    }

    tournamentsCall?.cancel();

    if (!ws) {
      tournaments = {
        nextUpdate: 0,
        updatedAt: 0,
      };

      //listeners.forEach((l) => l.onTournamentsUpdate([]));
      return;
    }

    tournamentsCall = ws?.sendMessage(
      new TournamentsRequest(),
      (resp: WsBaseResponse) => {
        if (!resp?.result) {
          listeners.forEach((l) => l.onTournamentsUpdate([]));
          return;
        }

        const arr = Array.isArray(resp?.data?.arr) ? resp?.data?.arr : [];
        tournaments = {
          nextUpdate: resp?.data?.nu || 0,
          updatedAt: resp?.data?.u || 0,
        };

        listeners.forEach((l) => l.onTournamentsUpdate(arr));
      },
    );
  };

  const checkTournamentStatus = (
    tournamentID: number,
    callback: (isApplied: boolean) => void,
  ) => {
    if (tournamentStatuses[tournamentID]) {
      return true;
    }

    tournamentStatusCall?.cancel();

    tournamentStatusCall = ws?.sendMessage(
      new TournamentCheckIsAppliedRequest(tournamentID),
      (resp: WsBaseResponse) => {
        if (!resp?.result) {
          if (callback) callback(true);
          return;
        }

        const isApplied = resp?.data.a;
        if (isApplied) {
          tournamentStatuses[tournamentID] = true;
        }

        if (callback) callback(isApplied);
      },
    );
  };

  const cancelCheckTournamnetStatus = () => {
    tournamentStatusCall?.cancel();
    tournamentStatusCall = null;
  };

  const applyTournament = (
    tournamentID: number,
    callback: (result: boolean) => void,
  ) => {
    applyTournamentCall?.cancel();

    applyTournamentCall = ws?.sendMessage(
      new TournamentApplyRequest(tournamentID),
      (resp: WsBaseResponse) => {
        if (!resp?.result) {
          if (callback) callback(false);
          return;
        }

        const isApplied = resp?.data.a;

        if (isApplied) {
          tournamentStatuses[tournamentID] = true;
        }

        if (callback) callback(isApplied);
      },
    );
  };

  const cancelApplyTournament = () => {
    applyTournamentCall?.cancel();
    applyTournamentCall = null;
  };

  const getTournamentLeaderboards = (
    tournamentID: number,
    page: number,
    callback: (
      result: boolean,
      arr: any[],
      it: any,
      page: number,
      pageCount: number,
    ) => void,
  ) => {
    tournamentLeaderboardsCall?.cancel();
    tournamentLeaderboardsCall = ws?.sendMessage(
      new GetTournamentLeaderboardsRequest(tournamentID, page),
      (resp: WsBaseResponse) => {
        if (!resp?.result) {
          if (callback) callback(false, [], null, page, 0);
          return;
        }

        if (callback)
          callback(
            true,
            resp.data?.arr || [],
            resp.data?.it || null,
            resp.data?.p || page,
            resp.data?.pc || 0,
          );
      },
    );
  };

  const appendChatMessage = (msg: any) => {
    listeners.forEach((l) => {
      l.onMessages([msg]);
    });
  };

  const cancelGetTournemntLeeaderboards = () => {
    tournamentLeaderboardsCall?.cancel();
    tournamentLeaderboardsCall = null;
  };

  return {
    connect,
    disconnect,
    subscribeListener,
    unsubscribeListener,
    time,
    sendMessage,
    reportMessage,
    getLBCoefficient,
    getLBWins,
    updateTournaments,
    checkTournamentStatus,
    cancelCheckTournamnetStatus,
    applyTournament,
    cancelApplyTournament,
    getTournamentLeaderboards,
    cancelGetTournemntLeeaderboards,
    getAvatars,
    updateAvatar,
    appendChatMessage,
  };
};

const notificationManager = createNotificationManager();

export default notificationManager;
