import { FC } from 'react';

import { SList } from './style';

const ListLayouts: FC<{
  isFullHeight?: boolean;
}> = ({ children, isFullHeight }) => {
  return <SList isFullHeight={isFullHeight}>{children}</SList>;
};

export default ListLayouts;
