export const getCutString = (value: string) => {
  const curValue = value && value.trim();

  let slicedValue = curValue.slice(0, 89);
  if (89 < curValue.length) {
    slicedValue += '...';
  }

  return slicedValue;
};
