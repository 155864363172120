interface IError {
  c: number;
  msg: string;
  b: string;
}

export class WsBaseResponse {
  _d: any;
  _err?: IError | null;
  _re?: boolean;

  constructor(data: any) {
    if (data) {
      this._d = data.d;
      this._err = data.err;
      this._re = data.re;
    }
  }

  get error() {
    return this._err;
  }

  get result() {
    return this._re;
  }

  get data() {
    return this._d;
  }

  get errorRawMsg() {
    return this._err ? this._err.msg : '';
  }
  get errorCode() {
    return this._err ? this._err.c : 0;
  }
  get errorButtonsRaw() {
    return this._err ? this._err.b : '';
  }
}
