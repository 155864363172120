import { atom } from 'recoil';

export const ATotalCounter = atom<{
  isGameInited: boolean;
  startTime: number;
  bet: number;
  won: number;
  currency: string;
}>({
  key: 'TOTAL_COUNTER',
  default: {
    isGameInited: false,
    startTime: 0,
    bet: 0,
    won: 0,
    currency: '',
  },
});
