import styled from 'styled-components';

export const SWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 0 6px;
  z-index: var(--z-index-10);

  &[data-is-open='true'] {
    z-index: var(--z-index-15);
  }
`;

export const SLabel = styled.span`
  display: block;
  color: rgba(var(--primary-1), 1);
  font-size: 10px;
  font-weight: 500;
  margin: 0 0 4px;
  text-transform: uppercase;
`;

export const SInner = styled.div`
  position: relative;
  background-color: rgba(var(--lvl-2), 1);
  color: rgba(var(--primary-1), 1);
  border-radius: 4px;

  [data-icon] {
    position: absolute;
    top: 50%;
    right: 6px;
    width: 16px;
    height: 16px;
    pointer-events: none;
    transform: translate(0, -50%);
  }
`;

export const SSelected = styled.button`
  width: 100%;
  display: block;
  height: 38px;
  line-height: 38px;
  background: none;
  border: none;
  text-align: left;
  color: rgba(var(--txt-h2-primary-1), 1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 8px;
  user-select: none;
  cursor: pointer;

  @media (min-width: 768px) {
    &:focus {
      outline: none;
    }
  }
`;

export const SList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(var(--primary-1), 1);
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  color: rgba(var(--menu-txt-normal), 1);
  z-index: var(--z-index-10);
  box-shadow: 0 2px 5px rgba(var(--shdw-primary-1));
`;

export const SItem = styled.li`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  position: relative;
  z-index: 20;

  &[data-is-active='true'] button {
    background: rgba(var(--menu-active), 1);
    color: rgba(var(--menu-txt-active), 1);
  }
`;

export const Sbutton = styled.button`
  height: 38px;
  line-height: 38px;
  font-size: 15px;
  padding: 0 8px;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  color: rgba(var(--menu-txt-normal), 1);

  &:hover {
    background: rgba(var(--menu-active), 1);
    color: rgba(var(--menu-txt-active), 1);
  }

  @media (max-width: 990px) {
    outline: none;
  }
`;

export const SCover = styled.div`
  position: fixed;
  z-index: var(--z-index-5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;
