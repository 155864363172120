import { FC, useCallback, useState, useRef, MutableRefObject } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { ABetsList } from 'store/bets';
import { ATabDimension } from 'store/tabs/configs';
import { AResize } from 'store/resize';

import ListLayouts from 'shared/components/ListLayouts';

import { betsTitles } from 'constants/betsTitles';

import Row from './Row';
import { SWrapper, SList, STitlesWrapper, STitle, SNoBets } from './style';
import { IBetsProps } from './types';

const Bets: FC<IBetsProps> = () => {
  const listContainerRef = useRef<{
    obs: ResizeObserver | null;
    ref: MutableRefObject<HTMLElement> | null;
  }>({ obs: null, ref: null });
  const [listHeight, setListHeight] = useState<number>(0);
  const { t } = useTranslation();
  const betsList = useRecoilValue(ABetsList);
  const { height } = useRecoilValue(ATabDimension);
  const resize = useRecoilValue(AResize);

  const onRefChange = useCallback(
    (node) => {
      if (node === null) {
        listContainerRef.current?.obs?.disconnect();
        return;
      }

      const onResize = () => {
        console.log(resize, height);
        setListHeight(
          resize.isPortrait
            ? height - 24
            : node.getBoundingClientRect()?.height || 0,
        );
      };

      if (node === listContainerRef.current?.ref) {
        onResize();
        return;
      }

      listContainerRef.current.ref = node;

      listContainerRef.current.obs = new ResizeObserver(onResize);
      listContainerRef.current.obs.observe(node);
      onResize();
    },
    [resize, height],
  );

  return (
    <SWrapper>
      {betsList.length ? (
        <>
          <STitlesWrapper>
            {betsTitles.map(({ name, id }) => (
              <STitle key={id} titleId={id}>
                {name}
              </STitle>
            ))}
          </STitlesWrapper>

          <SList ref={onRefChange}>
            <ListLayouts isFullHeight={true}>
              <List
                //className="no-scrollbars"
                height={listHeight}
                itemCount={betsList.length}
                itemSize={22}
                itemData={betsList}
                width="100%"
                key="i"
              >
                {Row}
              </List>
            </ListLayouts>
          </SList>
        </>
      ) : (
        <SNoBets>{t('noBets')}</SNoBets>
      )}
    </SWrapper>
  );
};

export default Bets;
