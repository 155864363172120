import { atom } from 'recoil';

import { MOBILE } from '../constants';

import { IMobile } from './types';

export const AMobile = atom<IMobile>({
  key: MOBILE,
  default: { isMobileHeight: false },
});
