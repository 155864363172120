import styled from 'styled-components';

export const StickerImage = styled.img`
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 100%;
  max-height: 140px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;
