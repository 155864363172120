import { FC, useState, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  APreloader,
  APreloaderLoadingProgress,
  FLAG_FILES,
} from 'store/config/preloader';
import { AProjectConfigs } from 'store/projectConfigs';

import { parseExtParams } from 'shared/helpers/parseUrl';

import { files } from './files';
import {
  SWrapper,
  SCover,
  SLogo,
  SImage,
  SProgressBar,
  SProgressTrack,
  SCanvasWrapper,
} from './style';
import { TFiles } from './types';
import { getStyle } from './customizer';

const Preloader: FC = () => {
  const ext_params = parseExtParams();

  const pStyle = getStyle(ext_params['preloader']);

  const portalEL = document.getElementById('portal');
  const preloaderRef = useRef<HTMLDivElement>(null);
  const setPreloader = useSetRecoilState(APreloader);
  const preloaderLoadingProgress = useRecoilValue(APreloaderLoadingProgress);
  const setConfig = useSetRecoilState(AProjectConfigs);
  const [isPortrait, setPortrait] = useState<boolean>(
    window.matchMedia('(orientation: portrait)').matches,
  );

  const getFilesLength = useCallback(
    (files: TFiles) =>
      Object.keys(files).reduce((acc, key) => acc + files[key].length, 0),
    [],
  );

  const [total] = useState<number>(() => getFilesLength(files));
  const [loadedFiles, setLoadedFiles] = useState<number>(0);

  const handleResize = () => {
    setPortrait(window.matchMedia('(orientation: portrait)').matches);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize.bind(this), false);

    return () => {
      window.removeEventListener('resize', handleResize.bind(this), false);
    };
  }, []);

  useEffect(() => {
    Object.keys(files).forEach((key) => {
      files[key].forEach((file) => {
        switch (key) {
          case 'callback':
            (file as Function)(
              () => {
                setLoadedFiles((prev) => prev + 1);
              },
              { el: preloaderRef.current, setConfig },
            );
            break;
          case 'image':
            const img = new Image();
            img.src = file as string;
            img.addEventListener('load', () => {
              setLoadedFiles((prev) => prev + 1);
            });
            break;
          case 'fetch':
            const audio = new Audio(file as string);
            audio.onloadedmetadata = () => {
              setLoadedFiles((prev) => prev + 1);
            };
            break;
          default:
            setLoadedFiles((prev) => prev + 1);
        }
      });
    });
  }, [setLoadedFiles]);

  useEffect(() => {
    if (loadedFiles >= total) {
      setPreloader((prev) => prev | FLAG_FILES);
    }
  }, [loadedFiles, total]);

  return (
    portalEL &&
    createPortal(
      <SWrapper>
        <SCanvasWrapper ref={preloaderRef} />
        <SCover style={{ ...pStyle.cover }}>
          <SLogo
            style={{
              ...Object.assign(
                {},
                pStyle.imageLogo,
                isPortrait && pStyle.imageLogoPortrait
                  ? pStyle.imageLogoPortrait
                  : {},
              ),
            }}
          >
            <SImage style={{ ...pStyle.image }}>
              {pStyle.logoSVG && (
                <pStyle.logoSVG
                  style={{
                    ...Object.assign(
                      {},
                      pStyle.logoStyle,
                      isPortrait && pStyle.logoStylePortrait
                        ? pStyle.logoStylePortrait
                        : {},
                    ),
                  }}
                />
              )}
              {pStyle.logoGif && (
                <img
                  src={pStyle.logoGif}
                  alt={'logo'}
                  style={{
                    ...Object.assign(
                      {},
                      pStyle.logoStyle,
                      isPortrait && pStyle.logoStylePortrait
                        ? pStyle.logoStylePortrait
                        : {},
                    ),
                  }}
                />
              )}
            </SImage>
            <SProgressBar style={{ ...pStyle.progressBar }}>
              <SProgressTrack
                style={{
                  ...pStyle.progressTrack,
                  width: `${
                    ((loadedFiles + preloaderLoadingProgress) * 100) /
                    (total + 1)
                  }%`,
                }}
              />
            </SProgressBar>
          </SLogo>
        </SCover>
      </SWrapper>,
      portalEL,
    )
  );
};

export default Preloader;
