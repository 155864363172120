import { atom } from 'recoil';

import { INITIAL_DATA, COUNTRY } from 'store/constants';

import { TInitData } from 'components/Chat/types';
import { defaultInitData } from 'components/Chat/constants/initialData';

export const AInitialData = atom<TInitData>({
  key: INITIAL_DATA,
  default: defaultInitData,
});

export const ACountry = atom<string>({
  key: COUNTRY,
  default: '',
});
