import i18n from 'i18n/config';

import {
  MILLIS_IN_DAY,
  MILLIS_IN_HOUR,
  MILLIS_IN_MINUTE,
  MILLIS_IN_SECOND,
} from 'constants/time';

const fillZero = (n: number): string => {
  return n > 9 ? '' + n : '0' + n;
};

export const convertTimeToString = (milliseconds: number) => {
  const d = Math.floor(milliseconds / MILLIS_IN_DAY);
  const h = Math.floor((milliseconds % MILLIS_IN_DAY) / MILLIS_IN_HOUR);
  const m = Math.floor((milliseconds % MILLIS_IN_HOUR) / MILLIS_IN_MINUTE);
  const s = Math.floor((milliseconds % MILLIS_IN_MINUTE) / MILLIS_IN_SECOND);

  return `${d ? d + 'D ' : ''}${fillZero(h)}:${fillZero(m)}:${fillZero(s)}`;
};

export const convertEndedTime = (milliseconds: number) => {
  return `${Math.floor(milliseconds / MILLIS_IN_DAY)} ${i18n.t('daysAgo')}`;
};
