export const getScreenSize = () => {
  const width =
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    window.innerWidth;
  const height =
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    window.innerHeight;

  return {
    width,
    height,
  };
};
