import styled, { css } from 'styled-components';

import { Statuses } from 'constants/TournamentsStatuses';

const SAvatar = css`
  width: 96px;
  height: 96px;
  right: -5px;
  top: 0;
  overflow: hidden;
  position: absolute;
  clip-path: circle(60px at 32px 48px);
`;

export const SWrapper = styled.div``;

export const SContainer = styled.div`
  height: 96px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  background-color: rgba(var(--lvl-2), 1);
  overflow: hidden;
`;

export const SAvatarWrapper = styled.div`
  height: 96px;
  width: 78px;
  position: relative;
`;

export const SAvatarImg = styled.img`
  object-fit: cover;
  ${SAvatar}
`;

export const SAvatarPlaceholder = styled.span`
  background: rgba(var(--primary-1), 0.2);
  ${SAvatar}
`;

export const SCupIcon = styled.div<{
  status: Statuses.SOON | Statuses.LIVE | Statuses.ENDED;
}>`
  position: absolute;
  right: -11px;
  top: 11px;
  padding: 5px;
  width: 30px;
  height: 30px;
  background-color: ${({ status }) =>
    status === Statuses.SOON
      ? 'rgba(var(--primary-3), 1)'
      : status === Statuses.LIVE
      ? 'rgba(var(--primary-2), 1)'
      : 'rgba(var(--primary-1), 1)'};
  border-radius: 50%;
  z-index: var(--z-index-40);

  [data-icon] {
    color: ${({ status }) =>
      status === Statuses.LIVE
        ? 'rgba(var(--txt-white), 1)'
        : 'rgba(var(--lvl-1), 1)'};
    width: 20px;
  }
`;

export const SContent = styled.div`
  padding: 0 0 0 16px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 98px);
  max-height: 96px;
`;

export const SInfo = styled.div`
  width: calc(100% - 104px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 8px 0;

  @media (max-width: 340px) {
    width: 136px;
  }
`;

export const SDescription = styled.span`
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  color: rgba(var(--txt-h1), 1);
  margin-bottom: 12px;
  word-break: break-word;
  overflow: hidden;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
`;

export const SStatusBtn = styled.button<{
  status?: Statuses.SOON | Statuses.LIVE | Statuses.ENDED;
}>`
  width: max-content;
  max-width: 142px;
  max-height: 28px;
  font-weight: 500;
  font-size: 15px;
  font-family: 'Rubik', sans-serif;
  display: flex;
  flex: 0 0 28px;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 5px 14px;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.25s;

  ${({ status }) =>
    status === Statuses.SOON
      ? css`
          background-color: rgba(var(--primary-3), 0.2);
          color: rgba(var(--txt-h2-primary-3), 1);
          &:hover {
            background-color: rgba(var(--primary-3), 0.3);
          }
        `
      : status === Statuses.LIVE
      ? css`
          background-color: rgba(var(--primary-2), 0.2);
          color: rgba(var(--txt-h2-primary-2), 1);
          &:hover {
            background-color: rgba(var(--primary-2), 0.3);
          }
        `
      : css`
          background-color: rgba(var(--white), 0.2);
          color: rgba(var(--txt-h2), 1);
          &:hover {
            background-color: rgba(var(--white), 0.3);
          }
        `};

  > span {
    min-height: 18px;
    line-height: 1.286;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    max-width: 120px;
  }
`;

export const SDetails = styled.div`
  width: 104px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
`;

const textStyle = css`
  word-break: break-word;
  overflow: hidden;
`;

export const SDetailsTitle = styled.span`
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  color: rgba(var(--txt-cap), 1);
  text-align: right;
  max-height: 15px;
  text-transform: uppercase;

  ${textStyle};

  @media (max-width: 415px) {
    font-size: 13px;
  }
`;

export const STime = styled.span<{
  status: Statuses.SOON | Statuses.LIVE | Statuses.ENDED;
}>`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  margin-bottom: 10px;
  max-height: 22px;

  color: ${({ status }) =>
    status === Statuses.SOON
      ? 'rgba(var(--txt-h2-primary-3), 1)'
      : status === Statuses.LIVE
      ? 'rgba(var(--txt-h2-primary-2), 1)'
      : 'rgba(var(--txt-h2), .5)'};

  ${textStyle};

  @media (max-width: 415px) {
    font-size: 12px;
  }
`;

export const SDot = styled.span`
  color: rgba(var(--txt-h2-primary-2), 1);
  font-weight: 800;
`;

export const SCash = styled.span`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: rgba(var(--txt-h2), 1);
  text-align: right;
  max-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${textStyle};

  @media (max-width: 415px) {
    font-size: 16px;
  }
`;
