import styled from 'styled-components';

export const StyledChatBtn = styled.button`
  height: 58px;
  width: 58px;
  background: rgba(var(--primary-1), 1);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  z-index: var(--z-index-1000);
  position: fixed;
  bottom: 20px;
  right: 20px;

  [data-icon] {
    color: rgba(var(--lvl-0), 1);
    opacity: 70%;
  }
  @media (max-width: 720px) {
    display: none;
    position: absolute;
  }
`;
