import styled, { css, keyframes } from 'styled-components';

const animationLoading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const AVATAR_SIZE = 80;

export const SAvatars = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(var(--lvl-0), 1);
  padding: 10px 0;
  z-index: 10;

  @media (max-width: 720px) {
    height: calc(100% - 25px);
  }
`;

export const SHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const STitle = styled.h3`
  color: rgba(var(--txt-h1), 1);
  font-size: 17px;
  font-weight: 500;
  margin: 0;
`;

export const SHeaderItem = styled.div`
  flex: 1 0;
  text-align: center;
  display: flex;
  justify-content: center;

  &:first-child,
  &:last-child {
    text-align: left;
    flex: 0 0 50px;
  }
`;

export const SHeaderButton = styled.button`
  display: block;
  text-align: center;
  border: none;
  background: none;
  color: rgba(var(--white), 1);
  cursor: pointer;
`;

export const SGroup = styled.section`
  margin: 10px 0 20px;
  height: 100%;
`;

export const SGroupHeader = styled.h4`
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  color: rgba(var(--primary-1), 1);
  text-transform: uppercase;
  margin: 10px 0;
`;

export const SList = styled.section`
  height: 100%;
  width: 100%;
  margin: 10px 0;
`;

export const SRow = styled.ul`
  margin: 0;
  padding: 0 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;

export const SItem = styled.li`
  margin: 0;
  position: relative;
  flex: 0 0 calc(${AVATAR_SIZE}px + 30px);
  height: calc(${AVATAR_SIZE}px + 10px);
  padding: 5px 15px;
`;

export const SItemButton = styled.button<{
  isActive?: boolean;
  isSelected?: boolean;
}>`
  position: relative;
  display: block;
  height: ${AVATAR_SIZE}px;
  width: ${AVATAR_SIZE}px;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(var(--lvl-2), 1);
  overflow: hidden;
  background: rgba(var(--lvl-1), 1);
  color: rgba(var(--white), 1);
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    filter: none;
    opacity: 1;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: 3;
    transition: all 0.05s;
    box-shadow: inset 0 0 0 0 rgba(var(--primary-1), 1);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &:before {
        box-shadow: inset 0 0 0 2px rgba(var(--primary-1), 1);
      }
    `}
`;

export const SImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const SSetButton = styled.button`
  position: absolute;
  bottom: 10px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 6px;
  min-width: 230px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(var(--shdw-primary-1));
  background: rgba(var(--primary-1), 1);
  color: rgba(var(--txt-h2-dark), 1);
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  &:disabled {
    opacity: 1;

    span {
      opacity: 0;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin: -10px;
      border-radius: 50%;
      border: 2px solid;
      border-color: rgba(var(--txt-h2-dark), 1) transparent
        rgba(var(--txt-h2-dark), 1) transparent;
      animation: ${animationLoading} 0.5s linear infinite;
      z-index: 100;
    }
  }
`;

export const SCheck = styled.span<{ isActive?: boolean }>`
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  right: 15px;
  bottom: 10px;
  opacity: 0;
  border-radius: 50%;
  color: rgba(var(--dark), 1);
  background: rgba(var(--primary-1), 1);
  border: 2px solid rgba(var(--lvl-0), 1);
  transform: scale(0.5);
  z-index: 5;
  transition: transform 0.07s, opacity 0s 0.07s;

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: inset 0 0 0 2px rgba(var(--primary-1), 1);
      transform: scale(1);
      opacity: 1;
      transition: transform 0.07s 0.07s, opacity 0.1s;
    `}

  [data-icon] {
    svg {
      width: 15px;
      height: 15px;
      transform: translate(-1px, 0);
    }
  }
`;

export const SLoading = styled.div`
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(var(--lvl-2), 1);
  color: rgba(var(--txt-placeholder), 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
