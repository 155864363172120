import { FC, MutableRefObject, useRef, useCallback, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { Key } from 'i18n/Key';

import { AMyBetsList, SORTED_COLS } from 'store/myBets';
import { ATabDimension } from 'store/tabs';
import { AResize } from 'store/resize';

import ListLayouts from 'shared/components/ListLayouts';

import Row from './Row';
import {
  SWrapper,
  STitlesWrapper,
  STitle,
  SList,
  SNoBets,
  SGameName,
} from './style';
import { IBetsProps } from './types';

const MyBets: FC<IBetsProps> = () => {
  const listContainerRef = useRef<{
    obs: ResizeObserver | null;
    ref: MutableRefObject<HTMLElement> | null;
  }>({ obs: null, ref: null });
  const [listHeight, setListHeight] = useState<number>(0);
  const { t } = useTranslation();
  const myBetsList = useRecoilValue(AMyBetsList);
  const { height } = useRecoilValue(ATabDimension);
  const resize = useRecoilValue(AResize);

  const onRefChange = useCallback(
    (node) => {
      if (node === null) {
        listContainerRef.current?.obs?.disconnect();
        return;
      }

      const onResize = () => {
        console.log(resize, height);
        setListHeight(
          resize.isPortrait
            ? height - 24
            : node.getBoundingClientRect()?.height || 0,
        );
      };

      if (node === listContainerRef.current?.ref) {
        onResize();
        return;
      }

      listContainerRef.current.ref = node;

      listContainerRef.current.obs = new ResizeObserver(onResize);
      listContainerRef.current.obs.observe(node);
      onResize();
    },
    [resize, height],
  );

  return (
    <SWrapper>
      <SGameName>{t(Key.title)}</SGameName>
      {myBetsList.length ? (
        <>
          <STitlesWrapper>
            {[...SORTED_COLS].map(([key, title]) => (
              <STitle key={key} type={key}>
                {t(title)}
              </STitle>
            ))}
          </STitlesWrapper>

          <SList ref={onRefChange}>
            <ListLayouts isFullHeight={true}>
              <List
                //className="no-scrollbars"
                height={listHeight}
                itemCount={myBetsList.length}
                itemSize={44}
                itemData={myBetsList}
                width="100%"
                key="i"
              >
                {Row}
              </List>
            </ListLayouts>
          </SList>
        </>
      ) : (
        <SNoBets>{t('noBets')}</SNoBets>
      )}
    </SWrapper>
  );
};

export default MyBets;
