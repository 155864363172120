import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SWrapper,
  SList,
  SItem,
  SLabel,
  SValue,
  SValue2,
  SValueContainer,
} from './style';
import { IFreeBetProps } from './types';

const FreeBet: FC<IFreeBetProps> = ({
  amountLeft,
  coinValue,
  coinValues,
  unit = '',
}) => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SList>
        <SItem>
          <SLabel>{t('freebets')}</SLabel>
          <SValue>{amountLeft}</SValue>
        </SItem>
        <SItem>
          <SLabel>{t('Bet')}</SLabel>
          <SValueContainer>
            <SValue color="green">
              {unit}
              {coinValues?.length > 0 ? coinValues[0] || 0 : 0}
            </SValue>
            {coinValues?.length > 1 && (
              <SValue2>
                {unit}
                {coinValues[1] || 0}
              </SValue2>
            )}
            {coinValues?.length > 2 && <SValue2>...</SValue2>}
          </SValueContainer>
        </SItem>
      </SList>
    </SWrapper>
  );
};

export default FreeBet;
