import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SButton,
  STitle,
  SSubTitle,
  SPrice,
  SFreeBets,
  STakeTitle,
} from './style';
import { TMakeBtnProps } from './types';

const MakeBtn: FC<TMakeBtnProps> = ({
  onClickHandler,
  price,
  freeBets = 0,
  status,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <SButton status={status} onClick={onClickHandler} disabled={disabled}>
      {status !== 3 ? (
        <>
          <STitle status={status}>
            {status === 1 ? `${t('make')} ${t('bet')}` : t('cancel')}
          </STitle>
          <SSubTitle>{t('nextRound')}</SSubTitle>
        </>
      ) : (
        <>
          <SPrice>{price}</SPrice>
          {freeBets > 0 && (
            <SFreeBets>
              {t('freebets')}: {freeBets}
            </SFreeBets>
          )}
          <STakeTitle>{t('take')}</STakeTitle>
        </>
      )}
    </SButton>
  );
};

export default MakeBtn;
