import React, {
  useEffect,
  Fragment,
  useRef,
  useMemo,
  FC,
  SyntheticEvent,
} from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { useResizeDetector } from 'react-resize-detector';
import { debounce } from 'lodash';

import { AAvailableContent } from 'store/config';
import { AIsOpenChat, AIsFullScreen } from 'store/chat';
import { ACurrentTab, ATabDimension } from 'store/tabs';
import { AControllersVisible } from 'store/controllers';
import { ACupsList } from 'store/tournaments';
import { AResize } from 'store/resize';

import Icon from 'shared/components/Icon';
import { scrollToMiddle } from 'shared/helpers/scrollToMiddle';
import { useDragScroll } from 'shared/hooks/useDragScroll';

import tabs from 'constants/tabs';
import getHeaderTabs from 'constants/getHeaderTabs';
import { BETS, BETS_MOBILE, CHAT, GAME, TOURNAMENTS } from 'constants/nameTabs';

import { SWrapper, STabs, STab, STabsContent } from './style';
import { IGameTabsProps } from './types';

const GameTabs: FC<IGameTabsProps> = () => {
  const { ref: tabsContentRef } = useResizeDetector();
  const tabsRef = useRef<HTMLDivElement>(null);
  const setTabDimension = useSetRecoilState(ATabDimension);
  const availableContent = useRecoilValue(AAvailableContent);
  const isControllersVisible = useRecoilValue(AControllersVisible);
  const [isFullScreenChat, setIsFullScreenChat] = useRecoilState(AIsFullScreen);
  const [isChatOpen, setIsChatOpen] = useRecoilState(AIsOpenChat);
  const [currentTab, setCurrentTab] = useRecoilState(ACurrentTab);
  const resize = useRecoilValue(AResize);
  const cupsList = useRecoilValue(ACupsList);

  const movedRef = useDragScroll(tabsRef);

  useEffect(() => {
    if (
      window.matchMedia('(min-width: 721px)').matches &&
      (currentTab === CHAT || currentTab === BETS_MOBILE)
    ) {
      setCurrentTab(BETS);
      setIsFullScreenChat(false);
    }
  }, [currentTab, document.documentElement.clientWidth]);

  useEffect(() => {
    if (
      window.matchMedia('(min-width: 721px)').matches &&
      (currentTab === BETS || currentTab === GAME || currentTab === BETS_MOBILE)
    ) {
      setCurrentTab(BETS);
    }

    if (
      window.matchMedia('(max-width: 720px)').matches &&
      (currentTab === BETS || currentTab === BETS_MOBILE)
    ) {
      setCurrentTab(BETS_MOBILE);
    }

    if (
      window.matchMedia('(max-width: 720px)').matches &&
      currentTab !== CHAT &&
      isChatOpen
    ) {
      setCurrentTab(CHAT);
      if (isFullScreenChat) setIsFullScreenChat(false);
    }
  }, [document.documentElement.clientWidth]);

  const handleResize = () => {
    if (tabsContentRef.current) {
      let margin = 0;
      const { top, width } = tabsContentRef.current.getBoundingClientRect();

      if (currentTab === TOURNAMENTS) {
        if (document.documentElement.clientWidth > 721) margin = 38;
        else margin = 38;
      } else {
        if (document.documentElement.clientWidth > 721) margin = 60;
        else margin = 55;
      }

      if (document.documentElement.clientHeight < 660) {
        margin = 0;
      }

      setTabDimension({
        height: 0,
        width: width / resize.scale || 0,
      });

      debounce(() => {
        setTabDimension({
          height: Math.max(
            (document.documentElement.clientHeight - top - margin) /
              resize.scale,
            0,
          ),
          width: width / resize.scale || 0,
        });
      }, 100)();
    }
  };

  useEffect(() => {
    handleResize();
  }, [currentTab, isControllersVisible, resize]);

  const onTabHandler = (
    e: SyntheticEvent<HTMLElement>,
    name: string,
    isOpen: boolean,
  ) => {
    if (movedRef.current.moved) {
      return;
    }
    setCurrentTab(name);
    if (tabsRef.current && e.target) {
      scrollToMiddle(tabsRef.current, e.target as HTMLElement);
    }

    if (window.matchMedia('(max-width: 720px)').matches) {
      setIsChatOpen(isOpen);
    }
  };

  const headerTabs = useMemo(
    () => getHeaderTabs(availableContent),
    [availableContent],
  );

  return (
    <SWrapper>
      <STabs ref={tabsRef} className="no-scrollbars">
        {headerTabs.map(
          (
            { tab, title, isOpen, isHide, desktopOnly, isAvailable, iconName },
            i,
          ) => {
            return isAvailable ? (
              <STab
                key={i}
                hide={isHide}
                highlight={tab === TOURNAMENTS && !!cupsList?.length}
                desktopOnly={desktopOnly}
                isActive={currentTab === tab}
                onClick={(e) => onTabHandler(e, tab, isOpen)}
              >
                {iconName && <Icon name={iconName} />}
                {title}
              </STab>
            ) : null;
          },
        )}
      </STabs>
      <STabsContent ref={tabsContentRef}>
        {tabs().map(({ name, component }) => (
          <Fragment key={name}>
            {currentTab === name && (
              <div style={{ height: '100%' }}>{component}</div>
            )}
          </Fragment>
        ))}
      </STabsContent>
    </SWrapper>
  );
};

export default GameTabs;
