import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'shared/components/Icon';

import {
  HeaderWrapper,
  Wrapper,
  Title,
  Controls,
  MaximizeIconWrapper,
  ExpandIcon,
} from './style';
import { THeaderProps } from './types';

const Header: FC<THeaderProps> = ({
  toggleVisibleChat,
  toggleExpandChat,
  isFullScreen,
}) => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <Wrapper>
        <Title>{t('chat')}</Title>
        <Controls>
          {isFullScreen ? (
            <ExpandIcon onClick={toggleExpandChat}>
              <Icon name="ic_fullscreen02" />
            </ExpandIcon>
          ) : (
            <ExpandIcon onClick={toggleExpandChat}>
              <Icon name="ic_fullscreen01" />
            </ExpandIcon>
          )}
          <MaximizeIconWrapper onClick={toggleVisibleChat}>
            <Icon name="ic_minus" />
          </MaximizeIconWrapper>
        </Controls>
      </Wrapper>
    </HeaderWrapper>
  );
};

export default Header;
