import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  height: 22px;
  display: flex;
  justify-content: space-between;
  color: rgba(var(--grid-txt-normal), 1);
  padding: 0 4px;
  font-size: 14px;
  font-weight: 400;
`;

export const SVerticalWrapper = styled.div`
  display: block;
  color: rgba(var(--grid-txt-normal), 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;

  &:nth-child(odd) {
    background: rgba(var(--lvl-1), 1);
  }
`;

export const SDate = styled.span`
  display: block;
  height: 22px;
  padding: 4px 4px 0px;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  opacity: 0.4;
  text-align: center;
`;

export const SCol = styled.div<{ type?: string }>`
  text-align: right;
  width: 75px;

  ${({ type }) =>
    ['wa'].includes(type as string) &&
    css`
      width: 80px;
    `}

  &:first-child {
    text-align: left;
  }

  ${({ type }) =>
    type === 'bonuses' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 15px;
    `}
`;

export const SImg = styled.img`
  display: block;
`;
