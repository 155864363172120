import styled from 'styled-components';

import { TButtonsStatuses } from 'store/gameButtons';

export const SMobButton = styled.button<{
  status: TButtonsStatuses;
  isFullWidth?: boolean;
}>`
  height: 44px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 49%;
  ${({ isFullWidth }) => isFullWidth && 'width: 100%'};
  border: none;
  padding: 0 2px;
  margin: 0;
  pointer-events: auto;
  color: rgba(var(--make-cap-state1), 1);
  transition: color 0.25s ease-out;

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  background-color: ${({ status }) =>
    status === 1
      ? 'rgba(var(--primary-1), 1)'
      : status === 2
      ? 'rgba(var(--primary-2), 1)'
      : 'rgba(var(--primary-3), 1)'};
`;

export const SFirstControl = styled.span`
  display: flex;
  text-align: left;
  flex-direction: column;
  max-width: 45px;
  width: 45px;
  overflow: hidden;
`;

export const SContent = styled.span<{ status: TButtonsStatuses }>`
  max-width: 90px;
  max-height: 42px;
  overflow: hidden;
  word-break: break-word;
  font-weight: bold;

  color: ${({ status }) =>
    status === 1
      ? 'rgba(var(--make-txt-state1),1)'
      : status === 2
      ? 'rgba(var(--make-txt-state2), 1)'
      : 'rgba(var(--make-txt-state3), 1)'};
`;

export const SLastControl = styled.span`
  display: flex;
  flex-direction: column;
`;

export const STitle = styled.span<{ status: TButtonsStatuses }>`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  color: ${({ status }) =>
    status === 1
      ? 'rgba(var(--make-cap-state1), .5)'
      : status === 2
      ? 'rgba(var(--make-cap-state2), .5)'
      : 'rgba(var(--make-cap-state3), .5)'};
`;

export const SSubTitle = styled.span<{ status: TButtonsStatuses }>`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  color: ${({ status }) =>
    status === 1
      ? 'rgba(var(--make-cap-state1), .5)'
      : status === 2
      ? 'rgba(var(--make-cap-state2), .5)'
      : 'rgba(var(--make-cap-state3), .5)'};
`;

export const SAutoTitle = styled.span<{
  status: TButtonsStatuses;
  isActive: boolean;
}>`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  color: rgba(var(--bet-auto-sm-state1), 0.5);
  text-align: right;

  ${({ isActive }) => isActive && 'color: rgba(var(--bet-auto-sm-state2), 1)'};
`;

export const SAutoSubTitle = styled.span<{
  status: TButtonsStatuses;
  isActive: boolean;
}>`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  color: rgba(var(--bet-auto-sm-state1), 0.5);
  text-align: right;

  ${({ isActive }) => isActive && 'color: rgba(var(--bet-auto-sm-state2), 1)'};
`;
