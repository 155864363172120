import { atom } from 'recoil';

export type TFreeBet = {
  isActive: boolean;
  amountLeft: number;
  totalWin: number;
  totalAmountGiven: number;
  coinValue: number;
  coinValues: number[];
};

export const AFreeBet = atom<TFreeBet>({
  key: 'freeBet',
  default: {
    isActive: false,
    amountLeft: 0,
    totalWin: 0,
    totalAmountGiven: 0,
    coinValue: 0,
    coinValues: [],
  },
});
