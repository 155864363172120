export const BETS_LIST = 'betsList';
export const MY_BETS_LIST = 'myBetsList';
export const BALANCE = 'balance';

export const LEADER_BOARD = 'leaderBoard';
export const GET_LEADER_BOARD_LISTS_SELECTOR = 'getLeaderBoardListSelector';

export const BET_BUTTON_1 = 'betButton1';
export const BET_BUTTON_2 = 'betButton2';
export const TAKE_WIN_1 = 'takeWin1';
export const TAKE_WIN_2 = 'takeWin2';
export const CONTROLLER_BET_1 = 'controllerBet1';
export const CONTROLLER_BET_2 = 'controllerBet2';
export const CONTROLLER_COLLECT_1 = 'controllerCollect1';
export const CONTROLLER_COLLECT_2 = 'controllerCollect2';

export const IS_OPEN_CHAT = 'isOpenChat';
export const IS_FULL_SCREEN = 'isFullScreen';
export const MESSAGES = 'messages';
export const SCROLL_HOLDER = 'scrollHolder';

export const INITIAL_DATA = 'initialData';
export const COUNTRY = 'country';

export const IS_OPEN_INFO_BANNER = 'isOpenInfoBanner';

export const CURRENT_TAB = 'currentTab';

export const IS_SOUND = 'isSound';

export const SCREEN_HEIGHT = 'screenHeight';
export const RESIZE = 'resize';

export const TAB_DIMENSION = 'tabDimension';

export const CONTROLLERS_VISIBLE = 'controllersVisible';

export const LAST_RESULTS = 'lastResults';

export const MOBILE = 'mobile';

export const PRELOADER = 'preloader';
export const PRELOADER_LOADING_PROGRESS = 'preloader_loading_progress';

export const CUPS_LIST = 'cupsList';
export const RATING_LIST = 'ratingList';
export const TOURNAMENT = 'tournament';
export const TOURNAMENT_INFO = 'tournamentInfo';
export const ACTIVE_CONTROL = 'activeControl';

export const AUTO_BUTTONS = 'autoButtons';

export const CURRENT_PAGE = 'currentPage';
export const PER_PAGE = 'perPage';

export const TIME = 'time';
export const START_TIME = 'start_time';
export const AVAILABLE_CONTENT = 'AVAILABLE_CONTENT';

export const UI = 'UI';
