import styled from 'styled-components';

import { TBannerProps } from './types';

export const ContentBackgroundShadow = styled.span`
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  border-radius: 10px 0 0 10px;
  background-image: linear-gradient(
    to right,
    black 0,
    black 40%,
    transparent 100%
  );
  width: 300px;
`;

export const Content = styled.div`
  max-width: 198px;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  left: 0;
  right: 0;
  z-index: 3;
`;

export const Title = styled.div`
  max-height: 36px;
  font-weight: 500;
  font-size: 15px;
  color: rgba(var(--txt-h2-primary-1), 1);
  display: flex;
  word-break: break-word;
  line-height: normal;
  overflow: hidden;
`;

export const Description = styled.div`
  overflow-wrap: break-word;
  max-width: 200px;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 4px;
  font-weight: 400;
  max-height: 172px;
  color: rgba(var(--txt-normal), 1);
  display: flex;
  word-break: break-word;
  line-height: normal;
  overflow: hidden;
`;

export const ActionLinkBtn = styled.a`
  max-width: 193px;
  width: max-content;
  max-height: 34px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 22px;
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  padding: 8px 20px;
  cursor: pointer;
  margin: 0;
  border: 0;
  text-decoration: none;
  color: rgba(var(--btn-txt), 1);
  background-color: rgba(var(--primary-3), 1);
  box-shadow: 0 2px 8px rgba(var(--shdw-primary-2));

  > span {
    min-height: 18px;
    line-height: 1.286;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    max-width: 148px;
  }
  :hover {
    background-color: rgba(var(--primary-3), 0.7);
  }
`;

export const ActionBtn = styled.button`
  max-width: 193px;
  width: max-content;
  max-height: 34px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 22px;
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  padding: 8px 20px;
  cursor: pointer;
  margin: 0;
  border: 0;
  text-decoration: none;
  color: rgba(var(--btn-txt), 1);
  background-color: rgba(var(--primary-3), 1);
  box-shadow: 0 2px 8px rgba(var(--shdw-primary-2));

  > span {
    min-height: 18px;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
  :hover {
    background-color: rgba(var(--primary-3), 0.7);
  }
`;

export const Wrapper = styled.div<TBannerProps>`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 140px;
  max-height: 280px;
  height: 100%;
  border-radius: 10px;
  background-position: center 10%;
  background-image: ${({ bannerUrl }) =>
    bannerUrl ? `url(${bannerUrl})` : "url('')"};
  background-size: cover;
`;
