import styled from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  max-height: 56px;
  background-color: rgba(var(--primary-2), 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 12px 7px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 2px 5px rgba(var(--shdw-primary-3));
  z-index: 10;

  .no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const STitle = styled.div`
  color: rgba(var(--bet-auto-cap), 0.5);
  font-weight: 500;
  font-size: 10px;
  margin-left: 7px;
  text-transform: uppercase;
`;

export const SOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
`;

export const SOption = styled.button`
  font-family: 'Rubik', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 32px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 2px;
  padding: 0 5px;
  border-radius: 4px;
  color: rgba(var(--bet-auto-txt-amount), 1);
  transition: background-color 0.25s ease-out;
  font-size: 12px;
  font-weight: 500;

  span[data-icon] {
    height: 14px;
  }

  &:disabled {
    cursor: default;
  }

  &:not(:disabled):hover {
    background-color: rgba(var(--bet-auto-active), 0.5);
  }
`;
