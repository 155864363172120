import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const SList = styled.div`
  width: 100%;
  height: 100%;

  @media (orientation: landscape) {
    height: calc(100% - 38px);
    position: absolute;
  }
`;

export const STitlesWrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const STitle = styled.div<{ type?: string }>`
  color: rgba(var(--grid-title), 0.5);
  font-weight: 400;
  font-size: 12px;
  width: 75px;
  text-align: right;
  text-transform: uppercase;

  &:first-child {
    text-align: left;
  }

  &:first-child,
  &:last-child {
    padding: 0 4px;
  }

  ${({ type }) =>
    ['wa'].includes(type as string) &&
    css`
      width: 80px;
    `}

  ${({ type }) =>
    type === 'bonuses' &&
    css`
      width: 15px;
    `}
`;

export const SNoBets = styled.div`
  height: calc(100% - 50px);
  font-size: 18px;
  font-weight: 400;
  color: rgba(var(--txt-h1), 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

export const SGameName = styled.span`
  height: 24px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(var(--txt-h1), 0.8);
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  display: flex;
  text-align: center;
`;
