import i18n, { DEFAULT_NS, DEFAULT_LANG } from 'i18n/config';
import { Key } from 'i18n/Key';

import services from 'api/services';
import gameManager from 'api/gameManager/GameManager';

import { addCssRules } from 'shared/helpers/addCssRules';

import { TFiles, TCallback } from './types';

const initConfig = async (
  cb: TCallback = () => {},
  setConfig: (data: any) => void,
) => {
  if (services.getConfig) {
    const {
      cssVars,
      ...restData
    }: {
      cssVars: { [key: string]: string };
      title?: string;
      logo?: string;
      logo_portrait?: string;
    } = await services.getConfig();
    if (cssVars) {
      addCssRules(`:root{
        ${Object.keys(cssVars)
          .map((varKey) => `${varKey}:${cssVars[varKey]}`)
          .join(';')}
      }`);
    }

    if (restData) {
      setConfig(restData);

      if (restData?.title) {
        const elTitle = window.document?.querySelector('title');
        if (elTitle) elTitle.innerHTML = restData?.title;

        i18n.addResources(DEFAULT_LANG, DEFAULT_NS, {
          [Key.title]: restData?.title || Key.title,
        });
      }
    }
  }

  cb();
};

export const files: TFiles = {
  callback: [
    (cb, { setConfig }) => {
      initConfig(cb, setConfig);
    },
    (cb, { el }) => {
      if (el) gameManager.attach(el);
      cb();
    },
  ],
};
