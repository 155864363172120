import { FC } from 'react';
import dayjs from 'dayjs';

import { SORTED_COLS } from 'store/myBets';

import imgPlusSrc from 'assests/icons/img_plus.svg';
import imgXSrc from 'assests/icons/img_x.svg';

import { SWrapper, SVerticalWrapper, SCol, SDate, SImg } from './style';
import { IBetsItemProps } from './types';

const BetsItem: FC<IBetsItemProps> = ({
  data: { bf = 0, bm = 0, ...data },
  style,
}) => (
  <SVerticalWrapper style={style}>
    <SDate>
      {data.rst ? dayjs(data.rst).format('HH:mm:ss DD.MM.YY') : '-'}
    </SDate>
    <SWrapper>
      {[...SORTED_COLS].map(([key]) =>
        key !== 'bonuses' ? (
          <SCol key={key}>{data[key] || '-'}</SCol>
        ) : (
          <SCol key={key} type={key}>
            {bf > 0 && <SImg src={imgPlusSrc} alt="plus" />}
            {bm > 1 && <SImg src={imgXSrc} alt="multi" />}
          </SCol>
        ),
      )}
    </SWrapper>
  </SVerticalWrapper>
);

export default BetsItem;
