import styled from 'styled-components';

export const SScreenWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-top: var(--game-canvas-height);
  position: relative;
  margin: 10px 0;

  @media (max-width: 991.98px) {
    margin: 0;
  }

  @media (min-height: 800px) and (max-width: 420px) and (orientation: portrait) {
    padding-top: var(--game-canvas-height-mobile-high);
  }

  @media (min-height: 660px) and (max-width: 380px) and (orientation: portrait) {
    padding-top: var(--game-canvas-height-mobile-high-660);
  }

  @media (max-height: 570px) {
    padding-top: var(--game-canvas-height-mobile);
  }
`;

export const SScreenInner = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  @media (max-width: 415px) {
    border-radius: 0;
  }
`;

export const SScreenBottom = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
`;
