import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { IModalButton, AModalSelector } from 'store/modal';

import {
  SWrapper,
  SOverlay,
  SModal,
  SInner,
  SHeader,
  SMessage,
  SButtonWrapper,
  SButton,
  SButtonText,
} from './style';

const Modal: FC = () => {
  const { t } = useTranslation();
  const portalEL = document.getElementById('portal');
  const [modal, setModal] = useRecoilState(AModalSelector);

  const closeModal = () => {
    setModal(null);
    (modal?.buttons || []).forEach(({ type, action }) => {
      if (type === 'close' && action) action();
    });
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleQuit = () => {
    setModal({
      title: t('thankYouForPlaying'),
      buttons: [
        {
          label: t('reload'),
          type: 'reload',
        },
      ],
      isNeededAuth: true,
    });
  };

  const handleCallback = (
    type: IModalButton['type'],
    action: IModalButton['action'],
  ) => {
    switch (type) {
      case 'quit': {
        if (action) action();
        handleQuit();
        break;
      }
      case 'reload': {
        if (action) action();
        handleReload();
        break;
      }
      case 'lobby':
      case 'history':
        if (action) action();
        break;
      case 'close':
      case 'continue':
      default:
        closeModal();
    }
  };

  return modal && portalEL
    ? createPortal(
        <SWrapper>
          <SModal>
            <SInner>
              <SHeader>{modal.title}</SHeader>
              <SMessage>{modal.message}</SMessage>
              <SButtonWrapper>
                {(modal.buttons || []).map(({ type, label, action }) => (
                  <SButton
                    key={type}
                    mode={type === 'reload' ? 'inverse' : null}
                    onClick={() => handleCallback(type, action)}
                  >
                    <SButtonText>{label}</SButtonText>
                  </SButton>
                ))}
              </SButtonWrapper>
            </SInner>
          </SModal>
          <SOverlay />
        </SWrapper>,
        portalEL,
      )
    : null;
};

export default Modal;
