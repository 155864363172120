import { atom, selector } from 'recoil';

import notificationManager from 'api/notificationManager/NotificationManager';

import {
  FilterType,
  mapToCoefficientItem,
  mapToWinItem,
  ICoefficientItemType,
  IWinItemType,
  FilterPeriod,
} from 'constants/leaderBoard';

export const paramsA = atom({
  key: 'paramsA',
  default: {
    type: FilterType.HUGEWINS,
    period: FilterPeriod.WEEK,
  },
});

export const getItemsS = selector({
  key: 'getItemsS',
  get: async ({ get }) => {
    const param = get(paramsA);

    const {
      _d: { arr, nu: updatedDate },
    }: { _d: { arr: (ICoefficientItemType | IWinItemType)[]; nu: number } } =
      await (param.type === FilterType.COEFFICIENTS
        ? notificationManager.getLBCoefficient({
            gt: param.period,
          })
        : notificationManager.getLBWins({
            t: param.type,
            gt: param.period,
          }));

    const items = arr.map((item) => {
      return Object.keys(item).reduce(
        (acc, key) => ({
          ...acc,
          [param.type !== FilterType.COEFFICIENTS
            ? mapToWinItem[key]
            : // @ts-ignore
              mapToCoefficientItem[key]]: item[key],
        }),
        {},
      );
    });

    return {
      items,
      updatedDate,
    };
  },
});
