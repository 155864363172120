import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import i18n from 'i18n/config';

import { ATournament } from 'store/tournaments';
import { ATime } from 'store/time';
import { AInitialData } from 'store/init';

import Icon from 'shared/components/Icon';
import { getTournamentsStatus } from 'shared/helpers/getTournamentsStatus';
import {
  convertEndedTime,
  convertTimeToString,
} from 'shared/helpers/convertTime';
import { getTranslation } from 'shared/helpers/getTranslations';

import { Statuses } from 'constants/TournamentsStatuses';
import { DEFAULT_CURRENCY } from 'constants/currency';

import {
  SAvatarImg,
  SAvatarPlaceholder,
  SAvatarWrapper,
  SCash,
  SContainer,
  SContent,
  SCupIcon,
  SDescription,
  SDetails,
  SDetailsTitle,
  SDot,
  SInfo,
  SStatusBtn,
  STime,
  SWrapper,
} from './style';
import { ITournamentsListItem } from './types';

const TournamentsListItem: FC<ITournamentsListItem> = ({
  setIsTournamentOpen,
  style,
  ...rest
}) => {
  const {
    id,
    aa: autoApply,
    d: description,
    st: startAt,
    et: endAt,
    im: imageUrl,
    n: name,
    r_p: prizes,
    t: terms,
    dcc,
    dlc,
  } = rest;
  const {
    chat: { cc: currencyCode },
  } = useRecoilValue(AInitialData);
  const setTournament = useSetRecoilState(ATournament);
  const time = useRecoilValue(ATime);
  const [tournamentTime, setTournamentTime] = useState<string>('');
  const [currentStatus, setCurrentStatus] = useState<
    Statuses.SOON | Statuses.LIVE | Statuses.ENDED
  >(Statuses.SOON);
  const { t } = useTranslation();

  const tournamentHandler = () => {
    setIsTournamentOpen(true);
    setTournament({
      id,
      aa: autoApply,
      d: description,
      st: startAt,
      et: endAt,
      im: imageUrl,
      n: name,
      r_p: prizes,
      t: terms,
      dcc,
      dlc,
    });
  };

  useEffect(() => {
    const newStatus = getTournamentsStatus(time, startAt, endAt);

    if (newStatus !== currentStatus) {
      setCurrentStatus(newStatus);
    }
  }, [time, currentStatus]);

  useEffect(() => {
    const newStatus = getTournamentsStatus(time, startAt, endAt);

    switch (newStatus) {
      case Statuses.SOON:
        setTournamentTime(convertTimeToString(startAt - time));
        break;
      case Statuses.LIVE:
        setTournamentTime(convertTimeToString(endAt - time));
        break;
      case Statuses.ENDED:
        setTournamentTime(convertEndedTime(time - endAt));
        break;
    }
  }, [time]);

  return (
    <SWrapper style={style}>
      <SContainer>
        <SAvatarWrapper>
          {imageUrl ? <SAvatarImg src={imageUrl} /> : <SAvatarPlaceholder />}
          <SCupIcon status={currentStatus}>
            <Icon name="ic_cup_small" />
          </SCupIcon>
        </SAvatarWrapper>
        <SContent>
          <SInfo>
            <SDescription>
              {getTranslation(name, i18n.language, dlc)}
            </SDescription>
            <SStatusBtn status={currentStatus} onClick={tournamentHandler}>
              <span>
                {currentStatus === Statuses.ENDED
                  ? t('results')
                  : t('moreInfo')}
              </span>
            </SStatusBtn>
          </SInfo>
          <SDetails>
            <SDetailsTitle>
              {currentStatus === Statuses.SOON
                ? t('startIn')
                : currentStatus === Statuses.LIVE
                ? t('live')
                : t('ended')}
            </SDetailsTitle>
            <STime status={currentStatus}>
              {currentStatus === Statuses.LIVE ? (
                <>
                  <SDot>&#183;</SDot> {tournamentTime}
                </>
              ) : (
                tournamentTime
              )}
            </STime>
            <SDetailsTitle>{t('prizePool')}</SDetailsTitle>
            <SCash>
              {getTranslation(
                prizes?.total?.curr || {},
                currencyCode,
                dcc,
                DEFAULT_CURRENCY,
              )}
            </SCash>
          </SDetails>
        </SContent>
      </SContainer>
    </SWrapper>
  );
};

export default TournamentsListItem;
