import { WsBaseRequest } from 'api/ws/WsRequest';
import { WsBaseResponse } from 'api/ws/WsResponse';
import { WsClient, IClientLifecycleListener } from 'api/ws/WsClient';

export const WsError = {
  ERR_NO_INTERNET_CONNECTION: -5,
  ERR_TIMEOUT: -6,
};

export type TCallback = (resp: WsBaseResponse) => void;

export type ConnectionInfo = {
  latency: any;
};

export interface ILifecycleListener {
  onOpen: (event: Event) => void;
  onError: (event: Event) => void;
  onClose: (event: CloseEvent) => void;
  connectionInfo: (info: ConnectionInfo) => void;
}

export interface IInterceptor {
  intercept: (data: any) => boolean;
}

const REQUEST_TIMEOUT_MILLIS = 7000;
const PING_INTERVAL = 7000;

class RequestObj {
  wsWrapper: WsWrapper;
  request_id: number;
  request: any;
  CB: ((resp: WsBaseResponse) => void) | null;
  timeout: any;

  constructor(
    wsWrapper: WsWrapper,
    request_id: number,
    request: any,
    CB: TCallback | null,
  ) {
    this.wsWrapper = wsWrapper;
    this.request_id = request_id;
    this.request = request;
    this.CB = CB;
    this.timeout = null;
  }

  cancel() {
    this._clearTimeout();
    if (this.wsWrapper) this.wsWrapper.cancelReq(this.request_id);
  }

  _clearTimeout() {
    if (!this.timeout) return;
    clearTimeout(this.timeout);
    this.timeout = null;
  }
}

function _createRequest(method: string, request_id: number, data: any) {
  return {
    m: method,
    r: request_id,
    d: data,
  };
}

export default class WsWrapper implements IClientLifecycleListener {
  ws: WsClient;
  requestId: number;
  requests: { [key: string]: RequestObj };
  staticListeners: { [key: string]: TCallback };
  lifecycleListeners: Set<ILifecycleListener>;
  _interceptor: IInterceptor | null;
  _pingInterval: any;

  constructor(ws: WsClient, lifecycleListener: ILifecycleListener) {
    this.ws = ws;
    this.requestId = 0;
    this.requests = {};
    this.staticListeners = {};
    this.lifecycleListeners = new Set();
    this._interceptor = null;
    this._pingInterval = null;
    if (lifecycleListener) {
      this.subscribeLifecycleListener(lifecycleListener);
    }
    this._init();
  }

  destroy() {
    this.ws.destroy();
    clearInterval(this._pingInterval);
  }

  set interceptor(mInterceptor: IInterceptor) {
    this._interceptor = mInterceptor;
  }

  _init() {
    this.ws.onMessageListener = this._handleMessage.bind(this);
    this.ws.lifecycleListener = this;
  }

  sendMessage(
    wsRequest: WsBaseRequest,
    CB: TCallback | null,
    timeout?: number,
  ): RequestObj | null {
    if (!this.ws) {
      if (CB)
        CB(
          new WsBaseResponse({
            re: false,
            d: null,
            err: { c: WsError.ERR_NO_INTERNET_CONNECTION, msg: '' },
          }),
        );
      return null;
    }

    let rID = ++this.requestId;

    let req = _createRequest(wsRequest.method, rID, wsRequest.data);

    return this._sendMsg(rID, req, CB, timeout);
  }

  async asyncSendMessage(
    wsRequest: WsBaseRequest,
    timeout?: number,
  ): Promise<WsBaseResponse> {
    return new Promise((resolve, reject) => {
      this.sendMessage(
        wsRequest,
        function (resp) {
          resolve(resp);
        },
        timeout,
      );
    });
  }

  _sendMsg(
    rID: number,
    req: any,
    CB: TCallback | null,
    timeout: number = REQUEST_TIMEOUT_MILLIS,
  ): RequestObj {
    let reqObj = new RequestObj(this, rID, req, CB);

    if (CB) {
      this.requests[rID] = reqObj;

      let ie = this._internalError.bind(this, rID, WsError.ERR_TIMEOUT);

      reqObj.timeout = setTimeout(function () {
        ie();
      }, timeout);
    }

    let msg = JSON.stringify(req);

    let result = this.ws.sendMessage(msg);

    if (!result) {
      this._internalError(rID, WsError.ERR_NO_INTERNET_CONNECTION);
    }

    return reqObj;
  }

  cancelReq(requestId: any) {
    delete this.requests[requestId];
  }

  isConnected() {
    return this.ws.isConnected;
  }

  onOpen(event: Event) {
    this.lifecycleListeners.forEach((l) => {
      try {
        l.onOpen(event);
      } catch (e) {
        console.log(e);
      }
    });

    this._startPing();
  }

  onError(event: Event) {
    this.lifecycleListeners.forEach((l) => {
      try {
        l.onError(event);
      } catch (e) {
        console.log(e);
      }
    });
  }

  onClose(event: CloseEvent) {
    this.lifecycleListeners.forEach((l) => {
      try {
        l.onClose(event);
      } catch (e) {
        console.log(e);
      }
    });
  }

  subscribeLifecycleListener(listener: ILifecycleListener) {
    this.lifecycleListeners.add(listener);
  }

  unsubscribeLifecycleListener(listener: ILifecycleListener) {
    try {
      this.lifecycleListeners.delete(listener);
    } catch (e) {
      console.log(e);
    }
  }

  // adds listener for emit with such method
  subscribeStatic(method: string, CB: TCallback) {
    this.staticListeners[method] = CB;
  }

  unsubscribeStatic(method: string) {
    if (!method) return;
    delete this.staticListeners[method];
  }

  _internalError(requestId: number, code: number) {
    if (this.requests.hasOwnProperty(requestId) && this.requests[requestId]) {
      let obj = this.requests[requestId];
      obj.cancel();
      if (obj.CB) {
        obj.CB(
          new WsBaseResponse({ re: false, d: null, err: { c: code, msg: '' } }),
        );
      }
    }
  }

  _handleMessage(message: any) {
    if (message instanceof ArrayBuffer) {
      if (message.byteLength === 8) {
        this._handlePing(message);
      }

      return;
    }

    let data;

    try {
      data = JSON.parse(message);
    } catch (e) {
      console.log(e);
      return;
    }

    let rId = data.r;

    if (this._interceptor) {
      if (this._interceptor.intercept(data)) {
        let obj = this.requests[rId];
        if (obj) obj.cancel();

        return;
      }
    }

    if (!rId) {
      if (!data.m) return;

      let CB = this.staticListeners[data.m];

      if (!CB) return;

      CB(new WsBaseResponse({ re: data.re, d: data.d, err: data.err }));
    }

    let obj = this.requests[rId];

    if (!obj) return;

    obj.cancel();

    if (obj.CB) {
      obj.CB(new WsBaseResponse({ re: data.re, d: data.d, err: data.err }));
    }
  }

  setReconnect(reconnect: boolean) {
    this.ws.setReconnect(reconnect);
  }

  shouldReconnect(): boolean {
    return this.ws.shouldReconnect();
  }

  _ping() {
    try {
      let result = this.ws.sendMessage(
        bigIntToByteArrayUInt64(BigInt(Date.now()) * 1000000n),
      );

      if (!result) {
        this._notifyConnectionInfoUpdated({ latency: Infinity });
      }
    } catch (e) {
      let result = this.ws.sendMessage(new ArrayBuffer(8));

      if (!result) {
        this._notifyConnectionInfoUpdated({ latency: Infinity });
      }
    }
  }

  _handlePing(msg: ArrayBuffer) {
    let latency: any;
    try {
      latency = Date.now() - Number(byteArrayUInt64ToBigInt(msg) / 1000000n);
    } catch (e) {
      latency = 'unknown';
    }

    this._notifyConnectionInfoUpdated({ latency });
  }

  _notifyConnectionInfoUpdated(connInfo: ConnectionInfo) {
    this.lifecycleListeners.forEach((l) => {
      try {
        l.connectionInfo(connInfo);
      } catch (e) {
        console.log(e);
      }
    });
  }

  sendPingMessage() {
    this._ping();
  }

  _startPing() {
    if (this._pingInterval) {
      return;
    }

    this._ping();
    this._pingInterval = setInterval(this._ping.bind(this), PING_INTERVAL);
  }
}

function bigIntToByteArrayUInt64(bigIntValue: bigint) {
  const arr = new ArrayBuffer(8);
  new DataView(arr).setBigUint64(0, BigInt.asUintN(64, bigIntValue), true);
  return arr;
}

function byteArrayUInt64ToBigInt(arr: ArrayBuffer) {
  return BigInt.asIntN(64, new DataView(arr).getBigUint64(0, true));
}
