import { FC, memo } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/config';

import { ATournamentInfo } from 'store/tournaments';

import { getTranslation } from 'shared/helpers/getTranslations';
import { getCutString } from 'shared/helpers/getCutString';

import { SMoreBtn, SSubTitle, STitle } from './style';

const ExtraContentInfo: FC<{
  id: number;
  name: { [key: string]: string };
  description: { [key: string]: string };
  dlc: string;
}> = ({ name, description, dlc }) => {
  const { t } = useTranslation();
  const [tournamentInfo, setTournamentInfo] = useRecoilState(ATournamentInfo);
  const tournamentInfoToggle = () => setTournamentInfo(!tournamentInfo);

  return (
    <>
      <STitle>{getTranslation(name, i18n.language, dlc)}</STitle>
      <SSubTitle>
        {!tournamentInfo
          ? getCutString(getTranslation(description, i18n.language, dlc))
          : getTranslation(description, i18n.language, dlc)}
        {tournamentInfo && <br />}
        {getTranslation(description, i18n.language, dlc).length > 89 && (
          <SMoreBtn isSmall={!tournamentInfo} onClick={tournamentInfoToggle}>
            {!tournamentInfo ? t('more') : t('showLess')}
          </SMoreBtn>
        )}
      </SSubTitle>
    </>
  );
};

export default memo(ExtraContentInfo, (p, n) => {
  return p.id === n.id;
});
