import { atom } from 'recoil';

import { TAB_DIMENSION } from 'store/constants';

export const ATabDimension = atom<{ width: number; height: number }>({
  key: TAB_DIMENSION,
  default: {
    width: 0,
    height: 0,
  },
});
