import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: none;
  @media (max-width: 720px) {
    display: block;
    border-right: none;
    max-width: 100%;
  }

  //@media (max-width: 415px) {
  //  display: block;
  //  border-right: none;
  //  max-width: 100%;
  //}
`;

export const IconContainer = styled.div`
  position: absolute;
  z-index: 80;
  right: 12px;
  top: 3px;
  opacity: 40%;
  cursor: pointer;
`;
