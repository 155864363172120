import { FC } from 'react';

import Icon from 'shared/components/Icon';

import { Wrapper, IconContainer } from './style';
import { TMobileHeaderProps } from './types';

const MobileHeader: FC<TMobileHeaderProps> = ({
  toggleExpandChat,
  isFullScreen,
}) => (
  <Wrapper>
    <IconContainer onClick={toggleExpandChat}>
      <Icon name={isFullScreen ? 'ic_fullscreen02' : 'ic_fullscreen01'} />
    </IconContainer>
  </Wrapper>
);

export default MobileHeader;
