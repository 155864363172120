import styled from 'styled-components';

import { TWrapperChatProps } from './types';

export const Wrapper = styled.div<TWrapperChatProps>`
  height: ${({ isFullScreen }) =>
    isFullScreen ? 'calc(100% - 40px)' : '440px'};
  width: 360px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: var(--z-index-999);

  @media (max-width: 991.98px) {
    bottom: 10px;
    right: 10px;
  }

  @media (max-width: 720px) {
    position: absolute;
    width: calc(100% - 20px);
    height: ${({ isFullScreen, screenHeight }) =>
      !isFullScreen && screenHeight
        ? `calc(100% - ${screenHeight}px - 100px)`
        : 'calc(100% - 160px)'};
    bottom: 36px;
    //bottom: 42px;
    right: 0;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 415px) {
    //bottom: 50px;
    bottom: 35px;
    height: ${({ isFullScreen, screenHeight }) =>
      !isFullScreen && screenHeight
        ? // ? `calc(100% - ${screenHeight}px - 110px)`
          `calc(100% - ${screenHeight}px - 85px)`
        : 'calc(100% - 160px)'};
    width: 100vw;
    margin: 0;
  }

  @media (max-height: 440px) and (min-width: 721px) {
    right: 0;
    bottom: 0;
    height: 100%;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
  background: rgba(var(--lvl-0), 1);
  border: 1px solid rgba(var(--lvl-2), 1);
  box-shadow: 0 6px 10px rgba(var(--shdw-primary-1));

  @media (max-width: 720px) {
    box-shadow: none;
    border: none;
  }
  //@media (max-width: 415px) {
  //  box-shadow: none;
  //  border: none;
  //}
`;
