import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  background-color: rgba(var(--lvl-0), 1);
  border-radius: 6px;
  max-height: 113px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 991.98px) {
    flex-wrap: wrap;

    & > div:first-child {
      margin-bottom: 5px;
    }

    max-height: 232px;
  }

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 100%;

    & > div:first-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 720px) {
    padding: 4px;
  }
`;

const hideBtn = css`
  @media (max-width: 720px) {
    width: 100%;
    margin-top: 4px;
    display: none;
  }
`;

const showBtn = css`
  @media (max-width: 720px) {
    width: 100%;
    margin-top: 4px;
  }
`;

export const SMobileDisabled = styled.div<{ isVisible?: boolean }>`
  width: 49%;
  ${({ isVisible }) => (isVisible ? showBtn : hideBtn)};
  position: relative;

  @media (max-width: 991.98px) {
    width: 100%;
  }
`;

export const SDesktop = styled.div<{ isHide?: boolean; isFullWidth?: boolean }>`
  width: 49%;
  position: relative;
  ${({ isFullWidth }) => isFullWidth && 'width: 100%'};

  @media (max-width: 991.98px) {
    width: 100%;
  }

  @media (max-width: 720px) {
    width: 100%;

    ${({ isHide }) => isHide && 'display: none'};
  }
`;

export const SAddControlBtn = styled.button<{ isHide?: boolean }>`
  width: 82px;
  min-width: 82px;
  height: 30px;
  cursor: pointer;
  background-color: rgba(var(--primary-1), 0.1);
  border: none;
  padding: 0;
  margin: 4px 0 6px 0;
  border-radius: 4px;
  transition: all 0.25s ease-out;

  [data-icon] {
    color: rgba(var(--primary-1), 1);
    > span > svg {
      margin-top: -4px;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    transform: translate(0, 3px);
  }

  @media (max-width: 720px) {
    ${({ isHide }) => isHide && 'display: none'};
  }
`;

export const SMobileFooter = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: flex;
    align-items: center;
  }
`;

export const SMobileExpand = styled.div<{ isHide?: boolean }>`
  display: none;
  width: 100%;
  @media (max-width: 720px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ isHide }) => isHide && 'display: none'};
  }
`;
