import styled, { css } from 'styled-components';

const wrapperStyle = css`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  background-color: rgba(var(--lvl-0), 1);
  border-radius: 6px;
`;

export const SWrapperPopup = styled.div`
  ${wrapperStyle};
  position: absolute;
  left: 0;
  top: 50px;
  height: calc(100% - 50px);
  border-radius: 0;
  z-index: 100;
  animation: 0.25s fadeIn forwards;

  div {
    border-radius: 0;
  }

  @media (max-width: 720px) {
    border-radius: 6px;

    top: 0;
    height: 100%;
  }

  :before {
    content: '';
    border-radius: 6px;
    position: absolute;
    display: block;
    height: 140px;
    width: 100%;

    background: linear-gradient(
      to bottom,
      rgba(var(--primary-2), 0.3) 0%,
      rgba(var(--primary-2), 0.15) 30%,
      rgba(var(--primary-2), 0) 100%
    );
  }
`;

export const SCloseButton = styled.button`
  color: rgba(var(--white), 1);
  border: none;
  background: none;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`;

export const SPopupScreenTitle = styled.span`
  position: absolute;
  width: 100%;
  padding: 0 55px;
  text-align: center;
  top: 10px;
  font-weight: 500;
  font-size: 18px;
`;

export const SPopupScreenContent = styled.div`
  padding: 40px 20px 20px 20px;
`;
