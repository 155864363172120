import { DEFAULT_LANG } from 'constants/locales';

export const getTranslation = (
  dict: { [key: string]: string },
  key: string, //language or currency
  defaultKey: string, //default language or currency
  fallbackKey: string = DEFAULT_LANG, //fallback language or currency
): string => {
  return (
    dict[key] ||
    dict[key?.toUpperCase() || key] ||
    dict[key?.toLowerCase() || key] ||
    dict[defaultKey] ||
    dict[defaultKey?.toUpperCase() || defaultKey] ||
    dict[defaultKey?.toLocaleLowerCase() || defaultKey] ||
    dict[fallbackKey] ||
    ''
  );
};
