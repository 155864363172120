import { FC } from 'react';

const Span: FC<{ html: string; isBlock?: boolean }> = ({
  html,
  isBlock = false,
}) =>
  isBlock ? (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <span dangerouslySetInnerHTML={{ __html: html }} />
  );

export default Span;
