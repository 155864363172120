import styled, { css } from 'styled-components';

import { TButtonsStatuses } from 'store/gameButtons';

export const SButton = styled.button<{ status?: TButtonsStatuses }>`
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 82px;
  border-radius: 4px 0 0 4px;
  pointer-events: auto;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  color: rgba(var(--make-txt-state1), 1);
  background-color: ${({ status }) =>
    status === 1
      ? 'rgba(var(--primary-1), 1)'
      : status === 2
      ? 'rgba(var(--primary-2), 1)'
      : 'rgba(var(--primary-3), 1)'};

  height: 113px;
  transition: color 0.25s ease-out;
`;

export const STitle = styled.span<{ status?: number }>`
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 6px;
  color: ${({ status }) =>
    status === 1
      ? 'rgba(var(--make-txt-state1), 1)'
      : 'rgba(var(--make-txt-state2), 1)'};
  text-transform: uppercase;
`;

export const SSubTitle = styled.span`
  font-weight: 500;
  font-size: 10px;
  opacity: 50%;
  text-transform: uppercase;
  color: rgba(var(--make-cap-state2), 1);
`;

const infoStyle = css`
  font-weight: 500;
  display: inline-block;
  font-size: 15px;
  height: 28px;
  line-height: 28px;
  color: rgba(var(--make-txt-state3), 1);
  max-width: 78px;
  overflow: hidden;
`;

export const SPrice = styled.span`
  ${infoStyle};
`;

export const SFreeBets = styled.span`
  ${infoStyle};
  margin-top: -10px;
  font-size: 12px;
  white-space: nowrap;
`;

export const STakeTitle = styled.span`
  font-weight: 500;
  font-size: 10px;
  color: rgba(var(--make-cap-state3), 0.5);
  text-transform: uppercase;
`;
