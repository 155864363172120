import { FC } from 'react';

import Span from 'shared/components/Span';

import { SWrapper, SValue, SLabel } from './style';
import { IFieldProps } from './types';

const Field: FC<IFieldProps> = ({
  color = 'white',
  value,
  label,
  unit = '&times;',
  align = 'right',
}) => {
  return (
    <SWrapper align={align}>
      <SValue color={color}>
        <Span html={unit} />
        <span title={value as string}>{value}</span>
      </SValue>
      <SLabel>{label}</SLabel>
    </SWrapper>
  );
};

export default Field;
