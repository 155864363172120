import { FC } from 'react';

import gameManager from 'api/gameManager/GameManager';

import Icon from 'shared/components/Icon';

import {
  ActionLinkBtn,
  ActionIconBtn,
  Content,
  Title,
  Wrapper,
  ContentBackgroundShadow,
  ActionBtn,
} from './style';
import { TSecondBannerType } from './types';

// If “link_type” = 1. The link is external. WIll be opened in a new tab.
const LinkType1 = 1;
//If “link_type” = 2. The link is internal. Will be forwarded to a route with the same domain. (Not supported)
const LinkType2 = 2;
// If “link_type” = 3. The link is an action
const LintType3 = 3;

const SecondBannerType: FC<TSecondBannerType> = ({
  serviceMessage,
  getTranslation,
}) => {
  const handleLinkClick = () => {
    if (serviceMessage?.lt === LintType3) {
      gameManager.processServiceMessageLink(
        serviceMessage.lt,
        getTranslation(serviceMessage!.l_l),
      );
    }
  };

  return (
    <Wrapper bannerUrl={getTranslation(serviceMessage!.i_l)}>
      <ContentBackgroundShadow />
      <Content>
        <Title>{getTranslation(serviceMessage!.ti_l)}</Title>
        {serviceMessage!.lt === LinkType1 ? (
          <ActionLinkBtn
            href={getTranslation(serviceMessage!.l_l)}
            target="_blank"
          >
            <span>{getTranslation(serviceMessage!.bt_l)}</span>
            <ActionIconBtn>
              <Icon name="ic_arrow_right" />
            </ActionIconBtn>
          </ActionLinkBtn>
        ) : (
          <ActionBtn onClick={handleLinkClick}>
            <span>{getTranslation(serviceMessage!.bt_l)}</span>
          </ActionBtn>
        )}
      </Content>
    </Wrapper>
  );
};

export default SecondBannerType;
