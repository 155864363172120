export class WsBaseRequest {
  _m: string;
  _d: any;

  constructor(methodName: string, data: any) {
    this._m = methodName;
    this._d = data;
  }

  get data() {
    return this._d;
  }

  get method() {
    return this._m;
  }
}
