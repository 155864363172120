import styled, { css } from 'styled-components';

import { IToastProps } from './types';

export const SWrapper = styled.div<{ type: IToastProps['type'] }>`
  position: relative;
  margin: 10px auto;
  display: flex;
  max-width: 296px;
  width: 100%;
  min-height: 40px;
  border-radius: 22px;
  padding: 3px 20px;
  justify-content: space-between;
  align-items: center;

  ${({ type }) =>
    type === 'success'
      ? css`
          background-color: rgba(var(--primary-3), 1);
          color: rgba(var(--txt-h2), 1);
          box-shadow: 0 2px 5px rgba(var(--shdw-primary-2));
        `
      : css`
          background-color: rgba(var(--primary-1), 1);
          color: rgba(var(--txt-h2-dark), 1);
          box-shadow: 0 2px 5px rgba(var(--shdw-primary-1));
        `};
`;

export const SExtraButton = styled.button<{ mode: IToastProps['type'] }>`
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
  min-height: 34px;
  border-radius: 20px;
  min-width: 120px;
  padding: 0 12px;
  margin-right: -16px;
  cursor: pointer;
  background-color: rgba(var(--lvl-0), 0.2);
  color: rgba(var(--txt-white), 1);

  && [data-icon] {
    color: rgba(var(--txt-white), 1);
  }
`;

export const SIcon = styled.div`
  height: 24px;
  width: 24px;

  [data-icon] {
    color: rgba(var(--dark), 1);
  }
`;

export const SText = styled.span`
  font-size: 15px;
  font-weight: 500;
  padding: 6px 0;
  margin-right: 10px;
`;

export const SExtraText = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  color: rgba(var(--txt-white), 1);
`;
