// Content types:
export const MESSAGE_CONTENT_TYPE_TEXT = 1;
export const MESSAGE_CONTENT_TYPE_STICKER = 2;
export const MESSAGE_CONTENT_TYPE_BANNER = 3;

// Target types:
export const MESSAGE_TARGET_TYPE_ALL = 1;
export const MESSAGE_TARGET_TYPE_PRIVATE = 2;
export const MESSAGE_TARGET_TYPE_GROUP = 3;

// Sender types:
export const MESSAGE_SENDER_TYPE_USER = 1;
export const MESSAGE_SENDER_TYPE_SERVICE = 2;

// Service Message
// Content type:
export const SERVICE_MESSAGE_BANNER_TYPE_1 = 1;
export const SERVICE_MESSAGE_BANNER_TYPE_2 = 2;
//Hot drop banner
export const SERVICE_MESSAGE_BANNER_TYPE_3 = 3;

// Banner Type:
export const SERVICE_MESSAGE_CONTENT_TYPE_TEXT = 1;
export const SERVICE_MESSAGE_CONTENT_TYPE_BANNER = 2;

// Link type:
export const SERVICE_MESSAGE_LINK_TYPE_EXTERNAL = 1;
export const SERVICE_MESSAGE_LINK_TYPE_INTERNAL = 2;
export const SERVICE_MESSAGE_LINK_TYPE_ACTION = 3;
