import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  overflow: hidden;
`;

export const SList = styled.ul`
  display: flex;
  height: 55px;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  gap: 1px;
`;

export const SValueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
`;

export const SItem = styled.li`
  flex: 1 0;
  background: rgba(var(--primary-3), 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const SLabel = styled.div`
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

export const SValue = styled.div<{ color?: 'green' }>`
  font-size: 14px;
  font-weight: 400;
  padding: 0 2px;

  ${({ color }) =>
    color === 'green' &&
    css`
      color: rgba(var(--primary-3), 1);
    `}
`;

export const SValue2 = styled.div`
  font-size: 11px;
  font-weight: 400;
  padding: 0 2px;
  margin-top: 3px;
`;
