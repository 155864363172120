import i18n from 'i18n/config';

type TBetsTitles = {
  id: string;
  name: 'USER' | 'BET' | 'COLLECT' | 'WIN' | 'BONUSES';
}[];

export const betsTitles: TBetsTitles = [
  { id: 'user', name: i18n.t('user').toUpperCase() as 'USER' },
  { id: 'bet', name: i18n.t('bet').toUpperCase() as 'BET' },
  { id: 'collect', name: i18n.t('collect').toUpperCase() as 'COLLECT' },
  { id: 'win', name: i18n.t('win').toUpperCase() as 'WIN' },
  { id: 'bonuses', name: '' as 'BONUSES' },
];
