import { FC } from 'react';

import {
  Wrapper,
  ContentBackgroundShadow,
  Content,
  Title,
  Description,
  ActionBtn,
  ActionLinkBtn,
} from './style';
import { TFirstBannerType } from './types';

const FirstBannerType: FC<TFirstBannerType> = ({
  serviceMessage,
  getTranslation,
}) => (
  <Wrapper bannerUrl={getTranslation(serviceMessage!.i_l)}>
    <ContentBackgroundShadow />
    <Content>
      <Title>{getTranslation(serviceMessage!.ti_l)}</Title>
      <Description>{getTranslation(serviceMessage!.t_l)}</Description>
      {serviceMessage!.lt === 1 ? (
        <ActionLinkBtn
          href={getTranslation(serviceMessage!.l_l)}
          target="_blank"
        >
          <span>{getTranslation(serviceMessage!.bt_l)}</span>
        </ActionLinkBtn>
      ) : (
        <ActionBtn>
          <span>{getTranslation(serviceMessage!.bt_l)}</span>
        </ActionBtn>
      )}
    </Content>
  </Wrapper>
);

export default FirstBannerType;
