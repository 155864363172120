import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  position: absolute;
  z-index: 20;
  max-width: 360px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media (max-width: 720px) {
    display: none;
    border-right: none;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(var(--lvl-3), 1);
  padding: 0 10px;
  border-radius: 4px 4px 0 0;

  @media (max-width: 720px) {
    border-right: none;
    max-width: 100%;
  }
`;
export const Title = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  padding: 2px 6px 2px 6px;
  position: relative;
  text-transform: uppercase;
  color: rgba(var(--tab-txt-active), 1);

  :before {
    content: '';
    height: 3px;
    width: 100%;
    border-radius: 0 0 3px 3px;
    background: rgba(var(--tab-active), 1);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExpandIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 16px;
  cursor: pointer;
  color: rgba(var(--white), 0.5);

  &:hover {
    color: rgba(var(--white), 0.8);
  }
`;

export const MaximizeIconWrapper = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: rgba(var(--white), 0.5);

  &:hover {
    color: rgba(var(--white), 0.8);
  }
`;
