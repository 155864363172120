import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  overflow-y: hidden;
  max-width: 360px;
  border-radius: 4px;
  width: 100%;
  background-color: rgba(var(--lvl-0), 1);

  @media (max-width: 720px) {
    max-width: 100%;
    box-shadow: none;
    border: none;
  }

  .no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
