import { FC, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { ACurrentTab } from 'store/tabs';
import { ALastResults } from 'store/lastResult';

import Icon from 'shared/components/Icon';
import { getResultsColor } from 'shared/helpers/getResultsColor';
import { useDragScroll } from 'shared/hooks/useDragScroll';

import {
  SWrapper,
  STitle,
  SIcon,
  SResults,
  SResultItem,
  SShadow,
} from './style';
import { IResultsHeaderProps } from './types';

const ResultsHeader: FC<IResultsHeaderProps> = ({ forceFullWidth }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const currentTab = useRecoilValue(ACurrentTab);
  const results = useRecoilValue(ALastResults);

  useDragScroll(ref);

  return (
    <SWrapper>
      <STitle>
        {t('lastResult')}
        <SIcon>
          <Icon name="ic_arrow_right" />
        </SIcon>
      </STitle>
      <SShadow />
      <SResults isFullWidth={forceFullWidth || currentTab !== 'game'} ref={ref}>
        {results.map(({ m, id }) => {
          const resultValueColor = getResultsColor(+m);

          return (
            <SResultItem valueColor={resultValueColor} key={`${m}-${id}`}>
              {m.toFixed(2)}
            </SResultItem>
          );
        })}
      </SResults>
    </SWrapper>
  );
};

export default ResultsHeader;
