import { useEffect, useRef, FC } from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { AIsSound } from 'store/sound';
import { ABalance } from 'store/balance';
import { AIsOpenInfoBanner } from 'store/infoBanner';
import { AToastsList, appendToast } from 'store/toasts/toastsList';
import { AProjectConfigs } from 'store/projectConfigs';

import gameManager from 'api/gameManager/GameManager';

import Icon from 'shared/components/Icon';

import {
  SWrapper,
  SContent,
  SContentItem,
  SBalance,
  STitle,
  STotalBalance,
  SLogo,
  SActions,
  SButton,
} from './style';
import { IScreenHeaderProps } from './types';

const ScreenHeader: FC<IScreenHeaderProps> = () => {
  const isInitialMount = useRef(true);
  const { t } = useTranslation();
  const [isSound, setIsSound] = useRecoilState(AIsSound);
  const balance = useRecoilValue(ABalance);
  const setIsOpenInfoBanner = useSetRecoilState(AIsOpenInfoBanner);
  const setToasts = useSetRecoilState(AToastsList);
  const { logo, logo_portrait } = useRecoilValue(AProjectConfigs);

  const soundToggle = () => {
    const newIsSound = !isSound;
    setIsSound(newIsSound);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      gameManager.changeSound(isSound);

      setToasts((toasts) =>
        appendToast(toasts, {
          text: t('soundIsOnOff', {
            status: isSound ? t('on') : t('off'),
          }),
          isHide: true,
          icon: isSound ? 'ic_soundon' : 'ic_soundoff',
          type: 'default',
          duration: 1000,
        }),
      );
    }
  }, [isSound]);

  const ShowInfoHandler = () => setIsOpenInfoBanner(true);

  return (
    <SWrapper>
      {window.matchMedia('(min-width: 721px)').matches
        ? logo && <SLogo src={logo} />
        : (logo_portrait || logo) && <SLogo src={logo_portrait || logo} />}
      <SContent>
        <SContentItem>
          <SBalance>
            <STitle>{t('balance')}</STitle>
            <STotalBalance>{balance}</STotalBalance>
          </SBalance>
          <SActions>
            <SButton onClick={ShowInfoHandler}>
              <Icon name="ic_info" />
            </SButton>
            <SButton onClick={soundToggle}>
              <Icon name={isSound ? 'ic_soundon' : 'ic_soundoff'} />
            </SButton>
          </SActions>
        </SContentItem>
      </SContent>
    </SWrapper>
  );
};

export default ScreenHeader;
