import { FC, memo } from 'react';
import { useRecoilState } from 'recoil';

import { AToastsList } from 'store/toasts/toastsList';

import Toast from 'shared/components/Toast';
import { useInterval } from 'shared/hooks/useInterval';

const ToastProvider: FC = () => {
  const [items, setItems] = useRecoilState(AToastsList);

  useInterval(() => {
    const neededRemoveIds: number[] = items.reduce<number[]>(
      (acc, { id, endDate }) => {
        const current = new Date().getTime();
        return endDate && current >= endDate ? acc.concat(id) : acc;
      },
      [],
    );

    if (neededRemoveIds.length) {
      setItems((prev) =>
        prev.filter(({ id }) => !neededRemoveIds.includes(id)),
      );
    }
  }, 1000);

  return (
    <>
      {items.map(({ id, ...rest }) => (
        <Toast key={id} id={id} {...rest} />
      ))}
    </>
  );
};

export default memo(ToastProvider);
