import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import { getLanguageFromUrl } from 'shared/helpers/parseUrl';

import en from './translations/en.json';
import sen from './translations/sen.json';
import et from './translations/et.json';
import ru from './translations/ru.json';
import fr from './translations/fr.json';
import tr from './translations/tr.json';
import sv from './translations/sv.json';
import ro from './translations/ro.json';
import it from './translations/it.json';
import es from './translations/es.json';
import hr from './translations/hr.json';
import bg from './translations/bg.json';
import cs from './translations/cs.json';
import nl from './translations/nl.json';
import ja from './translations/ja.json';
import id from './translations/id.json';
import ko from './translations/ko.json';
import vi from './translations/vi.json';
import zh from './translations/zh.json';
import th from './translations/th.json';
import de from './translations/de.json';
import uk from './translations/uk.json';
import el from './translations/el.json';
import pt from './translations/pt.json';
import fi from './translations/fi.json';
import no from './translations/no.json';
import by from './translations/by.json';
import ka from './translations/ka.json';
import lv from './translations/lv.json';

export const DEFAULT_NS = 'translation';
export const DEFAULT_LANG = 'en';

const resources = {
  en: {
    [DEFAULT_NS]: en,
  },
  sen: {
    [DEFAULT_NS]: sen,
  },
  ru: {
    [DEFAULT_NS]: ru,
  },
  fr: {
    [DEFAULT_NS]: fr,
  },
  tr: {
    [DEFAULT_NS]: tr,
  },
  sv: {
    [DEFAULT_NS]: sv,
  },
  ro: {
    [DEFAULT_NS]: ro,
  },
  it: {
    [DEFAULT_NS]: it,
  },
  hr: {
    [DEFAULT_NS]: hr,
  },
  es: {
    [DEFAULT_NS]: es,
  },
  cs: {
    [DEFAULT_NS]: cs,
  },
  bg: {
    [DEFAULT_NS]: bg,
  },
  nl: {
    [DEFAULT_NS]: nl,
  },
  ja: {
    [DEFAULT_NS]: ja,
  },
  id: {
    [DEFAULT_NS]: id,
  },
  ko: {
    [DEFAULT_NS]: ko,
  },
  th: {
    [DEFAULT_NS]: th,
  },
  vi: {
    [DEFAULT_NS]: vi,
  },
  zh: {
    [DEFAULT_NS]: zh,
  },
  de: {
    [DEFAULT_NS]: de,
  },
  el: {
    [DEFAULT_NS]: el,
  },
  uk: {
    [DEFAULT_NS]: uk,
  },
  pt: {
    [DEFAULT_NS]: pt,
  },
  fi: {
    [DEFAULT_NS]: fi,
  },
  no: {
    [DEFAULT_NS]: no,
  },
  by: {
    [DEFAULT_NS]: by,
  },
  et: {
    [DEFAULT_NS]: et,
  },
  ka: {
    [DEFAULT_NS]: ka,
  },
  lv: {
    [DEFAULT_NS]: lv,
  },
};

const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
  resources: resources,
  lng: getLanguageFromUrl(),
  fallbackLng: DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;
