export const initialData = {
  chat: {
    aka: 'Me',
    st: [
      {
        id: 1,
        m: false,
        t: 'help me',
        l: 'link for m = true',
      },
      {
        id: 2,
        m: false,
        t: 'Oh nice',
        l: 'link for m = true',
      },
      {
        id: 3,
        m: false,
        t: 'Attention',
        l: 'link for m = true',
      },
      {
        id: 4,
        m: false,
        t: 'Be careful',
        l: 'link for m = true',
      },
      {
        id: 5,
        m: false,
        t: 'Oh nice choose',
        l: 'link for m = true',
      },
      {
        id: 6,
        m: false,
        t: 'My congratulations',
        l: 'link for m = true',
      },
    ],
    lc: 'fr',
    ncmt: 162593348430759,
    mi_ml: 0,
    ma_ml: 100,
  },

  sync_time: () => Date.now(),
  dict: { literal: 'value' },
};

export const defaultInitData = {
  chat: {
    aka: '',
    st: [],
    lc: '',
    cc: '',
    ncmt: 0,
    mi_ml: 0,
    ma_ml: 0,
    a_id: 0,
    al: undefined,
    rp: false,
  },
  dict: { literal: 'value' },
};
