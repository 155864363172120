import { ReactNode } from 'react';
import { atom, selector } from 'recoil';

export interface IModalButton {
  label: string;
  type: 'reload' | 'close' | 'quit' | 'history' | 'continue' | 'lobby';
  action?: () => void;
}

export interface IModalState {
  title?: ReactNode;
  message?: ReactNode;
  buttons?: IModalButton[];
  isNeededAuth?: boolean;
}

export const AModalState = atom<IModalState | null>({
  key: 'modal',
  default: null,
});

export const AModalSelector = selector<IModalState | null>({
  key: 'modalSelector',
  set: ({ get, set }, newValue) => {
    if (newValue === null || get(AModalState) === null) {
      set(AModalState, newValue);
    }
  },
  get: ({ get }) => {
    return get(AModalState);
  },
});
