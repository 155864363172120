import { getUrlParams } from 'shared/helpers/parseUrl';

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const initServices = () => {
  const { config_url, translations_url } = getUrlParams();

  return {
    getConfig: async () => {
      try {
        const res = await fetch(config_url, {
          method: 'GET',
        });
        const raw = await res.text();
        const json = await JSON.parse(raw);

        return json || true;
      } catch (e) {
        console.log('getConfig', e);
      }

      return true;
    },
    getTranslations: async () => {
      try {
        const res = await fetch(translations_url, {
          method: 'GET',
        });
        const raw = await res.text();
        const json = await JSON.parse(raw);

        return json || {};
      } catch (e) {
        console.log('getTranslations', e);
      }

      return {};
    },
  };
};

export default initServices();
