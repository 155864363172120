import styled, { css } from 'styled-components';

export const SButton = styled.button<{
  borderRStyle?: {
    isBorderB: boolean;
    isBorderL: boolean;
    isRadiusT: boolean;
    isRadiusB: boolean;
  };
  autoOn?: boolean;
}>`
  font-family: 'Rubik', sans-serif;
  display: flex;
  flex: 0 0 36px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.15s;
  background-color: ${({ autoOn }) =>
    autoOn ? 'rgba(var(--primary-2), 1)' : 'rgba(var(--lvl-2), 1)'};

  ${({ borderRStyle }) =>
    borderRStyle?.isRadiusT && 'border-radius: 0 4px 0 0'};
  ${({ borderRStyle }) =>
    borderRStyle?.isRadiusB && 'border-radius: 0 0 4px 0'};

  &:not(:disabled):hover {
    background: ${({ autoOn }) =>
      !autoOn ? 'rgba(var(--white), .15)' : 'rgba(var(--primary-2), .5)'};
  }

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  .no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const textStyle = css`
  color: rgba(var(--txt-white), 1);
  font-weight: 500;
  max-width: 34px;
  overflow-y: hidden;
  text-transform: uppercase;
`;

export const STitle = styled.span`
  ${textStyle};
  color: rgba(var(--bet-auto-state2), 1);
  font-size: 9.5px;
`;

export const SSubTitle = styled.span<{ autoOn?: boolean }>`
  ${textStyle};
  color: rgba(var(--bet-select-cap), 1);
  font-size: 10px;
  opacity: 50%;
  margin-bottom: 5px;
`;
