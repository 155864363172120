import styled from 'styled-components';

export const SWrapper = styled.div`
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
  font-size: 13px;
`;

export const SItem = styled.div`
  background: rgba(var(--lvl-1), 0.5);
  color: rgba(var(--white-color), 1);
  padding: 1px 8px 1px 5px;
  border-radius: 0 5px 0 5px;

  &[data-right-radius] {
    border-radius: 5px 0 5px 0;
  }

  [data-icon] svg {
    width: 12px;
    height: 12px;
    transform: translateY(1px);
  }
`;

export const SValue = styled.span`
  display: inline-block;
  margin-left: 6px;

  &[data-min-width] {
    min-width: 56px;
  }
`;
