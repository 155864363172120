import React, { FC } from 'react';

import Icon from 'shared/components/Icon';

import { StyledChatBtn } from './style';
import { TChatButtonProps } from './types';

const ChatButton: FC<TChatButtonProps> = ({ toggleVisibleChat }) => (
  <StyledChatBtn onClick={toggleVisibleChat}>
    <Icon name="ic_floatchat" />
  </StyledChatBtn>
);

export default ChatButton;
