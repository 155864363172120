import styled from 'styled-components';

import imgRotatePhone from 'assests/icons/img_rotatephone.min.svg';

export const SLockScreen = styled.div`
  display: none;
  background: rgba(var(--lvl-0), 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;

  -webkit-touch-callout: none;
  user-select: none;

  @media (orientation: landscape) and (max-width: 720px) and (min-width: 420px) {
    display: flex;
  }
`;

export const SText = styled.span`
  margin-top: 4px;
  font-size: 18px;
  font-weight: 500;
  height: 22px;
  border: none;
`;

export const SImage = styled.span`
  width: 200px;
  height: 200px;
  background: url('${imgRotatePhone}') 50% 50% no-repeat;
  background-size: auto 220px;
`;
