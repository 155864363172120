import styled from 'styled-components';

import { Colors } from 'constants/results';

export const SWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: rgba(var(--lvl-0), 1);
  overflow: hidden;
  border-radius: 4px 0 0 4px;

  @media (max-width: 720px) {
    height: 40px;
    width: auto;
  }
`;

export const STitle = styled.div`
  max-width: 160px;
  min-width: 160px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 15px;
  padding: 0 10px 0 16px;
  color: rgba(var(--tab-txt-active), 1);
  background-color: rgba(var(--lvl-3), 1);
  text-transform: uppercase;
  border-radius: 4px;

  @media (max-width: 991.98px) {
    padding: 0 5px 0 5px;
    max-width: 125px;
    font-size: 12px;
    min-width: 125px;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

export const SIcon = styled.span`
  height: 24px;
  width: 24px;
  margin-left: 3px;
  [data-icon] {
    color: rgba(var(--tab-txt-active), 1);
  }
`;

export const SShadow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(var(--lvl-0), 1) 0,
    rgba(var(--lvl-0), 0) 0
  );

  @media (max-width: 720px) {
    display: none;
  }
`;

export const SResults = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  margin-left: 4px;
  overflow-x: auto;
  height: 105px;

  @media (max-width: 720px) {
    margin-left: 0;
    max-width: calc(100vw - 90px);
    ${({ isFullWidth }) => isFullWidth && 'max-width: calc(100vw - 10px)'};
  }
`;

export const SResultItem = styled.div<{
  valueColor?:
    | Colors.FIRST_COLOR
    | Colors.SECOND_COLOR
    | Colors.THIRD_COLOR
    | Colors.FOURTH_COLOR
    | Colors.DEFAULT_COLOR;
}>`
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 15px;
  padding: 1px 6px;
  border-radius: 2px;
  margin: 0 2px;
  background: ${({ valueColor }) =>
    valueColor === Colors.FIRST_COLOR
      ? 'rgba(var(--choef-1), .1)'
      : valueColor === Colors.SECOND_COLOR
      ? 'rgba(var(--choef-2), .1)'
      : valueColor === Colors.THIRD_COLOR
      ? 'rgba(var(--choef-3), .1)'
      : valueColor === Colors.FOURTH_COLOR
      ? 'rgba(var(--choef-4), .1)'
      : valueColor === Colors.DEFAULT_COLOR
      ? 'rgba(var(--choef-5), .1)'
      : 'rgba(var(--txt-white), 1)'};
  color: ${({ valueColor }) =>
    valueColor === Colors.FIRST_COLOR
      ? 'rgba(var(--choef-1), 1)'
      : valueColor === Colors.SECOND_COLOR
      ? 'rgba(var(--choef-2), 1)'
      : valueColor === Colors.THIRD_COLOR
      ? 'rgba(var(--choef-3), 1)'
      : valueColor === Colors.FOURTH_COLOR
      ? 'rgba(var(--choef-4), 1)'
      : valueColor === Colors.DEFAULT_COLOR
      ? 'rgba(var(--choef-5), 1)'
      : 'rgba(var(--white), 1)'};
`;
