import styled, { css } from 'styled-components';

import { Statuses } from 'constants/TournamentsStatuses';

export const SWrapper = styled.div<{ isActive?: boolean }>`
  height: 100%;
  width: 100%;
  z-index: var(--z-index-400);
  position: absolute;
  left: ${({ isActive }) => (isActive ? '0' : '100%')};
  transition: left 0.2s ease-out;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(var(--lvl-0), 1);

  animation: 0.25s fadeIn forwards;
`;

const soonCss = css`
  background: linear-gradient(
    to bottom,
    // TODO: unknown color
    rgba(var(--primary-3), 0.3) 0%,
    rgba(var(--primary-3), 0.29) 5%,
    rgba(var(--primary-3), 0.27) 9%,
    rgba(var(--primary-3), 0.25) 14%,
    rgba(var(--primary-3), 0.23) 18%,
    rgba(var(--primary-3), 0.2) 22%,
    rgba(var(--primary-3), 0.16) 26%,
    rgba(var(--primary-3), 0.14) 31%,
    rgba(var(--primary-3), 0.11) 37%,
    rgba(var(--primary-3), 0.08) 44%,
    rgba(var(--primary-3), 0.05) 52%,
    rgba(var(--primary-3), 0.03) 61%,
    rgba(var(--primary-3), 0.02) 72%,
    rgba(var(--primary-3), 0) 85%,
    rgba(var(--primary-3), 0)
  );
`;

const liveCss = css`
  background: linear-gradient(
    to bottom,
    // TODO: unknown color
    rgba(var(--primary-2), 0.3) 0%,
    rgba(var(--primary-2), 0) 110%
  );
`;

const endedCss = css`
  background: linear-gradient(
    to bottom,
    // TODO: unknown color
    rgba(var(--grid-cap), 0.4) 0%,
    rgba(var(--grid-cap), 0) 100% // TODO:
  );
`;

export const SPopupScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 233;
  border-radius: 6px;
  background-color: rgba(var(--lvl-0), 1);
  animation: 0.25s fadeIn forwards;

  :before {
    content: '';
    border-radius: 6px;
    position: absolute;
    display: block;
    height: 140px;
    width: 100%;

    background: linear-gradient(
      to bottom,
      rgba(var(--primary-1), 0.3) 0%,
      rgba(var(--primary-1), 0.15) 30%,
      rgba(var(--primary-1), 0) 100%
    );
  }
`;

export const SPopupScreenTitle = styled.span`
  position: absolute;
  width: 100%;
  padding: 0 55px;
  text-align: center;
  top: 18px;
  font-weight: 500;
  font-size: 18px;
`;

export const SPopupScreenContent = styled.div`
  margin: 60px 20px 20px 20px;
`;

export const SInner = styled.div<{ active?: boolean }>`
  position: relative;
  ${({ active }) =>
    !active &&
    css`
      overflow: hidden;
      max-height: 100%;
    `}
`;

export const SHeader = styled.header<{
  status: Statuses.SOON | Statuses.LIVE | Statuses.ENDED | '';
}>`
  height: 140px;
  ${({ status }) =>
    status === Statuses.SOON
      ? soonCss
      : status === Statuses.LIVE
      ? liveCss
      : endedCss};
  border-radius: 6px 6px 0 0;
`;

export const SHeaderContent = styled.div`
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const btnStyles = css`
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none;
  border: none;
  cursor: pointer;
`;

export const SLeftBtn = styled.button`
  position: absolute;
  left: 12px;
  top: 16px;
  width: 32px;
  height: 32px;
  background-color: transparent;

  [data-icon] {
    color: rgba(var(--white), 1);
  }

  ${btnStyles};
`;

export const SLogoWrapper = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: relative;
`;

export const SLogo = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
`;

export const SLogoIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 4px;
  right: 0;
  z-index: var(--z-index-20);
  font-size: 10px;
  font-weight: 500;
  color: rgba(var(--txt-white), 1);
  border-radius: 50%;
`;

export const STimerContent = styled.div`
  position: absolute;
  right: 16px;
  top: 20px;
  display: flex;
  flex-direction: column;
`;

export const STimerTitle = styled.span`
  font-weight: 500;
  font-size: 10px;
  color: rgba(var(--txt-white), 1);
  text-align: right;
  text-transform: uppercase;
`;

export const STimer = styled.span<{
  status: Statuses.SOON | Statuses.LIVE | Statuses.ENDED;
}>`
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  color: ${({ status }) =>
    status === Statuses.SOON
      ? 'rgba(var(--txt-h2-primary-3), 1)'
      : status === Statuses.LIVE
      ? 'rgba(var(--txt-h2-primary-2), 1)'
      : 'rgba(var(--txt-h2), 1)'};
  text-align: right;

  @media (max-width: 415px) {
    font-size: 12px;
  }
`;

export const STitle = styled.span`
  max-width: 100%;
  font-weight: 500;
  font-size: 17px;
  color: rgba(var(--txt-h1), 1);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  overflow: hidden;
  word-break: break-word;
`;

export const SSubTitle = styled.p`
  max-width: 328px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.286;
  color: rgba(var(--txt-normal), 1);
  text-align: center;

  @media (max-width: 720px) {
    max-width: 100%;
    margin: 0 10px;
  }

  @media (max-width: 415px) {
    max-width: 100%;
    margin: 0 5px;
  }
`;

export const SMoreBtn = styled.button<{ isSmall: boolean }>`
  width: ${({ isSmall }) => (isSmall ? '42px' : '76px')};
  height: 18px;
  font-weight: 500;
  font-size: 12px;
  color: rgba(var(--txt-h2-primary-1), 1);
  background-color: rgba(var(--primary-1), 0.1);
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;

  ${btnStyles};
`;

export const SScreen = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 20px;
`;

const screenStyle = css`
  display: flex;
  flex-direction: column;
  flex: 0 0 49.5%;
  width: 49.5%;
  height: 50px;
  padding-left: 10px;
  justify-content: center;
  background-color: rgba(var(--lvl-2), 1);
`;

export const SStartsScreen = styled.div`
  ${screenStyle};

  border-radius: 4px 0 0 4px;
`;

export const SEndScreen = styled.div`
  ${screenStyle};

  border-radius: 0 4px 4px 0;
`;

export const SScreenTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: rgba(var(--grid-cap), 1);
  text-transform: uppercase;
`;

export const SScreenTime = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.286;
  color: rgba(var(--grid-txt-normal), 1);
`;

export const SControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SActionBtn = styled.button<{
  status: Statuses.SOON | Statuses.LIVE | Statuses.ENDED;
}>`
  ${btnStyles};

  width: 200px;
  height: 44px;
  border-radius: 21px;
  background-color: ${({ status }) =>
    status === Statuses.SOON
      ? 'rgba(var(--primary-3), 1)'
      : 'rgba(var(--primary-2), 1)'};
  color: ${({ status }) =>
    status === Statuses.SOON
      ? 'rgba(var(--txt-h2-dark), 1)'
      : 'rgba(var(--txt-h1), 1)'};
  font-weight: 500;
  font-size: 17px;
  margin: 20px auto;
  ${({ status }) =>
    status === Statuses.SOON
      ? css`
          box-shadow: 0 2px 5px rgba(var(--shdw-primary-2));
        `
      : css`
          box-shadow: 0 2px 5px rgba(var(--shdw-primary-3));
        `};

  @media (max-width: 415px) {
    margin: 10px auto;
    width: 150px;
    height: 36px;
    font-size: 15px;
  }
`;

export const STermsBtn = styled.button`
  ${btnStyles};

  width: 180px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(var(--txt-h2-primary-1), 0.5);
  border: 1px solid rgba(var(--primary-1), 0.2);
  border-radius: 20px;
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  margin-top: 30px;
  transition: all 0.1s;

  &:not(:disabled):hover {
    background: rgba(var(--primary-1), 0.1);
    color: rgba(var(--txt-h2-primary-1), 1);
  }
`;
