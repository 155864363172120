import {
  FC,
  useEffect,
  useState,
  MutableRefObject,
  useCallback,
  useRef,
} from 'react';
import { useRecoilValue } from 'recoil';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';

import { ACupsList } from 'store/tournaments';
import { ATabDimension } from 'store/tabs';

import notificationManager from 'api/notificationManager/NotificationManager';

import ListLayouts from 'shared/components/ListLayouts';

import Row from './Row';
import ExtraContent from './ExtraContent';
import { SWrapper, SList, SScrollWrapper, SNoTournaments } from './style';
import { ITournamentsProps } from './types';

const Tournaments: FC<ITournamentsProps> = () => {
  const listContainerRef = useRef<{
    obs: ResizeObserver | null;
    ref: MutableRefObject<HTMLElement> | null;
  }>({ obs: null, ref: null });
  const [listHeight, setListHeight] = useState<number>(0);
  const { t } = useTranslation();
  const cupsList = useRecoilValue(ACupsList);
  const [isTournamentOpen, setIsTournamentOpen] = useState(false);

  useEffect(() => {
    notificationManager.updateTournaments();
  }, []);

  const onRefChange = useCallback((node) => {
    if (node === null) {
      listContainerRef.current?.obs?.disconnect();
      return;
    }

    if (node === listContainerRef.current?.ref) {
      return;
    }

    listContainerRef.current.ref = node;
    const onResize = () => {
      setListHeight(node.getBoundingClientRect()?.height || 0);
    };
    listContainerRef.current.obs = new ResizeObserver(onResize);
    listContainerRef.current.obs.observe(node);
    onResize();
  }, []);

  return (
    <SWrapper>
      {isTournamentOpen && (
        <ExtraContent state={{ isTournamentOpen, setIsTournamentOpen }} />
      )}
      {cupsList.length ? (
        <SScrollWrapper inactive={isTournamentOpen}>
          <SList ref={onRefChange}>
            <ListLayouts isFullHeight={true}>
              <List
                className="no-scrollbars"
                height={listHeight}
                itemCount={cupsList.length}
                itemSize={104}
                itemData={cupsList}
                width="100%"
              >
                {(p) => (
                  <Row setIsTournamentOpen={setIsTournamentOpen} {...p} />
                )}
              </List>
            </ListLayouts>
          </SList>
        </SScrollWrapper>
      ) : (
        <SNoTournaments>{t('noTournaments')}</SNoTournaments>
      )}
    </SWrapper>
  );
};

export default Tournaments;
