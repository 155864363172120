import { useMemo, useEffect, useState, memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import i18n from 'i18n/config';

import { IPrizesListItem, PrizeType } from 'store/tournaments/types';
import { AActiveControl, EControl, ATournament } from 'store/tournaments';
import { AInitialData } from 'store/init';

import notificationManager from 'api/notificationManager/NotificationManager';

import { useInterval } from 'shared/hooks/useInterval';
import Pagination from 'shared/components/Pagination';
import { getTranslation } from 'shared/helpers/getTranslations';

import { DEFAULT_CURRENCY } from 'constants/currency';

import {
  SWrapper,
  SActions,
  SLeaderBoardBtn,
  SPrizeBtn,
  SPrizesWrapper,
  SFirstSection,
  SFirstCardTitle,
  SCard,
  SFirstCardText,
  SLeftCard,
  SRightCard,
  SCardIcon,
  SLeaderBoardWrapper,
  SFirstBlock,
  SPlace,
  SUserName,
  SSecondBlock,
  SWin,
  SPoints,
  SBottomTitle,
  SNoTournament,
  SCardNumber,
  SCardInfo,
  SCardName,
  SCardDescription,
  SYou,
  SDot,
} from './style';
import { TRatingList } from './types';

const TournamentList: FC<{ id: number }> = ({ id }) => {
  const { t } = useTranslation();
  const {
    chat: { cc: currencyCode, aka: currentUser },
  } = useRecoilValue(AInitialData);
  const [activeControl, setActiveControl] = useRecoilState(AActiveControl);
  const [ratingList, setRatingList] = useState<TRatingList[]>([]);
  const [ratingItem, setRatingItem] = useState<null | TRatingList>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [{ page, pageCount }, setPagination] = useState<{
    page: number;
    pageCount: number;
  }>({
    page: 0,
    pageCount: 0,
  });
  const { r_p: prizes, dcc, dlc } = useRecoilValue(ATournament);

  const mappedPrizes = useMemo(
    () =>
      prizes.prize.reduce<{ [key: number]: IPrizesListItem }>((acc, item) => {
        acc[item.p] = item;
        return acc;
      }, {}),
    [prizes.prize],
  );

  const fetchLeaderBoards = (page: number = 0) => {
    setIsLoading(true);
    //setRatingList([]);
    //setRatingItem(null);

    notificationManager.getTournamentLeaderboards(
      id,
      page,
      (res, arr, it, page, pageCount) => {
        setIsLoading(false);
        setRatingList(arr);
        setRatingItem(it);
        setPagination({
          page,
          pageCount,
        });
      },
    );
  };

  const handleActive = (name: EControl) => {
    setActiveControl(name);
  };

  const getPrizeInfo = (
    type: PrizeType,
    title: { [key: string]: string },
    text: { [key: string]: string },
    curr: { [key: string]: string },
  ) => {
    switch (type) {
      case PrizeType.CASH:
      case PrizeType.BONUS:
        return {
          title: getTranslation(curr, currencyCode, dcc, DEFAULT_CURRENCY),
        };
      default:
        return {
          title: getTranslation(title, i18n.language, dlc),
          desc: getTranslation(text, i18n.language, dlc),
        };
    }
  };

  const getTitle = (pl: number) => {
    const item = mappedPrizes[pl];

    if (item) {
      return getPrizeInfo(
        item.type as PrizeType,
        item.title,
        item.text,
        item.curr,
      )?.title;
    }

    return '';
  };

  useInterval(
    () => {
      fetchLeaderBoards();
    },
    activeControl === EControl.LEADERBOARD ? 5000 : null,
  );

  useEffect(() => {
    if (activeControl === EControl.LEADERBOARD) {
      fetchLeaderBoards();
    }
  }, [activeControl]);

  return (
    <SWrapper>
      <SActions>
        <SPrizeBtn
          onClick={() => handleActive(EControl.PRIZES)}
          isActive={activeControl === 'prizes'}
        >
          {t('prizes')}
        </SPrizeBtn>
        <SLeaderBoardBtn
          onClick={() => handleActive(EControl.LEADERBOARD)}
          isActive={activeControl === 'leaderboard'}
        >
          {t('leaderboard')}
        </SLeaderBoardBtn>
      </SActions>
      {activeControl === 'prizes' && (
        <SPrizesWrapper>
          <SFirstSection>
            <SFirstCardTitle>
              {getTranslation(
                prizes?.total?.curr || {},
                currencyCode,
                dcc,
                DEFAULT_CURRENCY,
              )}
            </SFirstCardTitle>
            <SFirstCardText>{t('totalPrizes')}</SFirstCardText>
          </SFirstSection>
          {prizes?.prize?.map(
            ({
              p,
              title: titles,
              text,
              img: imgPath,
              isActive,
              type,
              curr,
            }) => {
              const { title, desc } = getPrizeInfo(
                type as PrizeType,
                titles,
                text,
                curr,
              );

              return (
                <SCard key={p} isActive={isActive}>
                  <SLeftCard>
                    <SCardNumber isActive={isActive}>#{p}</SCardNumber>
                    <SCardInfo>
                      {title && <SCardName>{title}</SCardName>}
                      {desc && <SCardDescription>{desc}</SCardDescription>}
                    </SCardInfo>
                  </SLeftCard>
                  {imgPath && (
                    <SRightCard>
                      <SCardIcon src={imgPath} />
                    </SRightCard>
                  )}
                </SCard>
              );
            },
          )}
        </SPrizesWrapper>
      )}
      {activeControl === 'leaderboard' && (
        <>
          {ratingList.length ? (
            <>
              <ul>
                {ratingItem && (
                  <SLeaderBoardWrapper key="it" isCurrentUser={true}>
                    <SFirstBlock>
                      <SPlace>
                        <span>#{ratingItem.pl}</span>
                        {ratingItem.pl === 1 && <span> {t('place')}</span>}
                      </SPlace>
                      <SUserName>
                        <SYou>{t('you')} </SYou> <SDot>&#183; </SDot>
                        {ratingItem.ua}
                      </SUserName>
                    </SFirstBlock>
                    <SSecondBlock>
                      <span>
                        {getTitle(ratingItem.pl) && (
                          <>
                            <SWin>{getTitle(ratingItem.pl)}</SWin>
                            <SBottomTitle isCurrentUser={true}>
                              {t('win')}
                            </SBottomTitle>
                          </>
                        )}
                      </span>
                      <span>
                        <SPoints>{ratingItem.p / 100}</SPoints>
                        <SBottomTitle isCurrentUser={true}>
                          {t('points')}
                        </SBottomTitle>
                      </span>
                    </SSecondBlock>
                  </SLeaderBoardWrapper>
                )}

                {ratingList.map(
                  ({
                    id,
                    ua: userName,
                    pl: ratingPlace, // p - prizes
                    p: pointsCount,
                  }) => {
                    const title = getTitle(ratingPlace);
                    const isCurrentUser = currentUser === userName;

                    return (
                      <SLeaderBoardWrapper
                        key={id}
                        isCurrentUser={isCurrentUser}
                      >
                        <SFirstBlock>
                          <SPlace>
                            <span>#{ratingPlace}</span>
                            {ratingPlace === 1 && <span> {t('place')}</span>}
                          </SPlace>
                          <SUserName>
                            {!isCurrentUser ? (
                              userName
                            ) : (
                              <>
                                <SYou>{t('you')} </SYou> <SDot>&#183; </SDot>
                                {userName}
                              </>
                            )}
                          </SUserName>
                        </SFirstBlock>
                        <SSecondBlock>
                          <span>
                            {title && (
                              <>
                                <SWin>{title}</SWin>
                                <SBottomTitle isCurrentUser={isCurrentUser}>
                                  {t('win')}
                                </SBottomTitle>
                              </>
                            )}
                          </span>
                          <span>
                            <SPoints>{pointsCount / 100}</SPoints>
                            <SBottomTitle isCurrentUser={isCurrentUser}>
                              {t('points')}
                            </SBottomTitle>
                          </span>
                        </SSecondBlock>
                      </SLeaderBoardWrapper>
                    );
                  },
                )}
              </ul>

              {/*pageCount && (
                <Pagination
                  page={page}
                  total={pageCount}
                  onClick={fetchLeaderBoards}
                />
              )*/}
            </>
          ) : (
            <SNoTournament>{t('noData')}</SNoTournament>
          )}
        </>
      )}
      {isLoading && !ratingList.length && (
        <SNoTournament>{t('loading')}</SNoTournament>
      )}
    </SWrapper>
  );
};

export default memo(TournamentList, (p, n) => {
  return p.id === n.id;
});
