import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const SContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1px;
`;

export const SControllerWrapper = styled.div`
  display: flex;
  margin-bottom: 1px;
`;

export const SController = styled.div`
  width: 100%;
`;

export const SAutoBtnWrapper = styled.div<{ position?: 'top' | 'bottom' }>`
  flex: 0 0 36px;
  display: flex;
  background-color: rgba(var(--lvl-2), 1);
  border-left: 1px solid rgba(var(--lvl-0), 1);
  border-radius: 0 0 5px 0;

  ${({ position }) =>
    position === 'top' &&
    css`
      border-radius: 0 5px 0 0;
    `}
`;

export const SAutoOptionsWrapper = styled.div<{ isBottom?: boolean }>`
  max-width: 100%;
  position: absolute;
  z-index: var(--z-index-300);
  right: 0;
  top: 0;

  ${({ isBottom }) =>
    isBottom &&
    css`
      top: auto;
      bottom: 0;
    `}
`;
