import {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
  FC,
  TouchEvent,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  calculatePosition,
  calculateSliderValue,
  calculateValue,
  calculateValuesCount,
} from 'shared/helpers/gameControllerHelper';

import {
  SWrapper,
  SInner,
  SCenter,
  SCenterCover,
  SCenterCoverInner,
  SCenterOver,
  SLeft,
  SRight,
  SButton,
  SLabel,
  SLabelText,
  SLabelUnit,
  SValue,
  SProgress,
  SInput,
} from './style';
import { IControllerProps } from './types';

const Controller: FC<IControllerProps> = ({
  label,
  name,
  value,
  min: minValue = 0,
  max: maxValue = 1,
  options = [],
  step = 1,
  unit = '',
  disabled = false,
  format = (value: number) => value,
  onChange,
  styleControl,
}) => {
  const withOptions: boolean = useMemo(() => options.length > 0, [options]);

  const min = useMemo(
    () => (withOptions ? options[0] : minValue),
    [options, minValue],
  );
  const max = useMemo(
    () => (withOptions ? options[options.length - 1] : maxValue),
    [options, maxValue],
  );
  const length = useMemo(
    () => (withOptions ? options.length : calculateValuesCount(min, max, step)),
    [min, max, step, options],
  );
  const optionsMap: { [key: string]: number } = useMemo(
    () =>
      options.reduce((acc, option, index) => ({ ...acc, [option]: index }), {}),
    [options],
  );

  const { t } = useTranslation();
  const isInitialMount = useRef(true);
  const currentIndexComputed = useMemo(
    () => (value > 0 ? calculatePosition(value, min, max, step) : -1),
    [value, min, max, step],
  );

  const timerId = useRef<number | null>(null);
  const [currentIndex, setCurrentIndex] =
    useState<number>(currentIndexComputed);
  const [currentValue, setCurrentValue] = useState<{
    value: number;
    isMute: boolean;
  }>({
    value: 0,
    isMute: true,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>(0);

  const handleChangeSlider = () => {
    const index = parseInt(
      (withOptions ? inputRef.current?.value : inputRef.current?.value) || '0',
      10,
    );

    const val = withOptions
      ? options[index]
      : calculateValue(index, length, min, max, step);

    setCurrentValue({
      value: val,
      isMute: true,
    });
  };

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      if (!inputRef.current || inputRef.current?.disabled) return;

      const { pageX } = event.touches[0];
      const { left, width } = inputRef.current.getBoundingClientRect();

      const val = withOptions
        ? options[
            calculateSliderValue(
              Math.max(Math.min(pageX - left, width), 0) / width,
              length,
              0,
              length - 1,
              1,
            )
          ]
        : calculateSliderValue(
            Math.max(Math.min(pageX - left, width), 0) / width,
            length,
            min,
            max,
            step,
          );

      setCurrentValue({
        value: val,
        isMute: true,
      });
    },
    [min, max, step, options],
  );

  const getValidIndex = useCallback(
    (index: number, mode: number) =>
      index + mode < 0
        ? 0
        : index + mode > length - 1
        ? length - 1
        : index + mode,
    [length],
  );

  const changeBy = (mode: -1 | 1) => {
    const val = withOptions
      ? options[getValidIndex(currentIndex, mode)]
      : calculateValue(currentIndex + mode, length, min, max, step);

    setCurrentValue({
      value: val,
      isMute: false,
    });
  };

  const handleClick = (mode: 'min' | 'max' | -1 | 1) => () => {
    switch (mode) {
      case 'min':
        setCurrentValue({ value: min, isMute: false });
        break;
      case 'max':
        setCurrentValue({ value: max, isMute: false });
        break;
      case -1:
        changeBy(-1);
        break;
      case 1:
        changeBy(1);
        break;
      default:
    }
  };

  const getDynamicIndex = (duration: number) => {
    if (duration > 4000) {
      return 5;
    } else if (duration > 2000) {
      return 3;
    } else if (duration > 1000) {
      return 2;
    }

    return 1;
  };

  const getDynamicStep = (duration: number) => {
    if (duration > 10000) {
      return step * 20;
    } else if (duration > 5000) {
      return step * 10;
    } else if (duration > 2500) {
      return step * 2;
    }

    return step;
  };

  const handleMouseDown = (mode: -1 | 1) => () => {
    const startedAt = new Date().getTime();

    const timer = () => {
      const delta = new Date().getTime() - startedAt;

      if (timerId.current) window.clearTimeout(timerId.current);
      timerId.current = window.setTimeout(timer, delta >= 300 ? 100 : 300);

      if (delta > 0) {
        setCurrentValue(({ value: val }) => {
          const newVal = withOptions
            ? options[
                getValidIndex(optionsMap[val], mode * getDynamicIndex(delta))
              ]
            : val + mode * getDynamicStep(delta);

          return {
            value: newVal >= max ? max : newVal <= min ? min : newVal,
            isMute: true,
          };
        });
      }
    };

    timer();
  };

  const handleMouseUp = () => {
    if (timerId.current) window.clearTimeout(timerId.current);
  };

  useEffect(() => {
    setProgress((currentIndex / Math.max(length - 1, 1)) * 100);
  }, [currentIndex, length]);

  useEffect(() => {
    if (currentValue.value) {
      onChange(name, currentValue.value, currentValue.isMute);
      setCurrentIndex(
        withOptions
          ? optionsMap[currentValue.value]
          : calculatePosition(currentValue.value, min, max, step),
      );
    }
  }, [currentValue, min, max, step, withOptions, length]);

  useEffect(() => {
    if (isInitialMount.current && currentIndexComputed > -1) {
      isInitialMount.current = false;
      setCurrentValue({
        value: value,
        isMute: true,
      });
    }
  }, [currentIndexComputed, value, length, min, max, step]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp, false);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp, false);
    };
  }, []);

  return (
    <SWrapper disabled={disabled}>
      <SInner>
        <SLeft isBorder={styleControl?.isBorder}>
          <SButton
            disabled={disabled}
            onClick={handleClick(-1)}
            onMouseDown={handleMouseDown(-1)}
            onTouchStart={handleMouseDown(-1)}
            onTouchEnd={handleMouseUp}
            onTouchCancel={handleMouseUp}
            textSize="lg"
          >
            -
          </SButton>
          <SButton
            disabled={disabled}
            onClick={handleClick('min')}
            mode="primary"
          >
            {name === 'bet' ? (
              t('min')
            ) : (
              <>
                {unit}
                {min}
              </>
            )}
          </SButton>
        </SLeft>
        <SCenter isBorder={styleControl?.isBorder} disabled={disabled}>
          <SCenterOver>
            <SLabel>
              <SLabelText>{label}</SLabelText>
              {name === 'bet' && unit && <SLabelUnit>({unit}) </SLabelUnit>}
            </SLabel>
            <SValue>
              <span>{name !== 'bet' && unit} </span>
              <span>{format(currentValue.value)}</span>
            </SValue>
          </SCenterOver>
          <SCenterCover>
            <SCenterCoverInner>
              <SInput
                disabled={disabled}
                ref={inputRef}
                type="range"
                min={0}
                max={withOptions ? length - 1 : length}
                step={1}
                value={currentIndex}
                onTouchStart={handleTouchMove}
                onTouchMove={handleTouchMove}
                onChange={handleChangeSlider}
              />
              <SProgress
                style={{
                  width: `${progress}%`,
                }}
              />
            </SCenterCoverInner>
          </SCenterCover>
        </SCenter>
        <SRight isBorder={styleControl?.isBorder}>
          <SButton
            disabled={disabled}
            onClick={handleClick(1)}
            onMouseDown={handleMouseDown(1)}
            onTouchStart={handleMouseDown(1)}
            onTouchEnd={handleMouseUp}
            onTouchCancel={handleMouseUp}
            textSize="lg"
          >
            +
          </SButton>
          <SButton
            disabled={disabled}
            onClick={handleClick('max')}
            mode="primary"
          >
            {t('max')}
          </SButton>
        </SRight>
      </SInner>
    </SWrapper>
  );
};

export default memo(
  Controller,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    nextProps.value === prevProps.value,
);
