import { atom } from 'recoil';

import { IUiConfig } from './types';

export const UI_CONFIG = 'ui_config';

export const AUiConfig = atom<IUiConfig>({
  key: UI_CONFIG,
  default: {
    disableAutobet: false,
    disableAutocollect: false,
  },
});
