import styled from 'styled-components';

import { TBannerProps } from './types';

export const ContentBackgroundShadow = styled.div`
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  z-index: 2;
  position: absolute;
  border-radius: 10px 10px 0 0;
  background-image: linear-gradient(to bottom, black, transparent);
`;

export const Content = styled.div`
  max-height: 280px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  left: 0;
  right: 0;
  z-index: 3;
`;

export const Title = styled.div`
  max-height: 72px;
  max-width: 100%;
  font-weight: 500;
  font-size: 15px;
  color: rgba(var(--txt-h2), 1);
  line-height: normal;
  display: flex;
  word-break: break-word;
  overflow: hidden;
`;

export const ActionLinkBtn = styled.a`
  //max-width: 80%;
  max-width: max-content;
  max-height: 34px;
  align-items: center;
  display: flex;
  border-radius: 22px;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 3px 3px 20px;
  cursor: pointer;
  margin: 0;
  border: 0;
  text-decoration: none;
  color: rgba(var(--btn-txt), 1);
  background-color: rgba(var(--primary-3), 1);
  box-shadow: 0 2px 8px rgba(var(--shdw-primary-2));

  > span {
    min-height: 18px;
    line-height: 1.286;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    max-width: 148px;
  }
  :hover {
    background-color: rgba(var(--primary-3), 0.7);
  }
`;

export const ActionBtn = styled.button`
  max-width: max-content;
  max-height: 34px;
  align-items: center;
  display: flex;
  border-radius: 22px;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 3px 3px 20px;
  cursor: pointer;
  margin: 0;
  border: 0;
  text-decoration: none;
  color: rgba(var(--btn-txt), 1);
  background-color: rgba(var(--primary-3), 1);
  box-shadow: 0 2px 8px rgba(var(--shdw-primary-2));

  > span {
    min-height: 18px;
    line-height: 1.286;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    max-width: 148px;
  }
  :hover {
    background-color: rgba(var(--primary-3), 0.7);
  }
`;

export const ActionIconBtn = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  border-radius: 20px;
  margin-left: 10px;
  background-color: rgba(var(--lvl-0), 0.2);

  svg {
    margin-top: 2px;
    height: 24px;
    color: rgba(var(--white), 1);
  }
`;

export const Wrapper = styled.div<TBannerProps>`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 140px;
  max-height: 280px;
  height: 100%;
  border-radius: 10px;
  background-position: center 10%;
  background-image: ${({ bannerUrl }) =>
    bannerUrl ? `url(${bannerUrl})` : "url('')"};
  background-size: cover;
`;
