import styled, { css } from 'styled-components';

const stylesBox = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SWrapper = styled.div`
  background: #323031;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
`;

export const SCover = styled.div`
  ${stylesBox};
  background: #323031;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

export const SLogo = styled.div`
  position: fixed;
  flex: 0 0 100%;
  width: 100%;
  max-width: 400px;
  height: 280px;
`;

export const SImage = styled.div`
  max-width: 400px;
  width: 100%;
  height: 282px;
`;

export const SProgressBar = styled.div`
  position: relative;
  width: 40%;
  height: 5px;
  margin: -30px auto 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  overflow: hidden;
`;

export const SProgressTrack = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: #aa19ab;
  background-image: linear-gradient(90deg, #aa19ab, #e00e52);
  border-radius: 4px;
  height: 100%;
  transition: width 0.2s;
`;

export const SCanvasWrapper = styled.div`
  ${stylesBox};
  background: rgba(var(--lvl-0), 1);
  z-index: 10;
`;
