import { atom } from 'recoil';

import { RESIZE } from '../constants';

export const AResize = atom<{
  trigger: boolean;
  scale: number;
  isPortrait: boolean;
}>({
  key: RESIZE,
  default: { trigger: false, scale: 1, isPortrait: false },
});
