import { FC } from 'react';

import { TStickers } from 'components/Chat/helpers/getChatUserData';

import { UserMessage } from '../style';

import { StickerImage } from './style';

const MessageSticker: FC<{ sticker?: TStickers }> = ({ sticker }) => {
  console.info('HELLO', sticker);

  return (
    <>
      {sticker?.m ? (
        <StickerImage src={sticker?.l} />
      ) : (
        <UserMessage>{sticker?.t}</UserMessage>
      )}
    </>
  );
};

export default MessageSticker;
