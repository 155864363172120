import { useState, FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MESSAGE_CONTENT_TYPE_BANNER,
  MESSAGE_CONTENT_TYPE_STICKER,
  MESSAGE_CONTENT_TYPE_TEXT,
  MESSAGE_SENDER_TYPE_SERVICE,
  MESSAGE_SENDER_TYPE_USER,
  MESSAGE_TARGET_TYPE_ALL,
  MESSAGE_TARGET_TYPE_PRIVATE,
  SERVICE_MESSAGE_BANNER_TYPE_1,
  SERVICE_MESSAGE_BANNER_TYPE_2,
  SERVICE_MESSAGE_BANNER_TYPE_3,
  SERVICE_MESSAGE_CONTENT_TYPE_BANNER,
} from 'components/Chat/constants/receiveMessages';
import Popover from 'components/Chat/components/shared/Popover';
import { TStickers } from 'components/Chat/helpers/getChatUserData';

import { getTranslation as translations } from 'shared/helpers/getTranslations';
import Icon from 'shared/components/Icon';

import FirstBannerType from './FirstBannerType';
import SecondBannerType from './SecondBannerType';
import HotDrop from './HotDrop';
import MessageSticker from './MessageSticker';
import {
  Wrapper,
  UsersNameWrapper,
  Content,
  ArrowIcon,
  UserIcon,
  UserCard,
  UserName,
  UserMessage,
  ActionBtn,
} from './style';
import { TMessageListItemProps } from './types';

const MessageListItem: FC<TMessageListItemProps> = ({
  message,
  userName,
  icon,
  handleReportMessage,
  id,
  accountName,
  handleItemHover,
  activeRowId,
  typeMessage,
  recipient,
  contentType,
  senderType,
  groupId,
  stickerId,
  defaultSticker,
  serviceMessage,
  language,
  stickersMap,
  handleGetRecipient,
  onShowProfile,
  onShowInfoPopup,
}) => {
  const { t } = useTranslation();

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const ifYourMessage = accountName === userName;

  const onMouseOver = () => handleItemHover(id);
  const onMouseLeave = () => {
    handleItemHover(null);
    setIsOpenPopover(false);
  };

  const getTranslation = (dict: { [key: string]: string }): string => {
    if (serviceMessage === null) {
      return '';
    }
    return translations(dict, language, serviceMessage.dlc);
  };

  const getSticker = (): TStickers => {
    return stickersMap[stickerId] || defaultSticker;
  };

  // contentType === 1 -Text, contentType === 2 - Sticker, contentType === , Banner
  const BannerContentType = contentType === MESSAGE_CONTENT_TYPE_BANNER;

  const isShowReportIcon = id === activeRowId && !ifYourMessage;

  const openReportPopover = () => setIsOpenPopover(true);
  const onReportMessage = () => {
    setIsOpenPopover(false);
    handleReportMessage(id, {
      ct: contentType,
      tt: typeMessage,
      st: senderType,
      t: message,
      s_id: stickerId,
      g_id: groupId,
      a_l: icon,
      from: userName,
      to: recipient,
      sm_id: serviceMessage !== null ? serviceMessage.id : undefined,
    });
  };

  const getRecipientHandler = (e: SyntheticEvent) => {
    if (!ifYourMessage && senderType === MESSAGE_SENDER_TYPE_USER) {
      handleGetRecipient(e, userName);
    }
  };

  const getRecipientToHandler = (e: SyntheticEvent) => {
    if (recipient !== accountName) {
      handleGetRecipient(e, recipient);
    }
  };

  const showProfile = () => {
    onShowProfile({
      userName,
      icon,
    });
  };

  return (
    <Wrapper
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ifYourMessage={id === activeRowId}
    >
      {typeMessage === MESSAGE_TARGET_TYPE_ALL && !BannerContentType && (
        <Content>
          <UserIcon onClick={showProfile}>
            {icon ? (
              <img src={icon} alt={userName} />
            ) : (
              <Icon name="ic_chatavatar" />
            )}
          </UserIcon>
          <UserCard>
            <UserName
              onClick={getRecipientHandler}
              ifYourMessage={ifYourMessage}
            >
              {userName}
              {ifYourMessage && ` (${t('you')})`}
            </UserName>

            {contentType === MESSAGE_CONTENT_TYPE_TEXT && (
              <UserMessage>{message}</UserMessage>
            )}
            {contentType === MESSAGE_CONTENT_TYPE_STICKER && (
              <MessageSticker sticker={getSticker()} />
            )}
          </UserCard>
        </Content>
      )}
      {typeMessage === MESSAGE_TARGET_TYPE_PRIVATE && !BannerContentType && (
        <Content>
          <UserIcon onClick={showProfile}>
            {icon ? (
              <img src={icon} alt={userName} />
            ) : (
              <Icon name="ic_chatavatar" />
            )}
          </UserIcon>
          <UserCard>
            <UsersNameWrapper>
              <UserName
                onClick={getRecipientHandler}
                ifYourMessage={ifYourMessage}
              >
                {userName}
              </UserName>
              <ArrowIcon>
                <Icon name="ic_arrowright" />
              </ArrowIcon>
              <UserName
                withoutPadding
                onClick={getRecipientToHandler}
                ifYourMessage={recipient === accountName}
              >
                {recipient}
              </UserName>
            </UsersNameWrapper>

            {contentType === MESSAGE_CONTENT_TYPE_TEXT && (
              <UserMessage>{message}</UserMessage>
            )}
            {contentType === MESSAGE_CONTENT_TYPE_STICKER && (
              <MessageSticker sticker={getSticker()} />
            )}
          </UserCard>
        </Content>
      )}

      {BannerContentType &&
        serviceMessage !== null &&
        (serviceMessage!.ct === SERVICE_MESSAGE_CONTENT_TYPE_BANNER ? (
          <>
            {serviceMessage!.bt === SERVICE_MESSAGE_BANNER_TYPE_1 && (
              <FirstBannerType
                serviceMessage={serviceMessage}
                getTranslation={getTranslation}
              />
            )}
            {serviceMessage!.bt === SERVICE_MESSAGE_BANNER_TYPE_2 && (
              <SecondBannerType
                serviceMessage={serviceMessage}
                getTranslation={getTranslation}
              />
            )}
            {serviceMessage!.bt === SERVICE_MESSAGE_BANNER_TYPE_3 && (
              <HotDrop
                serviceMessage={serviceMessage}
                getTranslation={getTranslation}
                onShowInfoPopup={onShowInfoPopup}
              />
            )}
          </>
        ) : (
          <Content>
            {serviceMessage!.i_l && (
              <UserIcon>
                <img src={getTranslation(serviceMessage!.i_l)} alt="" />
              </UserIcon>
            )}
            <UserCard>
              <UserName>{getTranslation(serviceMessage!.ti_l)}</UserName>
              <UserMessage>{getTranslation(serviceMessage!.t_l)}</UserMessage>
            </UserCard>
          </Content>
        ))}
      {isShowReportIcon &&
        (!isOpenPopover ? (
          <ActionBtn onClick={openReportPopover}>
            <Icon name="ic_more" />
          </ActionBtn>
        ) : (
          <Popover
            text={t(
              senderType === MESSAGE_SENDER_TYPE_SERVICE
                ? 'notInterested'
                : 'reportMessage',
            )}
            onClick={onReportMessage}
          />
        ))}
    </Wrapper>
  );
};

export default MessageListItem;
