import { FC, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import i18n from 'i18n/config';

import { ATournament, DEFAULT_TOURNAMENT } from 'store/tournaments';
import { ATime } from 'store/time';

import notificationManager from 'api/notificationManager/NotificationManager';

import Icon from 'shared/components/Icon';
import {
  convertEndedTime,
  convertTimeToString,
} from 'shared/helpers/convertTime';
import { getTournamentsStatus } from 'shared/helpers/getTournamentsStatus';
import { getTranslation } from 'shared/helpers/getTranslations';
import Span from 'shared/components/Span';

import { Statuses } from 'constants/TournamentsStatuses';

import ExtraContentInfo from './ExtraContentInfo';
import TournamentList from './TournamentList';
import {
  SWrapper,
  SPopupScreen,
  SPopupScreenTitle,
  SPopupScreenContent,
  SInner,
  SHeader,
  SHeaderContent,
  SLeftBtn,
  SLogo,
  STimerContent,
  STimerTitle,
  SLogoWrapper,
  STimer,
  SStartsScreen,
  SEndScreen,
  SScreenTitle,
  SScreenTime,
  SScreen,
  SActionBtn,
  SControls,
  SLogoIcon,
  STermsBtn,
} from './style';
import { IExtraContentProps } from './types';

const ExtraContent: FC<IExtraContentProps> = ({ state }) => {
  const { t } = useTranslation();
  const [showTerms, setShowTerms] = useState(false);
  const { isTournamentOpen, setIsTournamentOpen } = state;
  const [
    {
      id,
      aa: autoApply,
      d: description,
      st: startAt,
      et: endAt,
      im: imageUrl,
      n: name,
      // r_p: prizes,
      t: terms,
      // dcc,
      dlc,
    },
    setTournament,
  ] = useRecoilState(ATournament);
  const time = useRecoilValue(ATime);
  const [isShowBtn, setIsShowBtn] = useState<boolean>(false);
  const [tournamentTime, setTournamentTime] = useState<string>('');
  const [currentStatus, setCurrentStatus] = useState<
    Statuses.SOON | Statuses.LIVE | Statuses.ENDED
  >(Statuses.SOON);

  useEffect(() => {
    const newStatus = getTournamentsStatus(time, startAt, endAt);

    if (newStatus !== currentStatus) {
      setCurrentStatus(newStatus);
    }
  }, [time, currentStatus]);

  useEffect(() => {
    if (startAt === 0) {
      return;
    }

    const newStatus = getTournamentsStatus(time, startAt, endAt);

    switch (newStatus) {
      case Statuses.SOON:
        setTournamentTime(convertTimeToString(startAt - time));
        break;
      case Statuses.LIVE:
        setTournamentTime(convertTimeToString(endAt - time));
        break;
      case Statuses.ENDED:
        setTournamentTime(convertEndedTime(time - endAt));
        break;
    }
  }, [time]);

  const closeTournamentHandler = () => {
    setIsTournamentOpen(false);
    setIsShowBtn(false);
  };

  useEffect(() => {
    if (!autoApply && id > 0 && endAt > notificationManager.time()) {
      notificationManager.checkTournamentStatus(id, (isRes) => {
        if (!isRes) setIsShowBtn(!isRes);
      });
    }

    return () => {
      notificationManager.cancelCheckTournamnetStatus();
      notificationManager.cancelApplyTournament();
    };
  }, [id]);

  const actionHandler = () => {
    notificationManager.applyTournament(id, (isRes) => {
      if (isRes) setIsShowBtn(false);
    });
  };

  useEffect(() => {
    return () => {
      setTournament(DEFAULT_TOURNAMENT);
    };
  }, []);

  const handleTerms = () => setShowTerms((prev) => !prev);

  return (
    <SWrapper isActive={isTournamentOpen} className="no-scrollbars">
      {showTerms && (
        <SPopupScreen>
          <SPopupScreenTitle>{t('termsAndConditions')}</SPopupScreenTitle>
          <SLeftBtn onClick={handleTerms}>
            <Icon name="ic_arrowleft" />
          </SLeftBtn>
          <SPopupScreenContent>
            <Span html={getTranslation(terms, i18n.language, dlc)} isBlock />
          </SPopupScreenContent>
        </SPopupScreen>
      )}
      <SInner active={!showTerms}>
        <SHeader status={currentStatus}>
          <SHeaderContent>
            <SLeftBtn onClick={closeTournamentHandler}>
              <Icon name="ic_arrowleft" />
            </SLeftBtn>
            <SLogoWrapper>
              {imageUrl && <SLogo src={imageUrl} />}
              <SLogoIcon>
                <Icon
                  name={
                    currentStatus === Statuses.SOON
                      ? 'ic_soon'
                      : currentStatus === Statuses.LIVE
                      ? 'ic_live'
                      : 'ic_archive'
                  }
                />
              </SLogoIcon>
            </SLogoWrapper>
            <STimerContent>
              <STimerTitle>
                {currentStatus === Statuses.SOON
                  ? t('startsIn')
                  : currentStatus === Statuses.LIVE
                  ? t('endsIn')
                  : t('cup')}
              </STimerTitle>
              <STimer status={currentStatus}>
                {currentStatus === Statuses.SOON ||
                currentStatus === Statuses.LIVE
                  ? tournamentTime
                  : t('ended')}
              </STimer>
            </STimerContent>
          </SHeaderContent>
        </SHeader>
        <ExtraContentInfo
          id={id}
          name={name}
          description={description}
          dlc={dlc}
        />
        <SScreen>
          <SStartsScreen>
            <SScreenTitle>{t('starts')}</SScreenTitle>
            <SScreenTime>
              {dayjs(startAt).format('DD.MM.YY, HH:mm:ss')}
            </SScreenTime>
          </SStartsScreen>
          <SEndScreen>
            <SScreenTitle>{t('ends')}</SScreenTitle>
            <SScreenTime>
              {dayjs(endAt).format('DD.MM.YY, HH:mm:ss')}
            </SScreenTime>
          </SEndScreen>
        </SScreen>
        <SControls>
          <STermsBtn onClick={handleTerms}>{t('termsAndConditions')}</STermsBtn>
          {isShowBtn && (
            <SActionBtn status={currentStatus} onClick={actionHandler}>
              {t('optIn')}
            </SActionBtn>
          )}
        </SControls>

        {id > 0 && <TournamentList id={id} />}
      </SInner>
    </SWrapper>
  );
};

export default ExtraContent;
