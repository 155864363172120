import { atom } from 'recoil';

import { PRELOADER, PRELOADER_LOADING_PROGRESS } from '../constants';

export const FLAG_GAME = 1;
export const FLAG_FILES = 2;
export const FLAGS_ALL = 3;

const { NODE_ENV } = process.env;

export const APreloader = atom<number>({
  key: PRELOADER,
  default: NODE_ENV !== 'production' ? FLAG_GAME : 0,
});

export const APreloaderLoadingProgress = atom<number>({
  key: PRELOADER_LOADING_PROGRESS,
  default: 0,
});
