import { atom } from 'recoil';

import { TMessage } from 'components/Chat/components/ChatRoom/types';

import { MESSAGES } from '../constants';

export const AMessages = atom<TMessage[]>({
  key: MESSAGES,
  default: [] as TMessage[],
});
