import { atom } from 'recoil';

import { MY_BETS_LIST } from 'store/constants';

import { TMyBetItem, TCol } from './types';

export const MAX_MY_BETS_LIST_LENGTH = 2000;

export const SORTED_COLS: Map<TCol | 'bonuses', string> = new Map([
  ['rd', 'round'],
  ['a', 'bet'],
  ['m', 'collect'],
  ['wa', 'win'],
  ['bonuses', ''],
]);

export const AMyBetsList = atom<TMyBetItem[]>({
  key: MY_BETS_LIST,
  default: [],
});
