import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AMessages, AScrollHolder } from 'store/chat';
import { ACupsList } from 'store/tournaments';
import { ICupsListItem } from 'store/tournaments/types';
import { AInitialData } from 'store/init';
import { AAvailableContent, TSectionPermission } from 'store/config';
import { ATime } from 'store/time';
import { AAvatars } from 'store/avatars';

import notificationManger, {
  INotificationManagerListener,
} from 'api/notificationManager/NotificationManager';

import { TMessageData } from 'components/Chat/components/ChatRoom/types';
import { MESSAGES_LIMIT } from 'components/Chat/constants/messages';

let idx: number = 0;

const useNotificationManager = () => {
  const setMessages = useSetRecoilState(AMessages);
  const setInitData = useSetRecoilState(AInitialData);
  const setCupsList = useSetRecoilState(ACupsList);
  const setTime = useSetRecoilState(ATime);
  const setAvatars = useSetRecoilState(AAvatars);
  const setAvailableContent = useSetRecoilState(AAvailableContent);
  const scrollHolder = useRecoilValue(AScrollHolder);

  const scrollHolderRef = useRef(scrollHolder);

  const listener: INotificationManagerListener = {
    onInit: ({ acl, ...data }: any): void => {
      setAvailableContent(acl as TSectionPermission[]);
      setInitData(data);
    },

    onMessages: (data: any): void => {
      const isBottom = scrollHolderRef.current.isScrollBottom();

      setMessages((prevState) =>
        [
          ...prevState,
          ...data.map((item: TMessageData) => ({
            ...item,
            id: ++idx,
          })),
        ].slice(-MESSAGES_LIMIT),
      );

      if (isBottom) {
        setTimeout(() => {
          scrollHolderRef.current.scrollBottom(true);
        }, 30);
      }
    },

    onTournamentsUpdate(data: any) {
      try {
        if (!data || !Array.isArray(data)) {
          return;
        }

        const tournaments: ICupsListItem[] = [];
        const mAP = 3;

        data.forEach((d) => {
          if (d == null) {
            return;
          }

          const prizes = d.r_p?.prize || [];

          tournaments.push({
            id: d.id || 0,
            st: d.st || 0,
            et: d.et || 0,
            aa: d.aa || false,
            dlc: d.dlc || '',
            dcc: d.dcc || '',
            n: d.n || {},
            d: d.d || {},
            t: d.t || {},
            im: d.im,
            r_p: {
              total: {
                curr: d.r_p?.total?.curr || {},
              },
              prize: Array.isArray(prizes)
                ? prizes
                    .reduce((acc, cv) => {
                      if (cv == null) {
                        return acc;
                      }

                      const t = {
                        p: cv.p || 0,
                        isActive: cv.p <= mAP,
                        curr: cv.curr || {},
                        img: cv.img,
                        title: cv.title || {},
                        text: cv.text || {},
                        type: cv.type || '',
                      };

                      acc.push(t);

                      for (let i = t.p + 1 || 0; i <= (cv.to_p || 0); i++) {
                        acc.push({ ...t, p: i, isActive: i <= mAP });
                      }

                      return acc;
                    }, [])
                    .sort((a: any, b: any) => a.p - b.p)
                : [],
            },
          });
        });

        tournaments.sort((a, b) => {
          return a.id - b.id;
        });

        setCupsList(tournaments);
      } catch (e) {}
    },

    onSlowTimeUpdate(newTime: number) {
      setTime(newTime);
    },

    onAvatarUpdate(avatarId: number, avatarLink: string) {
      setInitData((p) => ({
        ...p,
        chat: {
          ...p.chat,
          a_id: avatarId,
          al: avatarLink,
        },
      }));
    },

    onGetAvatars(avatars: any[]) {
      setAvatars(avatars);
    },
  };

  useEffect(() => {
    scrollHolderRef.current = scrollHolder;
  }, [scrollHolder]);

  useEffect(() => {
    notificationManger.subscribeListener(listener);

    return () => {
      notificationManger.unsubscribeListener(listener);
    };
  }, []);
};

export default useNotificationManager;
