import { atom } from 'recoil';

import { TOURNAMENT, TOURNAMENT_INFO } from 'store/constants';
import { ICupsListItem } from 'store/tournaments/types';

export const ATournamentInfo = atom<boolean>({
  key: TOURNAMENT_INFO,
  default: false,
});

export const DEFAULT_TOURNAMENT = {
  id: 0,
  st: 0,
  et: 0,
  aa: false,
  dlc: '',
  dcc: '',
  n: {},
  d: {},
  t: {},
  im: '',
  r_p: {
    total: {
      curr: {},
    },
    prize: [],
  },
};

export const ATournament = atom<ICupsListItem>({
  key: TOURNAMENT,
  default: DEFAULT_TOURNAMENT,
});
