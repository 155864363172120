import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { RecoilRoot } from 'recoil';
import { I18nextProvider } from 'react-i18next';

import 'assests/style/index.css';
import i18n from 'i18n/config';

import App from './App';

render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RecoilRoot>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </RecoilRoot>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
