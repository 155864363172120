import styled from 'styled-components';

import { TUserNameProps } from './types';

export const Wrapper = styled.li<TUserNameProps>`
  display: block;
  position: relative;
  padding: 6px;
  align-items: center;
  ${({ ifYourMessage }) =>
    ifYourMessage && 'background-color: rgba(var(--lvl-1), 1)'};

  .popover > div {
    background-color: rgba(var(--primary-3), 1);
  }
`;

export const UsersNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
`;

export const ArrowIcon = styled.span`
  display: flex;
  margin: 0 4px;

  [data-icon] {
    color: rgba(var(--txt-normal), 1);
    > span {
      display: flex;
      align-items: center;
    }
    svg {
      width: 13px;
      height: 13px;
    }
  }
`;

export const UserIcon = styled.button`
  display: block;
  width: 38px;
  height: 38px;
  min-width: 38px;
  border-radius: 50%;
  background-color: rgba(var(--lvl-2), 1);
  cursor: pointer;
  position: relative;
  border: none;

  & > span,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const UserCard = styled.span`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span<TUserNameProps>`
  display: flex;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  word-break: break-word;
  color: ${({ ifYourMessage }) =>
    ifYourMessage ? 'rgba(var(--primary-1), 1)' : 'rgba(var(--txt-h2), 1)'};

  padding-left: ${({ withoutPadding }) => (withoutPadding ? '0' : '8px')};
`;

export const UserMessage = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: rgba(var(--txt-normal), 1);
  padding-left: 8px;
  word-break: break-word;
`;

export const ActionBtn = styled.span`
  width: 24px;
  height: 24px;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 13px;
  padding: 0;
  margin: 0;
  border: 0;
  [data-icon] {
    color: rgba(var(--white), 0.3);
  }
  z-index: 50;
`;
