import { FC } from 'react';

import imgPlusSrc from 'assests/icons/img_plus.svg';
import imgXSrc from 'assests/icons/img_x.svg';

import { SWrapper, SUser, SBet, SCollect, SWin, SBonus, SImg } from './style';
import { IBetsItemProps } from './types';

const BetsItem: FC<IBetsItemProps> = ({
  n,
  a,
  m,
  wa,
  bf = 0,
  bm = 0,
  toFixed,
  currency,
  style,
  isActive,
}) => (
  <SWrapper isActive={isActive} style={style}>
    <SUser>{n}</SUser>
    <SBet>
      {currency}
      {a}
    </SBet>
    <SCollect>{+m ? `×${+m.toFixed(2)}` : '-'}</SCollect>
    <SWin>{wa ? `${currency}${wa.toFixed(toFixed)}` : '-'}</SWin>
    <SBonus>
      {bf > 0 && <SImg src={imgPlusSrc} alt="plus" />}
      {bm > 1 && <SImg src={imgXSrc} alt="multi" />}
    </SBonus>
  </SWrapper>
);

export default BetsItem;
