import { FC, SyntheticEvent, useState } from 'react';

import { canSendMessage } from 'components/Chat/helpers/checkCanSend';

import InputSection from './components/InputSection';
import StickersSection from './components/StickersSection';
import { Wrapper } from './style';
import { TSendMessageFormProps } from './types';

const SendMessageForm: FC<TSendMessageFormProps> = ({
  handleSend,
  newMessage,
  onChange,
  handleOnKeydown,
  stickers,
  handleOnBlur,
  isValidMessage,
  syncTime,
  setIsShowSendBtn,
  nextMessageTimeRef,
}) => {
  const [isVisibleWords, setIsVisibleWords] = useState<boolean>(false);

  const handleSendWord = (e: SyntheticEvent, id?: number) => {
    if (canSendMessage(nextMessageTimeRef.current, syncTime)) {
      handleSend(e, id);
      hideWordsSection();
    }
  };

  const openWordsSection = () => setIsVisibleWords(true);
  const hideWordsSection = () => setIsVisibleWords(false);

  return (
    <Wrapper>
      {!isVisibleWords ? (
        <InputSection
          handleOnKeydown={handleOnKeydown}
          handleSend={handleSend}
          newMessage={newMessage}
          onChange={onChange}
          handleOnBlur={handleOnBlur}
          openWordsSection={openWordsSection}
          isValidMessage={isValidMessage}
          nextMessageTimeRef={nextMessageTimeRef}
          syncTime={syncTime}
          setIsShowSendBtn={setIsShowSendBtn}
        />
      ) : (
        <StickersSection
          stickers={stickers}
          handleSendWord={handleSendWord}
          hideWordsSection={hideWordsSection}
          nextMessageTimeRef={nextMessageTimeRef}
          syncTime={syncTime}
        />
      )}
    </Wrapper>
  );
};

export default SendMessageForm;
