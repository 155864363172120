import { atom } from 'recoil';

import { ACTIVE_CONTROL } from 'store/constants';

export enum EControl {
  PRIZES = 'prizes',
  LEADERBOARD = 'leaderboard',
}

export const AActiveControl = atom<EControl>({
  key: ACTIVE_CONTROL,
  default: EControl.PRIZES,
});
