import styled, { css } from 'styled-components';

export const SWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 720px) {
    height: calc(100% - 36px);
  }

  @media (max-width: 415px) {
    height: calc(100% - 38px);
  }
`;

export const SList = styled.div`
  width: 100%;
  height: 100%;

  @media (orientation: landscape) {
    height: calc(100% - 38px);
    position: absolute;
  }
`;

export const SScrollWrapper = styled.div<{ inactive?: boolean }>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  box-sizing: content-box;
  transition: all 0.3s;
  will-change: transform;

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.1;
      transform: scale(0.98);
    `}
`;

export const SNoTournaments = styled.div`
  height: calc(100% - 50px);
  font-size: 18px;
  font-weight: 400;
  color: rgba(var(--txt-h1), 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;
