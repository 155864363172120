import WsWrapper from 'api/ws/WsWrapper';

import TimeRequest from './request/TimeRequest';

export interface ISyncTime {
  time(): number;
  syncTime(): Promise<boolean>;
}

export const createSyncTime = (ws: WsWrapper): ISyncTime => {
  let delta = 0;
  let respDelay = Number.MAX_VALUE;
  const _ws = ws;

  const time = () => {
    return Date.now() + delta;
  };

  const syncTime = async (): Promise<boolean> => {
    if (_ws == null || !_ws.isConnected) {
      return false;
    }

    respDelay = Number.MAX_VALUE;

    return (await _syncTime()) && (await _syncTime());
  };

  const _syncTime = async () => {
    let st = await makeSyncTimeCall();

    if (!st.r) {
      return false;
    }

    if (respDelay < st.respDelay!) {
      return true;
    }

    respDelay = st.respDelay!;
    delta = st.delta!;
    return true;
  };

  const makeSyncTimeCall = async () => {
    let reqT = Date.now();
    let resp = await _ws.asyncSendMessage(new TimeRequest());
    let respT = Date.now();

    if (!resp.result || resp.data == null || !resp.data.t) {
      return { r: false };
    }

    let respDelay = respT - reqT;
    let delta = resp.data.t - respDelay / 2 - reqT;

    return { r: true, delta, respDelay };
  };

  return {
    time,
    syncTime,
  };
};
