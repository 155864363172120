import { atom } from 'recoil';

import { MAX_TOASTS } from 'constants/toasts';

import { IToast } from './types';

let id = 0;

export const AToastsList = atom<IToast[]>({
  key: 'AToastsList',
  default: [],
});

export const appendToast = (
  items: IToast[],
  item: Omit<IToast, 'id' | 'endDate'> & { duration?: number },
) => {
  const { duration = 5000, isHide, ...rest } = item;
  if (isHide) return items;

  return items.slice(-MAX_TOASTS).concat({
    id: id++,
    endDate: new Date().getTime() + duration,
    ...rest,
  });
};

export const removeToast = (items: IToast[], id: IToast['id']) =>
  items.filter((item) => item.id !== id);
