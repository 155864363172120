import { useEffect, useState } from 'react';

import { Literals } from '../constants/literals';

const defaultTranslations: { [key: string]: string } = {
  [Literals.Chat]: 'Chat',
  [Literals.EnterMessage]: 'Enter message',
  [Literals.ReportMessage]: 'Report message',
  [Literals.NotInterested]: 'Not interested',
};

const TranslationsManager = () => {
  let currentTranslations: { [key: string]: string } = {
    ...defaultTranslations,
  };

  const setTranslations = (newTranslations: { [key: string]: string }) => {
    currentTranslations = { ...defaultTranslations, ...newTranslations };
    translationsSubscribers.forEach((s) => {
      s(currentTranslations);
    });
  };

  const translationsSubscribers: ((t: { [key: string]: string }) => void)[] =
    [];

  const subscribe = (listener: (t: { [key: string]: string }) => void) => {
    if (translationsSubscribers.indexOf(listener) >= 0) {
      return;
    }
    translationsSubscribers.push(listener);
  };

  const unsubscribe = (listener: (t: { [key: string]: string }) => void) => {
    const index = translationsSubscribers.indexOf(listener);
    if (index >= 0) {
      translationsSubscribers.splice(index, 1);
    }
  };

  const getCurrentTranslation = () => currentTranslations;

  return {
    setTranslations,
    subscribe,
    unsubscribe,
    getCurrentTranslation,
  };
};

const tm = TranslationsManager();

export const useTranslationsManager = () => {
  const [t, setTransl] = useState<{ [key: string]: string }>(
    tm.getCurrentTranslation(),
  );

  const updateTranslations = (newTranslations: { [key: string]: string }) => {
    setTransl(newTranslations);
  };

  useEffect(() => {
    tm.subscribe(updateTranslations);
    return () => {
      tm.unsubscribe(updateTranslations);
    };
  }, []);

  return {
    setTranslations: tm.setTranslations,
    t,
  };
};
