import { FC } from 'react';
import { useRecoilValue } from 'recoil';

import { AAutoButtons } from 'store/gameButtons/autoButtons';

import { COLLECT, BET } from 'constants/autoButtons';

import {
  SMobButton,
  SFirstControl,
  SContent,
  SLastControl,
  STitle,
  SSubTitle,
  SAutoTitle,
  SAutoSubTitle,
} from './style';
import { TMobileBtnProps } from './types';

const MobileBtn: FC<TMobileBtnProps> = ({
  onClickHandler,
  firstControl,
  lastControl,
  price,
  autoCollect,
  status,
  disabled,
  autoBtnType,
  isFullWidth,
}) => {
  const stateAutoBtns = useRecoilValue(AAutoButtons);
  const { title: firstTitle, subTitle: firstSubTitle = '' } = firstControl;
  const { title: lastTitle, subTitle: lastSubTitle } = lastControl;

  //  conditions ==> value === -1 ? '-1' : value > 0 ? 'on' : value < 0 ? 'off';

  return (
    <SMobButton
      onClick={onClickHandler}
      status={status}
      disabled={disabled}
      isFullWidth={isFullWidth}
    >
      <SFirstControl>
        <STitle status={status}>{firstTitle}</STitle>
        <SSubTitle status={status}>{firstSubTitle}</SSubTitle>
      </SFirstControl>
      <SContent status={status}>
        {price}
        <br />
        {autoCollect}
      </SContent>
      <SLastControl>
        {stateAutoBtns.map(
          ({ groupBtnType, type, value }) =>
            groupBtnType === autoBtnType &&
            type === BET && (
              <SAutoTitle
                key={`${value}-btn-${groupBtnType}`}
                status={status}
                isActive={!!value}
              >
                {value
                  ? value === -1
                    ? lastTitle
                    : value > 0
                    ? lastTitle
                    : lastSubTitle
                  : lastSubTitle}
              </SAutoTitle>
            ),
        )}
        {stateAutoBtns.map(
          ({ groupBtnType, type, value }) =>
            groupBtnType === autoBtnType &&
            type === COLLECT && (
              <SAutoSubTitle
                key={`${value}-btn-${groupBtnType}`}
                status={status}
                isActive={!!value}
              >
                {value
                  ? value === -1
                    ? lastTitle
                    : value > 0
                    ? lastTitle
                    : lastSubTitle
                  : lastSubTitle}
              </SAutoSubTitle>
            ),
        )}
      </SLastControl>
    </SMobButton>
  );
};

export default MobileBtn;
