import { useRef, useEffect, RefObject, MutableRefObject } from 'react';

const MOVE_THRESHOLD = 6;

export const useDragScroll = (
  elementRef: MutableRefObject<HTMLDivElement> | RefObject<HTMLDivElement>,
  scrollMultiplier: number = 2,
): MutableRefObject<{ moved: boolean }> => {
  let lastMousePosition: number | null = null;
  let leftScroll = 0;
  let startLeftScroll = 0;
  const movedRef = useRef<{ moved: boolean }>({ moved: false });

  const onMouseDown = (e: MouseEvent) => {
    lastMousePosition = e.clientX;
    startLeftScroll = elementRef?.current?.scrollLeft || 0;
    leftScroll = elementRef?.current?.scrollLeft || 0;
    movedRef.current.moved = false;
  };

  const onMouseUp = (e: MouseEvent) => {
    movedRef.current.moved = startLeftScroll !== leftScroll;
    lastMousePosition = null;
  };

  const onMouseMove = (e: MouseEvent) => {
    if (elementRef.current === null || lastMousePosition === null) {
      return;
    }

    let delta = lastMousePosition - e.clientX;
    if (Math.abs(delta) < MOVE_THRESHOLD) {
      return;
    }
    lastMousePosition = e.clientX;
    leftScroll += scrollMultiplier * delta;
    elementRef.current.scrollLeft = leftScroll;
  };

  useEffect(() => {
    elementRef?.current?.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      elementRef?.current?.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return movedRef;
};
