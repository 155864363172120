import { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { AUiConfig } from 'store/config';
import { AIsOpenInfoBanner } from 'store/infoBanner';
import { AProjectConfigs } from 'store/projectConfigs';
import { ACountry } from 'store/init';

import { infoData } from 'constants/infoData';

import gameManager from "../../api/gameManager/GameManager";

import {
  SContainer,
  SWrapper,
  SInfoWrapper,
  SCloseBtn,
  SLogo,
  STitle,
  SSubInfo,
  SSubText,
  SSubTitle,
  SContent,
  SComponentImg,
  SBtnWrapper,
  SInfoItem,
  SLastTitle,
  SContentSubTitle,
  SContentInfo,
  SContentTitle,
} from './style';

const InfoBanner: FC = () => {
  const { t } = useTranslation();
  const setIsOpenInfoBanner = useSetRecoilState(AIsOpenInfoBanner);
  const { logo } = useRecoilValue(AProjectConfigs);
  const country = useRecoilValue(ACountry);
  const hideInfoHandler = () => setIsOpenInfoBanner(false);
  const { disableAutobet, disableAutocollect } = useRecoilValue(AUiConfig);

  const createTranslation = (literal: string, country?: string) => {
    if (!country) {
      return t(literal);
    }

    const nl = `${literal}-${country}`;
    const cl = t(nl);

    return cl === nl ? t(literal) : cl;
  };

  const check = (checkValue?: string): boolean => {
    switch (checkValue) {
      case 'autoBet':
        return !disableAutobet;
      case 'autoCollect':
        return !disableAutocollect;
      default:
        return true;
    }
  };

  return (
    <SContainer>
      <SWrapper>
        <SInfoWrapper className="no-scrollbars">
          <SLogo src={logo} />
          <STitle>{createTranslation('information', country)}</STitle>
          <SSubInfo>
            <SSubTitle>{createTranslation('rtp', country)}</SSubTitle>
            <SSubText>{createTranslation('infoSubTitle', country)}</SSubText>
          </SSubInfo>
          <SContent>
            {infoData.map(
              ({ id, infoText, title, content, lastTitle, checkValue }) =>
                check(checkValue) ? (
                  <SInfoItem key={id}>
                    <SContentTitle>
                      {createTranslation(title, country)}
                    </SContentTitle>
                    <SContentInfo>
                      {createTranslation(infoText, country)}
                    </SContentInfo>
                    {lastTitle && (
                      <SLastTitle>
                        {createTranslation(lastTitle, country)}
                      </SLastTitle>
                    )}
                    {content &&
                      content.filter((item) => {
                        //Filter out items that are hidden when cancel bet is disabled.
                        return item.dcb != !gameManager.cancelButtonDisabled()
                      }).map(
                        ({ id, subTitle, pathImg, isFullWidth, text ,dcb}) => {
                          return (
                            <SInfoItem key={id}>
                              <SContentSubTitle>
                                {createTranslation(subTitle, country)}
                              </SContentSubTitle>
                              {pathImg && (
                                <SComponentImg
                                  isFullWidth={isFullWidth}
                                  src={createTranslation(pathImg, country)}
                                />
                              )}
                              <SContentInfo>
                                {createTranslation(text, country)}
                              </SContentInfo>
                            </SInfoItem>
                          );
                        },
                      )}
                  </SInfoItem>
                ) : (
                  <></>
                ),
            )}
          </SContent>
        </SInfoWrapper>
        <SBtnWrapper>
          <SCloseBtn onClick={hideInfoHandler}>{t('close')}</SCloseBtn>
        </SBtnWrapper>
      </SWrapper>
    </SContainer>
  );
};

export default InfoBanner;
