import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ABetsList } from 'store/bets';
import { AMyBetsList, MAX_MY_BETS_LIST_LENGTH } from 'store/myBets';
import { ALastResults } from 'store/lastResult';
import {
  APreloader,
  APreloaderLoadingProgress,
  FLAG_GAME,
} from 'store/config/preloader';
import { ABalance } from 'store/balance';
import { AIsSound } from 'store/sound';
import { AModalSelector, IModalButton } from 'store/modal';
import {
  ABetButton1,
  ABetButton2,
  ATakeWin1,
  ATakeWin2,
  AControllerBet1,
  AControllerBet2,
  AControllerCollect1,
  AControllerCollect2,
} from 'store/gameButtons';
import { AAutoButtons } from 'store/gameButtons/autoButtons';
import { appendToast, removeToast, AToastsList } from 'store/toasts/toastsList';
import { AFreeBet } from 'store/freebet';
import { TMyBetItem } from 'store/myBets/types';
import { ATotalCounter } from 'store/totalCounter';
import { AIsOpenInfoBanner } from 'store/infoBanner';
import { AUI } from 'store/ui';
import { ACountry } from 'store/init';

import {
  MESSAGE_CONTENT_TYPE_BANNER,
  MESSAGE_SENDER_TYPE_SERVICE,
  MESSAGE_TARGET_TYPE_PRIVATE,
  SERVICE_MESSAGE_CONTENT_TYPE_TEXT,
} from 'components/Chat/constants/receiveMessages';

import { TBtsItem } from 'constants/betsList';
import { GameButton, TBetButtonState } from 'constants/gameButtons';
import { LAST_RESULTS_LIMIT } from 'constants/results';

import notificationManager from '../notificationManager/NotificationManager';

import gameManager, { IGameManagerListener } from './GameManager';

let lastResultIdx: number = 0;

const useGameManager = () => {
  const { t } = useTranslation();
  const setBetsList = useSetRecoilState(ABetsList);
  const setLastResults = useSetRecoilState(ALastResults);
  const setPreloader = useSetRecoilState(APreloader);
  const setPreloaderLoading = useSetRecoilState(APreloaderLoadingProgress);
  const setBalance = useSetRecoilState(ABalance);
  const setSound = useSetRecoilState(AIsSound);
  const setBetButton1 = useSetRecoilState(ABetButton1);
  const setBetButton2 = useSetRecoilState(ABetButton2);
  const setTakeWin1 = useSetRecoilState(ATakeWin1);
  const setTakeWin2 = useSetRecoilState(ATakeWin2);
  const setControllerBet1 = useSetRecoilState(AControllerBet1);
  const setControllerBet2 = useSetRecoilState(AControllerBet2);
  const setControllerCollect1 = useSetRecoilState(AControllerCollect1);
  const setControllerCollect2 = useSetRecoilState(AControllerCollect2);
  const setToasts = useSetRecoilState(AToastsList);
  const setStateAutoBtns = useSetRecoilState(AAutoButtons);
  const setModal = useSetRecoilState(AModalSelector);
  const setFreeBet = useSetRecoilState(AFreeBet);
  const setMyBetsList = useSetRecoilState(AMyBetsList);
  const setTotalCounter = useSetRecoilState(ATotalCounter);
  const setIsOpenInfoBanner = useSetRecoilState(AIsOpenInfoBanner);
  const setUI = useSetRecoilState(AUI);
  const setCountry = useSetRecoilState(ACountry);

  const onErrorMessage = (errorData: any) => {
    console.log('onErrorMessage', errorData);
    let msg = t('errorConnection');
    let errorCode = 0;

    if (errorData.msg && typeof errorData.msg === 'string') {
      msg = errorData.msg;
    } else if (errorData.msg && typeof errorData.msg.msg === 'string') {
      msg = errorData.msg.msg || '';
      errorCode = errorData.msg.c || 0;
    }

    let buttons: IModalButton[] = [
      {
        label: t('reload'),
        type: 'reload',
      },
    ];

    switch (errorCode) {
      case 911:
        const b = errorData.msg.b;

        if (!b || typeof b !== 'string') {
          break;
        }

        let btns = null;

        try {
          btns = JSON.parse(b);
        } catch (e) {
          break;
        }

        if (!btns || !Array.isArray(btns) || !btns.length) {
          break;
        }

        let newButtons: IModalButton[] = [];
        btns.forEach((b) => {
          if (!b) {
            return;
          }

          const type = b.action || 'reload';

          newButtons.push({
            label: b.text || t('continue'),
            type: type,
            action:
              type === 'history'
                ? gameManager.navigateToHistoryURL.bind(gameManager)
                : type === 'lobby'
                ? gameManager.navigateToLobbyURL.bind(gameManager)
                : undefined,
          });
        });

        if (newButtons.length) {
          buttons = newButtons;
        }
        break;
      case 804:
        msg = t('timeout');
        break;
      default:
        msg = t('defaultErrorDesc');
    }

    setModal({
      title: msg,
      buttons: buttons,
    });
  };

  const listener: IGameManagerListener = {
    onReady: () => {
      setPreloader((prev) => prev | FLAG_GAME);

      setToasts((toasts) =>
        appendToast(toasts, {
          text: t('turnOffSound'),
          icon: 'ic_soundoff',
          type: 'default',
          isHide: true,
          extra: {
            title: t('yesPlease'),
            action: (toastId) => {
              setSound(false);
              setToasts((prevToasts) => removeToast(prevToasts, toastId));
            },
          },
          duration: 3000,
        }),
      );
    },

    onBalance: (balance: string) => {
      setBalance(balance);
    },

    onBetsUpdate: (arr: TBtsItem[]) => {
      setBetsList(arr);
    },

    onNewMultiplier: (arr: number[], clear: boolean) => {
      setLastResults((prevState) =>
        [
          ...arr.reverse().map((item: number) => ({
            m: item,
            id: ++lastResultIdx,
          })),
          ...(clear ? [] : prevState),
        ].slice(0, LAST_RESULTS_LIMIT),
      );
    },

    onInit: (data: any) => {
      setSound(data?.musicChecked || true);

      const betStep = data?.betStep || 1;
      const collectStep = data?.collectStep || 1;
      const minBet = data?.minBet || 0;
      const maxBet = data?.maxBet || 0;
      const minCollect = data?.minCollect || 0;
      const maxCollect = data?.maxCollect || 0;
      const bet1 = data?.bet1 || 0;
      const bet2 = data?.bet2 || 0;
      const collect1 = data?.collect1 || 0;
      const collect2 = data?.collect2 || 0;
      const availableBets = data?.availableBets || [];
      const betController2Hidden = data?.bc_2h;

      const betOptions = {
        minValue: minBet,
        maxValue: maxBet,
        step: betStep,
        currentValue: 0,
        symbol: data?.currency || '',
        toFixed: data?.toFixed || 2,
      };

      const collectOptions = {
        minValue: minCollect,
        maxValue: maxCollect,
        step: collectStep,
        currentValue: 0,
        symbol: '×',
        toFixed: 2,
      };

      setControllerBet1({
        ...betOptions,
        currentValue: bet1,
        availableBets,
        isHidden: false,
      });
      setControllerBet2({
        ...betOptions,
        currentValue: bet2,
        availableBets,
        isHidden: betController2Hidden,
      });
      setControllerCollect1({
        ...collectOptions,
        currentValue: collect1,
        isHidden: false,
      });
      setControllerCollect2({
        ...collectOptions,
        currentValue: collect2,
        isHidden: betController2Hidden,
      });
      setTotalCounter((prev) => ({
        ...prev,
        isGameInited: true,
        startTime: dayjs().valueOf(),
        currency: data?.currency,
      }));
      setCountry(data?.pc || '');
    },

    onUpdateButtonState: (
      button1: TBetButtonState,
      button2: TBetButtonState,
    ) => {
      setBetButton1(button1);
      setBetButton2(button2);
    },

    onTakeWinValueChanged(
      button: GameButton,
      value: number,
      currency: string,
      toFixed: number,
      freeBets: number = 0,
    ) {
      if (button === GameButton.BUTTON_1) {
        setTakeWin1({
          win: `${currency}${value.toFixed(toFixed)}`,
          freeBets,
        });
      } else {
        setTakeWin2({
          win: `${currency}${value.toFixed(toFixed)}`,
          freeBets,
        });
      }
    },

    onWin(value: number, currency) {
      setTotalCounter((prev) => ({
        ...prev,
        won: prev.won + value,
        currency,
      }));

      setToasts((toasts) =>
        appendToast(toasts, {
          text: t('youWinAmount', { amount: `${currency}${value}` }),
          type: 'success',
          // extra: {
          //   title: t('shareToChat'),
          // },
          duration: 1000,
        }),
      );
    },

    onBetAmountChanged(button: GameButton, value: number) {
      if (button === GameButton.BUTTON_1) {
        setControllerBet1((p) => ({ ...p, currentValue: value }));
      } else {
        setControllerBet2((p) => ({ ...p, currentValue: value }));
      }
    },

    onCollectAmountChanged(button: GameButton, value: number) {
      if (button === GameButton.BUTTON_1) {
        setControllerCollect1((p) => ({ ...p, currentValue: value }));
      } else {
        setControllerCollect2((p) => ({ ...p, currentValue: value }));
      }
    },

    onUpdateAutoBet(button: GameButton, value: number) {
      setStateAutoBtns((prev) =>
        prev.map((item) =>
          item.type === 'bet' && item.groupBtnType === button
            ? { ...item, value: value }
            : item,
        ),
      );
    },

    onUpdateAutoCollect(button: GameButton, value: number) {
      setStateAutoBtns((prev) =>
        prev.map((item) =>
          item.type === 'collect' && item.groupBtnType === button
            ? { ...item, value: value }
            : item,
        ),
      );
    },

    onFreeBet(a: {
      is_active: boolean;
      total_win: number;
      amount_left: number;
      total_amount_given: number;
      coin_value: number;
      coin_values: number[];
    }) {
      const {
        is_active = false,
        total_win = 0,
        amount_left = 0,
        total_amount_given = 0,
        coin_value = 0,
        coin_values = [],
      } = a;
      setFreeBet({
        isActive: Boolean(is_active),
        amountLeft: amount_left > 0 ? +amount_left : 0,
        totalWin: total_win > 0 ? +total_win : 0,
        totalAmountGiven: total_amount_given > 0 ? +total_amount_given : 0,
        coinValue: coin_value > 0 ? +coin_value : 0,
        coinValues: coin_values || [],
      });
    },

    onAppendToBetsHistory(arr: TMyBetItem[]) {
      setMyBetsList((prev) =>
        [...prev, ...arr].slice(-MAX_MY_BETS_LIST_LENGTH),
      );
    },

    onErrorMessage(errorData: any) {
      onErrorMessage(errorData);
    },

    onShowInfo(show: boolean) {
      setIsOpenInfoBanner(show);
    },

    onSoundUpdated(on: boolean) {
      setSound(on);
    },

    onEnableUI(enable: boolean) {
      setUI(enable);
    },

    onProgress(value: number) {
      setPreloaderLoading(value);
    },

    onInternalChatMsg(data: any) {
      if (data?.sysMsg) {
        notificationManager.appendChatMessage({
          a_l: '',
          ct: MESSAGE_CONTENT_TYPE_BANNER,
          from: '',
          g_id: 0,
          s: null,
          s_id: 0,
          sm: {
            id: 0,
            ct: SERVICE_MESSAGE_CONTENT_TYPE_TEXT,
            bt: 0,
            lt: 0,
            dlc: 'en',
            ti_l: {
              en: data?.sysMsg?.title,
            },
            t_l: {
              en: data?.sysMsg?.text,
            },
            i_l: null,
            bt_l: {},
            l_l: {},
          },
          st: MESSAGE_SENDER_TYPE_SERVICE,
          t: '',
          to: '',
          tt: MESSAGE_TARGET_TYPE_PRIVATE,
        });
        return;
      }

      if (data?.msg) {
        notificationManager.appendChatMessage(data.msg);
      }
    },
  };

  useEffect(() => {
    gameManager.subscribeListener(listener);

    if (!gameManager.getGameUrl()) {
      onErrorMessage({ msg: "Can't find game url" });
    }

    return () => {
      gameManager.unsubscribeListener(listener);
    };
  }, []);
};

export default useGameManager;
