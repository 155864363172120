import React, { useState, useEffect, FC } from 'react';

import { INFINITY_TIME } from 'components/Chat/constants/messages';

import Icon from 'shared/components/Icon';

import { TimerWrapper, Container, TimerIcon, InfinityIcon } from './style';
import { ITimerProps } from './types';

const Timer: FC<ITimerProps<number, boolean>> = ({
  syncTime,
  setIsShowSendBtn,
  nextMessageTimeRef,
}) => {
  const getMillis = () => Math.max(0, nextMessageTimeRef.current - syncTime());
  const milliseconds = getMillis();

  const [timeInMillis, setTimeInMillis] = useState(milliseconds);

  const getTimerText = (millis: number) => {
    const timeInSeconds = millis / 1000;
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    if (nextMessageTimeRef.current === INFINITY_TIME) {
      return;
    }

    const updateTime = () => {
      const millis = getMillis();
      setTimeInMillis(millis);
      const isEnded = millis === 0;
      if (isEnded) {
        if (setIsShowSendBtn) {
          setIsShowSendBtn(true);
        }
      }
      return isEnded;
    };

    if (updateTime()) {
      return;
    }

    const myInterval = setInterval(() => {
      if (updateTime()) {
        clearInterval(myInterval);
      }
    }, 500);

    // Clear myInterval if the component is unmounted
    return () => clearInterval(myInterval);
  }, []);

  return (
    <>
      {nextMessageTimeRef.current === INFINITY_TIME ? (
        <Container>
          <InfinityIcon>
            <Icon name="ic_infinitysign" />
          </InfinityIcon>
          <TimerIcon>
            <Icon name="ic_timer" />
          </TimerIcon>
        </Container>
      ) : (
        <Container>
          {timeInMillis === 0 ? null : (
            <>
              <TimerWrapper>
                <span>{getTimerText(timeInMillis)}</span>
              </TimerWrapper>
              <TimerIcon>
                <Icon name="ic_timer" />
              </TimerIcon>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Timer;
