import defaultLogo from 'assests/img/gif/logo.min.gif';
import { ReactComponent as BetanoLogo } from 'assests/img/betano_logo.min.svg';

export type TPreloaderStyle = {
  cover: any;
  progressBar: any;
  progressTrack: any;
  imageLogo: any;
  image: any;
  logoStyle: any;
  logoSVG: any;
  logoGif: any;
  imageLogoPortrait?: any;
  logoStylePortrait?: any;
};

const defaultStyle: TPreloaderStyle = {
  cover: {
    background: '#000000',
  },
  progressBar: {
    background: '#232222',
    borderRadius: '10px',
    width: '70%',
    height: '14px',
    marginTop: '20px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  progressTrack: {
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(90deg, #16307c, #28bcea)',
  },
  imageLogo: { width: '100%', maxWidth: '60vw', height: 'auto' },
  imageLogoPortrait: { maxWidth: '80vw', width: '80vw' },
  image: { maxWidth: '60vw', height: 'auto' },
  logoStyle: { maxWidth: '100%', width: '100%' },
  logoStylePortrait: { maxWidth: '80vw', width: '80vw' },
  logoSVG: null,
  logoGif: defaultLogo,
};

const betanoStyle: TPreloaderStyle = {
  cover: {
    background: '#232B36',
  },
  progressBar: {
    background: 'rgba(35, 33, 32, 1)',
  },
  progressTrack: {
    backgroundImage: 'linear-gradient(90deg, #EF8530, #EF8530)',
  },
  imageLogo: {},
  image: {
    height: '200px',
    scale: '0.4',
    maxWidth: '100%',
  },
  logoStyle: {},
  logoSVG: BetanoLogo,
  logoGif: null,
};

export const getStyle = (
  preloader: string | undefined | null,
): TPreloaderStyle => {
  switch (preloader) {
    case 'betano':
      return betanoStyle;
    default:
      return defaultStyle;
  }
};
