import { FC } from 'react';

import gameManager from 'api/gameManager/GameManager';

import { Wrapper, SButton, SInfo } from './style';
import { THotDrop } from './types';

const HotDrop: FC<THotDrop> = ({
  serviceMessage,
  getTranslation,
  onShowInfoPopup,
}) => {
  const handleButtonClick = () => {
    if (serviceMessage) {
      gameManager.processServiceMessageLink(
        serviceMessage.lt,
        getTranslation(serviceMessage!.l_l),
      );
    }
  };

  const handleInfoClick = () => {
    if (serviceMessage) {
      onShowInfoPopup({ text: getTranslation(serviceMessage!.t_l) });
    }
  };

  return (
    <Wrapper>
      <SButton onClick={handleButtonClick} />
      <SInfo onClick={handleInfoClick} />
    </Wrapper>
  );
};

export default HotDrop;
