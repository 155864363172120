import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'shared/components/Icon';

import Timer from '../Timer';

import { EmojiBtn, SendBtn, StyledInput, Wrapper, Container } from './style';
import { TInputSectionProps } from './types';

const InputSection: FC<TInputSectionProps> = ({
  handleSend,
  newMessage,
  onChange,
  openWordsSection,
  handleOnKeydown,
  handleOnBlur,
  isValidMessage,
  syncTime,
  setIsShowSendBtn,
  nextMessageTimeRef,
}) => {
  const { t } = useTranslation();

  const isSendIcon =
    nextMessageTimeRef.current !== -1 &&
    Math.max(0, nextMessageTimeRef.current - syncTime()) === 0;

  return (
    <Wrapper>
      <EmojiBtn onClick={openWordsSection}>
        <Icon name="ic_stickers" />
      </EmojiBtn>
      <StyledInput
        isSendIcon={isSendIcon}
        value={newMessage}
        onKeyDown={handleOnKeydown}
        onBlur={handleOnBlur}
        placeholder={t('enterMessage')}
        onChange={onChange}
        type="text"
      />
      {isSendIcon ? (
        <SendBtn
          isActive={isValidMessage}
          onClick={handleSend}
          disabled={!newMessage && !isValidMessage}
        >
          <Icon name="ic_send" />
        </SendBtn>
      ) : (
        <Container>
          <Timer
            nextMessageTimeRef={nextMessageTimeRef}
            syncTime={syncTime}
            setIsShowSendBtn={setIsShowSendBtn}
          />
        </Container>
      )}
    </Wrapper>
  );
};

export default InputSection;
