import { FC } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ru';

dayjs.extend(duration);
dayjs.extend(updateLocale);

const DateProvider: FC<{ lang?: 'en' | 'de' | 'ru' }> = ({
  lang = 'en',
  children,
}) => {
  dayjs.locale(lang);

  return <>{children}</>;
};

export default DateProvider;
