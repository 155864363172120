import { atom } from 'recoil';

import { LAST_RESULTS } from 'store/constants';

import { IResult } from './types';

export const ALastResults = atom<IResult[]>({
  key: LAST_RESULTS,
  default: [],
});
