import styled, { css } from 'styled-components';

const wrapperStyle = css`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgba(var(--lvl-0), 1);
  border-radius: 6px;
`;
export const SWrapper = styled.div`
  ${wrapperStyle};
`;

export const SWrapperPopup = styled.div`
  ${wrapperStyle};
  position: absolute;
  left: 0;
  top: 50px;
  height: calc(100% - 50px);
  border-radius: 0;
  z-index: 100;
  animation: 0.25s fadeIn forwards;

  div {
    border-radius: 0;
  }

  @media (max-width: 720px) {
    border-radius: 6px;

    top: 0;
    height: 100%;
  }
`;

export const SCloseButton = styled.button`
  color: rgba(var(--white), 1);
  border: none;
  background: none;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`;

export const SMarker = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
  color: rgba(var(--txt-h2-primary-1), 1);
  opacity: 0.5;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 720px) {
    top: 22px;
  }
`;

export const SHeader = styled.div`
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  background: linear-gradient(
    to bottom,
    rgba(var(--primary-1), 0.3) 0%,
    rgba(var(--primary-1), 0.29) 4%,
    rgba(var(--primary-1), 0.28) 8%,
    rgba(var(--primary-1), 0.27) 11%,
    rgba(var(--primary-1), 0.25) 14%,
    rgba(var(--primary-1), 0.23) 17%,
    rgba(var(--primary-1), 0.21) 20%,
    rgba(var(--primary-1), 0.18) 24%,
    rgba(var(--primary-1), 0.16) 27%,
    rgba(var(--primary-1), 0.14) 31%,
    rgba(var(--primary-1), 0.11) 36%,
    rgba(var(--primary-1), 0.09) 41%,
    rgba(var(--primary-1), 0.07) 46%,
    rgba(var(--primary-1), 0.05) 53%,
    rgba(var(--primary-1), 0.04) 60%,
    rgba(var(--primary-1), 0.02) 68%,
    rgba(var(--primary-1), 0.01) 78%,
    rgba(var(--primary-1), 0) 88%,
    rgba(var(--primary-1), 0) 100%
  );
`;

export const SIconWrapper = styled.div`
  position: relative;
  height: 120px;
  width: 120px;

  [data-icon='ic_chatavatar'] {
    svg {
      height: 120px;
      width: 120px;
    }
  }
`;

const btnStyles = css`
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none;
  border: none;
  cursor: pointer;
`;

export const SLeftBtn = styled.button`
  position: absolute;
  left: 16px;
  top: 20px;
  width: 24px;
  height: 24px;
  background-color: transparent;

  [data-icon] {
    color: rgba(var(--txt-white), 1);
  }

  ${btnStyles};
`;

export const STitleWrapper = styled.div`
  margin: 10px 24px;
  display: flex;
  justify-content: center;
`;

export const SUserTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  color: rgba(var(--txt-h1), 1);
  margin: 0;
`;

export const SAvatarImg = styled.img`
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0;
  object-fit: cover;
  background-color: rgba(var(--lvl-0), 1);
`;

export const SEditButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  background: rgba(var(--primary-1), 1);
  color: rgba(var(--dark), 1);
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

export const SActionBtn = styled.button`
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none;
  border: none;
  cursor: pointer;
  width: 200px;
  height: 44px;
  border-radius: 21px;
  box-shadow: 0 2px 5px rgba(var(--shdw-primary-3));
  background-color: rgba(var(--primary-2), 1);
  color: rgba(var(--txt-h1), 1);
  font-weight: 500;
  font-size: 17px;
  margin: 10px auto;

  @media (max-width: 415px) {
    margin: 0 auto;
    width: 200px;
    height: 28px;
    font-size: 15px;
  }
`;
