import styled, { css } from 'styled-components';

const btnStyles = css`
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
`;

export const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

export const SActions = styled.div`
  margin: 20px auto;
  display: flex;
  width: 224px;
  height: 30px;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(var(--primary-1), 0.2);
`;

export const SPrizeBtn = styled.button<{ isActive?: boolean }>`
  ${btnStyles};

  background-color: ${({ isActive }) =>
    isActive ? 'rgba(var(--primary-1), 1)' : 'transparent'};
  color: ${({ isActive }) =>
    isActive
      ? 'rgba(var(--txt-h2-dark), 1)'
      : 'rgba(var(--txt-h2-primary-1), 1)'};
`;

export const SLeaderBoardBtn = styled.button<{ isActive?: boolean }>`
  ${btnStyles};

  background-color: ${({ isActive }) =>
    isActive ? 'rgba(var(--primary-1), 1)' : 'transparent'};
  color: ${({ isActive }) =>
    isActive
      ? 'rgba(var(--txt-h2-dark), 1)'
      : 'rgba(var(--txt-h2-primary-1), 1)'};
`;

export const SPrizesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SFirstSection = styled.span`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const styleText = css`
  font-family: 'Rubik', sans-serif;
  text-align: center;
`;
export const SFirstCardTitle = styled.p`
  ${styleText};

  margin: 0;
  color: rgba(var(--txt-h1), 1);
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
`;

export const SFirstCardText = styled.p`
  ${styleText};

  margin: 2px 0;
  color: rgba(var(--txt-normal), 1);
  font-size: 12px;
  font-weight: 400;
`;

export const SCard = styled.li<{ isActive?: boolean }>`
  height: 50px;
  max-width: 348px;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(var(--lvl-1), 1);
  margin: 3px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: '';
    width: 3px;
    height: 28px;
    border-radius: 0 3px 3px 0;
    background: ${({ isActive }) =>
      isActive ? 'rgba(var(--primary-1), .2)' : 'rgba(var(--white), .2)'};
    display: block;
    position: absolute;
    z-index: var(--z-index-50);
    left: 0;
    transition: all 0.2s;
  }

  @media (max-width: 720px) {
    max-width: 100%;
  }
`;

export const SLeftCard = styled.div`
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
  display: flex;
  align-items: center;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SCardNumber = styled.span<{ isActive?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  width: 55px;
  overflow: hidden;
  color: ${({ isActive }) =>
    isActive ? 'rgba(var(--txt-h2-primary-1), 1)' : 'rgba(var(--txt-h2), 1)'};
  margin-left: 8px;
`;

export const SCardInfo = styled.div`
  width: 100%;
  max-height: 100%;
  margin-left: 5px;
  overflow: hidden;
  line-height: 13px;
`;

export const SCardName = styled.p`
  ${styleText};
  margin: 0;

  color: rgba(var(--primary-1), 1);
  font-weight: 500;
  font-size: 12px;
  text-align: left;

  overflow: hidden;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const SCardDescription = styled.p`
  ${styleText};
  margin: 0;
  text-align: left;

  color: rgba(var(--txt-cap), 1);
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SRightCard = styled.div`
  height: 100%;
  flex: 0 0 78px;
  width: 78px;
  overflow: hidden;
  position: relative;
  border-radius: 0 4px 4px 0;
`;

export const SCardIcon = styled.img`
  flex: 0 0 78px;
  width: 78px;
  height: 50px;
  top: 0;
  overflow: hidden;
  position: relative;
  clip-path: circle(50px at 50px 25px);
  object-fit: cover;
`;

export const SLeaderBoardWrapper = styled.li<{ isCurrentUser?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  background-color: ${({ isCurrentUser }) =>
    isCurrentUser ? 'rgba(var(--primary-1), 0.1)' : 'rgba(var(--lvl-1), 1)'};
  max-height: 85px;
  border-radius: 4px;
`;

export const SFirstBlock = styled.span`
  display: flex;
  justify-content: space-between;
  height: 34px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid rgba(var(--lvl-0), 1);
  align-items: center;
  padding: 0 6px;
`;

export const SPlace = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.286;
  color: rgba(var(--txt-h2-primary-1), 1);
  max-width: 49%;
  overflow-y: hidden;
  text-transform: lowercase;
`;

export const SUserName = styled.span`
  font-weight: 400;
  font-size: 15px;
  color: rgba(var(--grid-txt-normal), 1);
  max-width: 49%;
  overflow-y: hidden;
`;

export const SSecondBlock = styled.span`
  display: flex;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  max-height: 50px;
  width: 100%;
  padding: 8px 6px;
  align-items: center;
`;

const secondBlockStyle = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.286;
`;
export const SWin = styled.span`
  ${secondBlockStyle};
  color: rgba(var(--grid-txt-primary-1), 0.5);
`;

export const SPoints = styled.span`
  ${secondBlockStyle};
  text-align: right;
  display: block;
  color: rgba(var(--grid-txt-normal), 1);
`;

export const SBottomTitle = styled.p<{ isCurrentUser?: boolean }>`
  color: ${({ isCurrentUser }) =>
    isCurrentUser ? 'rgba(var(--primary-1), .7)' : 'rgba(var(--grid-cap), 1)'};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
`;

export const SNoTournament = styled.div`
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(var(--lvl-2), 1);
  color: rgba(var(--txt-h1), 1);
  position: absolute;
  left: 50%;
  margin-top: 150px;
  transform: translate(-50%, -50%);
`;

export const STitle = styled.span`
  color: rgba(var(--txt-h1), 1);
  font-weight: 400;
  font-size: 12px;
  width: 70px;
  text-align: right;
  text-transform: uppercase;
`;

export const SYou = styled.span`
  color: rgba(var(--primary-1), 1);
`;

export const SDot = styled.span`
  color: rgba(var(--primary-1), 1);
`;
