type TGetRecipientsName = (value: string) => {
  recipient: string;
  text: string;
};

export const getRecipientsNameAndText: TGetRecipientsName = (value) => {
  const regexpName = /^@[a-zA-Z0-9-]*($|\s)/;
  const regexpEmptySymbols =
    /(^[\u0020\u00A0\u2000-\u2009\u200A\u2028\u205F\u3000]+|[\u0020\u00A0\u2000-\u2009\u200A\u2028\u205F\u3000]+$)/g;

  const prepValue = value.trim();
  const name = prepValue.match(regexpName);
  const recipientName = name && name.length ? name[0] : '';
  const recipient = recipientName.trim().replace('@', '');
  const text = prepValue
    .substring(recipientName.length, prepValue.length)
    .trim()
    .replace(regexpEmptySymbols, '');

  return {
    recipient,
    text,
  };
};
