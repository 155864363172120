export const BORDER_S = { isBorder: true };

export const TOP_BUTTON_S = {
  isBorderB: true,
  isBorderL: true,
  isRadiusT: true,
  isRadiusB: false,
};

export const BOTTOM_BUTTON_S = {
  isBorderB: false,
  isBorderL: true,
  isRadiusT: false,
  isRadiusB: true,
};
