import { atom } from 'recoil';

import { AUTO_BUTTONS } from 'store/constants';

import { autoButtons } from 'constants/autoButtons';

//TODO type should be constants
//TODO group btn type should be constatns
export type TAutoBtn = {
  value?: number;
  isOpen?: boolean;
  enabled?: boolean;
  groupBtnType: 1 | 2;
  type: 'bet' | 'collect';
};

export const AAutoButtons = atom<TAutoBtn[]>({
  key: AUTO_BUTTONS,
  default: autoButtons,
});
