import { atom } from 'recoil';

import { BETS_LIST } from 'store/constants';

import { TBtsItem } from 'constants/betsList';

export const ABetsList = atom<TBtsItem[]>({
  key: BETS_LIST,
  // default: betsListR,
  default: [],
});
