import { Statuses } from 'constants/TournamentsStatuses';

type TGetTournamentsStatus = (
  time: number,
  startAt: number,
  endAt: number,
) => Statuses.SOON | Statuses.LIVE | Statuses.ENDED;

export const getTournamentsStatus: TGetTournamentsStatus = (
  time,
  startAt,
  endAt,
) => {
  if (time < startAt) {
    return Statuses.SOON;
  }
  if (time < endAt) {
    return Statuses.LIVE;
  }
  return Statuses.ENDED;
};
