import styled, { css } from 'styled-components';

const fixedStyle = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const SWrapper = styled.div`
  ${fixedStyle};
  vertical-align: middle;
  text-align: center;
  background: rgba(var(--dark), 0.5);
  white-space: nowrap;
  overflow-y: auto;
  user-select: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
    width: 0;
    margin-left: -5px;
    pointer-events: none;
  }
`;

export const SOverlay = styled.div`
  ${fixedStyle};
  z-index: 10;
`;

export const SModal = styled.div`
  position: relative;
  vertical-align: middle;
  display: inline-block;
  max-width: 440px;
  width: calc(100% - 40px);
  white-space: normal;
  z-index: 20;
`;

export const SInner = styled.div`
  background: rgba(var(--lvl-2), 1);
  margin: 20px 0;
  padding: 20px 20px 10px;
  border-radius: 8px;
  border: 1px solid rgba(var(--white), 0.05);
  text-align: center;
  box-shadow: 0 5px 20px rgba(var(--lvl-0), 1);
`;

export const SHeader = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: rgba(var(--txt-h2), 1);
  line-height: 28px;
  margin: 0 0 20px;
`;

export const SMessage = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: rgba(var(--txt-normal), 1);
  margin: 0 0 20px;
`;

export const SButtonWrapper = styled.div`
  display: flex;
  position: sticky;
  line-height: 1.48;
  gap: 5px;
  bottom: 0;
  padding: 15px 0;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(var(--lvl-2), 1);
`;

export const SButton = styled.button<{ mode: null | 'inverse' }>`
  padding: 10px 15px;
  border: none;
  color: rgba(var(--txt-h2-dark), 1);
  background-color: rgba(var(--primary-1), 1);
  box-shadow: 0 2px 5px rgba(var(--shdw-primary-1));
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  ${({ mode }) =>
    mode === 'inverse' &&
    css`
      background-color: rgba(var(--white), 1);
      color: rgba(var(--txt-h2-dark), 1);
    `}
`;

export const SButtonText = styled.span``;
