import { useEffect } from 'react';

export const useClickOutside = (
  elRef: { current: HTMLElement | null },
  callback: () => void,
) => {
  const handleClick = (event: MouseEvent) => {
    if (
      event &&
      event.target &&
      elRef.current &&
      !elRef.current.contains(event.target as HTMLDocument)
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  }, []);
};
