import styled from 'styled-components';

export const SWrapper = styled.div`
  height: 60px;
  width: 100%;
  border-radius: 6px 6px 0 0;
  position: relative;
`;

export const SContent = styled.div`
  position: absolute;
  z-index: var(--z-index-50);
  width: 100%;
  height: 100%;
  padding-top: 5px;
  border-radius: 6px 6px 0 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
`;

export const SContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 18px 0 20px;

  @media (max-width: 991.98px) {
    padding: 0 10px;
  }

  @media (max-width: 415px) {
    border-radius: 0;
  }
`;

export const SBalance = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const STitle = styled.span`
  color: rgba(var(--txt-white), 0.5);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const STotalBalance = styled.span`
  color: rgba(var(--txt-white), 1);
  font-size: 17px;
  font-weight: 500;
  min-height: 26px;
`;

export const SLogo = styled.img`
  display: block;
  width: 100%;
  max-height: var(--game-logo-max-height-d);
  object-fit: contain;
  left: 0;
  top: 15px;
  position: absolute;
  z-index: var(--z-index-45);

  @media (max-width: 990px) {
    max-height: var(--game-logo-max-height-990);
  }

  @media (max-width: 720px) {
    max-height: var(--game-logo-max-height-720);
  }

  @media (max-height: 570px) {
    max-height: var(--game-logo-max-height-570);
  }
`;

export const SActions = styled.div`
  display: flex;
  max-width: 63px;
  width: 100%;
  justify-content: space-between;
`;

export const SButton = styled.button`
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;

  [data-icon] {
    color: rgba(var(--white), 1);
  }
`;
