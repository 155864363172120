import { ReactNode } from 'react';
import styled from 'styled-components';

import Tournaments from 'components/Tournaments';
import LeaderBoard from 'components/LeaderBoard';
import Bets from 'components/Bets';
import MyBets from 'components/MyBets';
import Profile from 'components/Profile';

import {
  GAME,
  BETS,
  MY_BETS,
  CHAT,
  LEADER_BOARD,
  TOURNAMENTS,
  PROFILE,
  BETS_MOBILE,
} from 'constants/nameTabs';

type TTabs = () => {
  name:
    | 'leaderBoard'
    | 'bets'
    | 'betsMobile'
    | 'myBets'
    | 'game'
    | 'chat'
    | 'profile'
    | 'tournaments';
  component: ReactNode;
}[];

const SChat = styled.div`
  @media (min-width: 721px) {
    display: none;
  }
`;

const tabs: TTabs = () => [
  { name: LEADER_BOARD, component: <LeaderBoard /> },
  { name: BETS, component: <Bets /> },
  { name: BETS_MOBILE, component: <Bets /> },
  { name: MY_BETS, component: <MyBets /> },
  { name: GAME, component: <Bets /> },
  {
    name: CHAT,
    component: <SChat />,
  },
  { name: PROFILE, component: <Profile /> },
  { name: TOURNAMENTS, component: <Tournaments /> },
];

export default tabs;
