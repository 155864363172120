import { useEffect, useRef, FC } from 'react';

import gameManager from 'api/gameManager/GameManager';

import { SCanvasWrapper } from './style';

const CanvasWrapper: FC = () => {
  const gameWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gameManager.attach(gameWrapperRef.current);

    return () => {
      gameManager.detach();
    };
  }, []);

  return <SCanvasWrapper ref={gameWrapperRef} />;
};

export default CanvasWrapper;
