import i18n, { DEFAULT_LANG, DEFAULT_NS } from 'i18n/config';

import infoImg1 from 'assests/img/img_about1@2x.min.jpg';
import infoImg2 from 'assests/img/img_about2@2x.min.jpg';
import infoImg3 from 'assests/img/img_about3@2x.min.jpg';
import infoImg4 from 'assests/img/img_about4@2x.min.jpg';
import infoImg5 from 'assests/img/img_about5@2x.min.jpg';

const imgs = { infoImg1, infoImg2, infoImg3, infoImg4, infoImg5 };
const defaultTrans = i18n.getResourceBundle(DEFAULT_LANG, DEFAULT_NS);
Object.entries(imgs).forEach(([key, img]) => {
  if (!defaultTrans[img])
    i18n.addResources(DEFAULT_LANG, DEFAULT_NS, { [key]: img });
});

type TData = {
  id: number;
  subTitle: string;
  pathImg?: string;
  isFullWidth?: boolean;
  text: string;
  dcb?: boolean;
};

type TInfoDataItem = {
  id: number;
  title: string;
  infoText: string;
  lastTitle?: string;
  content?: TData[];
  checkValue?: string;
};

const Content: TData[] = [
  {
    id: 30,
    subTitle: 'infoC1',
    pathImg: 'infoImg1',
    isFullWidth: true,
    text: 'infoC1D1',
  },
  {
    id: 31,
    subTitle: 'infoC2',
    pathImg: 'infoImg2',
    isFullWidth: true,
    text: 'infoC2D2',
  },
  {
    id: 32,
    subTitle: 'infoC3',
    pathImg: 'infoImg3',
    isFullWidth: true,
    text: 'infoC3D3',
  },
  {
    id: 33,
    subTitle: 'infoC4',
    pathImg: 'infoImg4',
    isFullWidth: true,
    text: 'infoC4D4',
  },
  {
    id: 34,
    subTitle: 'infoC5',
    pathImg: 'infoImg5',
    isFullWidth: true,
    text: 'infoC5D5',
    dcb: false,
  },
];

const Content2: TData[] = [
  {
    id: 70,
    subTitle: 'infoC71',
    text: 'infoC7D1',
  },
  {
    id: 71,
    subTitle: 'infoC72',
    text: 'infoC7D2',
  },
  {
    id: 72,
    subTitle: '',
    text: 'infoC73',
  },
  {
    id: 73,
    subTitle: '',
    text: 'infoC74',
  },
];

export const infoData: TInfoDataItem[] = [
  {
    id: 1,
    title: 'infoT1',
    infoText: 'infoD1',
  },
  {
    id: 2,
    title: 'infoT5',
    content: Content,
    infoText: '',
  },
  {
    id: 3,
    title: 'infoT2',
    infoText: 'infoD2',
  },
  {
    id: 4,
    title: 'infoT3',
    infoText: 'infoD3',
  },
  {
    id: 5,
    title: 'infoT4',
    infoText: 'infoD4',
  },
  {
    id: 6,
    title: 'infoT6',
    infoText: 'infoD6',
  },
  {
    id: 7,
    title: 'infoT7',
    content: Content2,
    infoText: 'infoD7',
  },
  {
    id: 8,
    title: 'infoT8',
    infoText: 'infoD8',
  },
  {
    id: 9,
    title: 'infoT9',
    infoText: 'infoD9',
    checkValue: 'autoBet',
  },
  {
    id: 10,
    title: 'infoT10',
    infoText: 'infoD10',
    checkValue: 'autoCollect',
  },
  {
    id: 11,
    title: 'infoT11',
    infoText: 'infoD11',
  },
  {
    id: 12,
    title: 'infoT12',
    infoText: 'infoD12',
  },
  {
    id: 13,
    title: 'infoT13',
    infoText: 'infoD13',
  },
  {
    id: 14,
    title: '',
    lastTitle: 'infoD14',
    infoText: '',
  },
  {
    id: 15,
    title: '',
    lastTitle: '',
    infoText: 'infoD15',
  },
  {
    id: 16,
    title: '',
    lastTitle: 'version',
    infoText: '',
  },
];
