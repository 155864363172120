export const GAME_BUTTON_MAKE_BET = 1;
export const GAME_BUTTON_CANCEL = 2;
export const GAME_BUTTON_TAKE = 3;

export const DEFAULT_GAME_CONTROLLER_OPTIONS: TGameControllerOptions = {
  minValue: 0,
  maxValue: 0,
  step: 1,
  currentValue: 0,
  symbol: '',
  toFixed: 0,
  isHidden: false,
};

export enum GameButton {
  BUTTON_1 = 1,
  BUTTON_2 = 2,
}

export type TBetButtonState = {
  state: 1 | 2 | 3;
  enabled: boolean;
};

export type TGameControllerOptions = {
  minValue?: number;
  maxValue?: number;
  availableBets?: number[];
  step?: number;
  currentValue: number;
  symbol: string;
  toFixed: number;
  isHidden: boolean;
};
