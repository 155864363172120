import styled from 'styled-components';

import hotDropBgSrc from 'assests/img/hotDrop/HotDropBg.min.png';
import buttonNormalSrc from 'assests/img/hotDrop/ButtonNormal.min.png';
import buttonHoverSrc from 'assests/img/hotDrop/ButtonHover.min.png';
import buttonPressedSrc from 'assests/img/hotDrop/ButtonPressed.min.png';
import buttonDisabledSrc from 'assests/img/hotDrop/ButtonDisabled.min.png';
import infoNormalSrc from 'assests/img/hotDrop/InfoButtonNormal.min.png';
import infoHoverSrc from 'assests/img/hotDrop/InfoButtonHover.min.png';
import infoPressedSrc from 'assests/img/hotDrop/InfoButtonPressed.min.png';
import infoDisabledSrc from 'assests/img/hotDrop/InfoButtonDisabled.min.png';

export const Wrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 33%;
  display: block;
  position: relative;
  background-position: center;
  background-image: url('${hotDropBgSrc}');
  background-size: contain;
  background-repeat: no-repeat;
`;

export const SButton = styled.span`
  width: 35%;
  height: 35%;
  display: block;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background-position: center;
  background-image: url('${buttonNormalSrc}');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  :hover {
    background-image: url('${buttonHoverSrc}');
  }

  :active {
    background-image: url('${buttonPressedSrc}');
  }

  :disabled {
    background-image: url('${buttonDisabledSrc}');
  }
`;

export const SInfo = styled.span`
  width: 10%;
  height: 27%;
  display: block;
  bottom: 6%;
  right: 2%;
  position: absolute;
  background-position: center;
  background-image: url('${infoNormalSrc}');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  :hover {
    background-image: url('${infoHoverSrc}');
  }

  :active {
    background-image: url('${infoPressedSrc}');
  }

  :disabled {
    background-image: url('${infoDisabledSrc}');
  }
`;
