import { atom } from 'recoil';

import { CUPS_LIST } from 'store/constants';

//import { cupsList } from 'constants/cupsList';

import { ICupsListItem } from './types';

export const ACupsList = atom<ICupsListItem[]>({
  key: CUPS_LIST,
  //default: cupsList,
  default: [],
});
