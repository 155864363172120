import styled, { css } from 'styled-components';

import { TAlign, TColors } from './types';

export const SWrapper = styled.div<{ align: TAlign }>`
  margin-bottom: 6px;
  white-space: nowrap;
  text-align: ${({ align }) => align};
  width: 100%;
`;

export const SValue = styled.div<{ color: TColors }>`
  color: rgba(var(--grid-txt-normal), 1);
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  vertical-align: middle;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;

  ${({ color }) =>
    color === 'blue' &&
    css`
      color: rgba(var(--grid-txt-primary-1), 1);
    `}

  ${({ color }) =>
    color === 'green' &&
    css`
      color: rgba(var(--grid-txt-primary-3), 1);
    `}
`;

export const SLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(var(--grid-cap), 1);
  text-transform: uppercase;
`;
