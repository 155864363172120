import React, { FC, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  AMessages,
  AIsFullScreen,
  AIsOpenChat,
  AScrollHolder,
} from 'store/chat';
import { AInitialData } from 'store/init';
import { AScreenHeight } from 'store/screenHeight';
import { appendToast, AToastsList } from 'store/toasts/toastsList';

import notificationManager from 'api/notificationManager/NotificationManager';

import {
  MESSAGE_CONTENT_TYPE_STICKER,
  MESSAGE_CONTENT_TYPE_TEXT,
  MESSAGE_TARGET_TYPE_ALL,
  MESSAGE_TARGET_TYPE_PRIVATE,
} from './constants/receiveMessages';
import { useTranslationsManager } from './helpers/translationsManager';
import { getChatUserData } from './helpers/getChatUserData';
import ChatLayout from './layouts/ChatLayout';
import { Wrapper, Container } from './style';
import ChatRoom from './components/ChatRoom';
import ChatButton from './components/ChatButton';
import {
  TMessage,
  TReportBody,
  TSendMessageArguments,
} from './components/ChatRoom/types';
import { TDict, TInitData, TMessageBody } from './types';

const Chat: FC = () => {
  const { t } = useTranslation();
  const [isChatOpen, setIsChatOpen] = useRecoilState<boolean>(AIsOpenChat);
  const [messages, setMessages] = useRecoilState<TMessage[]>(AMessages);
  const [initData, setInitData] = useRecoilState<TInitData>(AInitialData);
  const [isFullScreen, setIsFullScreen] =
    useRecoilState<boolean>(AIsFullScreen);
  const scrollHolder = useRecoilValue(AScrollHolder);
  const screenHeight = useRecoilValue(AScreenHeight);
  const setToasts = useSetRecoilState(AToastsList);
  const nextMessageTimeRef = useRef(0);

  const { setTranslations } = useTranslationsManager();
  const syncTime = notificationManager.time;

  useEffect(() => {
    setTranslations(initData.dict as TDict);
    nextMessageTimeRef.current = initData.chat.ncmt;
  }, [initData]);

  const handleSendMessage = async (value: TSendMessageArguments) => {
    scrollHolder.scrollBottom(false);

    const { text, recipient, stickerValue } = value;

    const messageBody: TMessageBody = {
      tt: recipient ? MESSAGE_TARGET_TYPE_PRIVATE : MESSAGE_TARGET_TYPE_ALL,
      ct: stickerValue
        ? MESSAGE_CONTENT_TYPE_STICKER
        : MESSAGE_CONTENT_TYPE_TEXT,
      t: text,
      s_id: stickerValue,
      g_id: 0,
      to: recipient && recipient,
    };

    const res = await notificationManager.sendMessage(messageBody);
    if (!res || !res.result || !res.ncmt || typeof res.ncmt !== 'number') {
      return;
    }

    setInitData((prevState) => {
      if (!prevState.chat.t || prevState.chat.t <= res.t) {
        const newState = cloneDeep(prevState);
        newState.chat.ncmt = res.ncmt;
        newState.chat.t = res.t;
        nextMessageTimeRef.current = res.ncmt;
        return newState;
      }

      return prevState;
    });
  };

  const handleReportMessage = async (
    messageID: number,
    reportBody: TReportBody,
  ) => {
    const res = await notificationManager.reportMessage(reportBody);
    if (!res || !res.result) {
      return;
    }

    setToasts((toasts) =>
      appendToast(toasts, {
        text: t('messageReported'),
        icon: 'ic_report',
        type: 'default',
        duration: 1000,
      }),
    );
    setMessages((prevState) => prevState.filter((el) => el.id !== messageID));
  };

  const {
    accountName,
    language,
    st,
    minMessageLength,
    maxMessageLength,
    stickersMap,
  } = getChatUserData(initData as TInitData);

  // Controls handlers
  const toggleVisibleChat = () => setIsChatOpen(!isChatOpen);
  const toggleExpandChat = () => setIsFullScreen(!isFullScreen);

  return (
    <>
      {isChatOpen ? (
        <Wrapper isFullScreen={isFullScreen} screenHeight={screenHeight}>
          <Container>
            <ChatLayout
              toggleVisibleChat={toggleVisibleChat}
              toggleExpandChat={toggleExpandChat}
              isFullScreen={isFullScreen}
            >
              <ChatRoom
                messages={messages}
                language={language}
                handleSendMessage={handleSendMessage}
                handleReportMessage={handleReportMessage}
                accountName={accountName}
                stickers={st}
                nextMessageTimeRef={nextMessageTimeRef}
                minMessageLength={minMessageLength}
                maxMessageLength={maxMessageLength}
                stickersMap={stickersMap}
                syncTime={syncTime}
              />
            </ChatLayout>
          </Container>
        </Wrapper>
      ) : (
        <ChatButton toggleVisibleChat={toggleVisibleChat} />
      )}
    </>
  );
};

export default Chat;
