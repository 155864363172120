import i18n from 'i18n/config';

export enum FilterType {
  HUGEWINS = 1,
  BIGWINS = 2,
  COEFFICIENTS = 3,
}

export enum FilterPeriod {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
}

export const typeOptions = [
  {
    value: FilterType.BIGWINS,
    label: i18n.t('bigWins'),
  },
  {
    value: FilterType.HUGEWINS,
    label: i18n.t('hugeWins'),
  },
  {
    value: FilterType.COEFFICIENTS,
    label: i18n.t('coefficients'),
  },
];

export const periodOptions = [
  {
    value: FilterPeriod.DAY,
    label: i18n.t('day'),
  },
  {
    value: FilterPeriod.WEEK,
    label: i18n.t('week'),
  },
  {
    value: FilterPeriod.MONTH,
    label: i18n.t('month'),
  },
  {
    value: FilterPeriod.YEAR,
    label: i18n.t('year'),
  },
];

export interface ICoefficientItemType {
  id: number;
  date: number;
  coefficient: number;
  groupType: number;
}

export const mapToCoefficientItem: {
  [key: string]: string;
} = {
  id: 'id',
  d: 'date',
  c: 'coefficient',
  gt: 'groupType',
};

export interface IWinItemType {
  id: number;
  date: number;
  betAmount: number;
  cashedOutMultiplier: number;
  winAmount: number;
  roundMultiplier: number;
  typeOfRating: number;
  groupType: number;
  casinoCode: string;
  userAka: string;
}

export const mapToWinItem: {
  [key: string]: string;
} = {
  id: 'id',
  d: 'date',
  ba: 'betAmount',
  com: 'cashedOutMultiplier',
  wa: 'winAmount',
  rm: 'roundMultiplier',
  tor: 'typeOfRating',
  gt: 'groupType',
  cc: 'casinoCode',
  ua: 'userAka',
};
