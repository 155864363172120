import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'shared/components/Select';

import { typeOptions, periodOptions } from 'constants/leaderBoard';

import { SWrapper } from './style';
import { IFiltersProps } from './types';

const Filters: FC<IFiltersProps> = ({ items, onChange }) => {
  const { t } = useTranslation();
  const handleChange = (key: string) => (val: string | number) => {
    onChange({
      ...items,
      [key]: val as number,
    });
  };

  return (
    <SWrapper>
      <Select
        label={t('selectType')}
        value={items.type}
        options={typeOptions}
        onChange={handleChange('type')}
      />

      <Select
        label={t('selectPeriod')}
        value={items.period}
        options={periodOptions}
        onChange={handleChange('period')}
      />
    </SWrapper>
  );
};

export default Filters;
