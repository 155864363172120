import styled from 'styled-components';

export const SWrapper = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: relative;

  .no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .no-scrollbars {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }
  .no-scrollbars::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 720px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`;

export const STabs = styled.div`
  height: 50px;
  flex: 0 0 50px;
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(var(--lvl-0), 1);
  position: relative;
  z-index: var(--z-index-15);

  @media (max-width: 720px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    top: auto;
  }

  @media (max-width: 720px) {
    height: 35px;
    flex: 0 0 35px;
  }
`;

export const STab = styled.button<{
  isActive?: boolean;
  hide?: boolean;
  desktopOnly?: boolean;
  highlight?: boolean;
}>`
  height: 100%;
  margin: 0 11px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  color: ${({ isActive }) =>
    isActive
      ? 'rgba(var(--tab-txt-active), 1)'
      : 'rgba(var(--tab-txt-inactive), 0.3)'};

  ${({ isActive, highlight }) =>
    !isActive &&
    highlight &&
    'color:rgba(var(--tab-txt-active), 1); animation: 3s highlight infinite ease-in-out'};

  padding: 0 5px;
  display: flex;
  align-items: center;
  position: relative;
  transition: color 0.3s ease-out;
  background-color: rgba(var(--lvl-0), 1);
  border: none;
  white-space: nowrap;
  text-transform: uppercase;

  [data-icon] {
    margin-right: 5px;
    svg {
      width: 20px;
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 3px;
    border-radius: 0 0 3px 3px;
    background: rgba(var(--tab-active), 1);
    display: block;
    position: absolute;
    z-index: var(--z-index-50);
    top: 0;
    right: 0;
    transition: all 0.2s;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }

  @media (min-width: 721px) {
    ${({ hide }) => hide && 'display: none'};
  }

  @media (max-width: 720px) {
    ${({ desktopOnly }) => desktopOnly && 'display: none'};
  }
`;

export const STabsContent = styled.div`
  position: relative;
  margin: 8px 6px;
  height: calc(100% - 66px);

  @media (max-width: 991.98px) {
    height: calc(100% - 50px);
    margin: 0 10px;
  }

  @media (max-width: 720px) {
    height: 100%;
    margin: 0 6px;
  }
`;

export const SIconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;
