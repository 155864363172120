import React, { useState, FC } from 'react';

import Icon from '../Icon';

import { ISelectProps } from './types';
import {
  SWrapper,
  SLabel,
  SInner,
  SSelected,
  SList,
  SItem,
  Sbutton,
  SCover,
} from './style';

const Select: FC<ISelectProps> = ({ label, value, options = [], onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (val: string | number) => {
    setIsOpen(false);
    onChange(val);
  };

  const selected = options.find(({ value: val }) => val === value);

  return (
    <SWrapper data-is-open={isOpen}>
      <SLabel>{label}</SLabel>
      <SInner>
        <SSelected onClick={() => setIsOpen(true)}>
          {selected && selected.label}
        </SSelected>

        {isOpen && (
          <>
            <SList>
              {options.map(({ label, value: val }) => (
                <SItem data-is-active={val === value} key={val}>
                  <Sbutton type="button" onClick={() => handleChange(val)}>
                    {label}
                  </Sbutton>
                </SItem>
              ))}
            </SList>
            <SCover onClick={() => setIsOpen(false)} />
          </>
        )}

        <Icon name="ic_arrow_down" />
      </SInner>
    </SWrapper>
  );
};

export default Select;
