import {
  Colors,
  COLOR_RANGE_1,
  COLOR_RANGE_2,
  COLOR_RANGE_3,
  COLOR_RANGE_4,
} from 'constants/results';

export const getResultsColor = (value: number) => {
  if (value < COLOR_RANGE_1) {
    return Colors.FIRST_COLOR;
  }
  if (value < COLOR_RANGE_2) {
    return Colors.SECOND_COLOR;
  }

  if (value < COLOR_RANGE_3) {
    return Colors.THIRD_COLOR;
  }

  if (value < COLOR_RANGE_4) {
    return Colors.FOURTH_COLOR;
  }

  return Colors.DEFAULT_COLOR;
};
