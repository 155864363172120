import { ReactNode } from 'react';

import Icon from 'shared/components/Icon';

export type TAutoOption = {
  title: string | ReactNode;
  value: number;
};

export const autoOptions: TAutoOption[] = [
  { title: 'OFF', value: 0 },
  { title: '5', value: 5 },
  { title: '10', value: 10 },
  { title: '20', value: 20 },
  { title: '50', value: 50 },
  { title: '100', value: 100 },
  { title: '500', value: 500 },
  { title: '1000', value: 1000 },
  { title: <Icon name="ic_infinitysign" />, value: -1 },
];
