import { FC, memo } from 'react';
import { areEqual } from 'react-window';

import BetsItem from '../BetsItem';

import { TRowProps } from './types';

const Row: FC<TRowProps> = memo(({ data, index, style }) => {
  return <BetsItem data={data[index]} style={style} />;
}, areEqual);

export default Row;
