import styled from 'styled-components';

export const TimerWrapper = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: rgba(var(--primary-1), 0.5);
  padding-right: 6px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const TimerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: rgba(var(--primary-1), 0.2);

  [data-icon] {
    height: 24px;
    color: rgba(var(--white), 1);
  }
`;

export const InfinityIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;

  [data-icon] {
    color: rgba(var(--white), 1);
  }
  > span {
    height: 14px;
  }
`;
