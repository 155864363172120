import notificationManager from 'api/notificationManager/NotificationManager';

import { getUrlParams } from 'shared/helpers/parseUrl';
import { convertCurrencyHexToChar } from 'shared/helpers/currencyConverter';

import {
  GameButton,
  TBetButtonState,
  GAME_BUTTON_MAKE_BET,
  GAME_BUTTON_CANCEL,
  GAME_BUTTON_TAKE,
} from 'constants/gameButtons';
import { TBtsItem } from 'constants/betsList';

export interface IGameManagerListener {
  onBetsUpdate(arr: TBtsItem[]): void;
  onNewMultiplier(arr: number[], clear: boolean): void;
  onReady(): void;
  onBalance(balance: string): void;
  onInit(data: any): void;
  onUpdateButtonState(button1: TBetButtonState, button2: TBetButtonState): void;
  onWin(value: number, currency: string): void;
  onBetAmountChanged(button: GameButton, value: number): void;
  onCollectAmountChanged(button: GameButton, value: number): void;
  onUpdateAutoBet(button: GameButton, value: number): void;
  onUpdateAutoCollect(button: GameButton, value: number): void;
  onTakeWinValueChanged(
    button: GameButton,
    value: number,
    currency: string,
    toFixed: number,
    freeBets: number,
  ): void;
  onFreeBet(d: any): void;
  onAppendToBetsHistory(arr: any[]): void;
  onErrorMessage(errorData: any): void;
  onShowInfo(show: boolean): void;
  onSoundUpdated(on: boolean): void;
  onEnableUI(enalbe: boolean): void;
  onProgress(value: number): void;
  onInternalChatMsg(data: any): void;
}

export interface IGameManager {
  attach(newParent: HTMLDivElement | null): void;
  detach(): void;
  subscribeListener(listener: IGameManagerListener): void;
  unsubscribeListener(listener: IGameManagerListener): void;
  changeSound(on: boolean): void;
  gameButtonClick(button: GameButton): void;
  setBetAmount(button: GameButton, value: number, mute: boolean): void;
  setCollectAmount(button: GameButton, value: number, mute: boolean): void;
  setAutoBet(button: GameButton, value: number): void;
  setAutoCollect(button: GameButton, value: number): void;
  getGameUrl(): string | null | undefined;
  terminateSession(): void;
  navigateToHistoryURL(): void;
  navigateToLobbyURL(): void;
  getLurl(): string;
  getHurl(): string;
  showLobbyBtn(): boolean;
  cancelButtonDisabled(): boolean;
  processServiceMessageLink(linkType: number, content: string): void;
}

const gameURL = getUrlParams()?.game_url;
let loadScriptCalled = false;

const cancel1_BUTTON = 'cancel1_BUTTON';
const makeBet1_BUTTON = 'makeBet1_BUTTON';
const takeWin1 = 'takeWin1';
const cancel2_BUTTON = 'cancel2_BUTTON';
const makeBet2_BUTTON = 'makeBet2_BUTTON';
const takeWin2 = 'takeWin2';
const soundState = 'sounds_STATE';

const loadScript = (gameURL: string) => {
  if (loadScriptCalled) {
    return;
  }
  loadScriptCalled = true;
  const script = document.createElement('script');
  script.src = gameURL || '';
  script.async = false;
  document.head.appendChild(script);
};

const addDocumentListener = (eventName: string, listener: (d: any) => void) => {
  const eventListener = (event: Event) => {
    try {
      const ev = event as CustomEvent;
      //console.log('DOCUMENT EVENT', eventName, ev.detail);
      listener(ev.detail);
    } catch (e) {
      console.log(e);
    }
  };

  document.addEventListener(eventName, eventListener);
};

const createGameManager = (): IGameManager => {
  let listeners: IGameManagerListener[] = [];
  let parent: HTMLDivElement | null = null;
  const gameCanvas = document.createElement('canvas');
  gameCanvas.id = 'gameCanvas';
  gameCanvas.classList.add('gameCanvasStyle');

  const defaultToFixed = 2;
  let currency: string = '';
  let initData: any = null;
  let casinoToken: string | null | undefined = null;
  let dcb: boolean | false | undefined = false;
  let aka = '';
  let toFixed = defaultToFixed;
  let betsMap: { [key: number]: TBtsItem } = {};
  let isShowLobbyBtn = false;
  //take win
  let takeWinValue1 = 0;
  let takeWinValue2 = 0;
  let takeWinFreebets1 = 0;
  let takeWinFreebets2 = 0;

  let gameButtonState: { [key: number]: TBetButtonState } = {
    1: { state: GAME_BUTTON_MAKE_BET, enabled: false },
    2: { state: GAME_BUTTON_MAKE_BET, enabled: false },
  };

  let freeBet: any = null;

  const updateGameButtonState = (name: any, v: any): boolean => {
    if (typeof name !== 'string' || !v) {
      return false;
    }

    switch (name) {
      case cancel1_BUTTON:
        gameButtonState[GameButton.BUTTON_1] = {
          state: GAME_BUTTON_CANCEL,
          enabled: !!v.enable,
        };
        return true;
      case makeBet1_BUTTON:
        gameButtonState[GameButton.BUTTON_1] = {
          state: GAME_BUTTON_MAKE_BET,
          enabled: !!v.enable,
        };
        return true;
      case takeWin1:
        gameButtonState[GameButton.BUTTON_1] = {
          state: GAME_BUTTON_TAKE,
          enabled: !!v.enable,
        };
        return true;
      case cancel2_BUTTON:
        gameButtonState[GameButton.BUTTON_2] = {
          state: GAME_BUTTON_CANCEL,
          enabled: !!v.enable,
        };
        return true;
      case makeBet2_BUTTON:
        gameButtonState[GameButton.BUTTON_2] = {
          state: GAME_BUTTON_MAKE_BET,
          enabled: !!v.enable,
        };
        return true;
      case takeWin2:
        gameButtonState[GameButton.BUTTON_2] = {
          state: GAME_BUTTON_TAKE,
          enabled: !!v.enable,
        };
        return true;
    }

    return false;
  };

  const notifySoundChanged = (on: boolean) => {
    listeners.forEach((l) => l.onSoundUpdated(on));
  };

  const updateButtons = (d: any) => {
    if (!d || !(typeof d === 'object')) {
      return;
    }

    let updated = false;

    Object.entries(d).forEach(([k, v]: any) => {
      if (k === soundState) {
        notifySoundChanged(!!v.state);
        return;
      }

      if (v?.visible) {
        if (updateGameButtonState(k, v)) {
          updated = true;
        }
      }
    });

    if (updated) {
      notifyButtonsStateUpdated();
    }
  };

  const changeSound = (on: boolean) => {
    if (!initData) {
      return;
    }

    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: 'sounds_STATE', value: on },
      }),
    );
  };

  const setBetAmount = (
    button: GameButton,
    value: number,
    mute: boolean = false,
  ) => {
    const name = button === GameButton.BUTTON_1 ? 'setBet1' : 'setBet2';

    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: name, value: value, mute: mute },
      }),
    );
  };

  const setCollectAmount = (
    button: GameButton,
    value: number,
    mute: boolean = false,
  ) => {
    const name = button === GameButton.BUTTON_1 ? 'setCollect1' : 'setCollect2';

    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: name, value: value, mute: mute },
      }),
    );
  };

  const setAutoCollect = (button: GameButton, value: number) => {
    const name =
      button === GameButton.BUTTON_1 ? 'setAutoCollect1' : 'setAutoCollect2';

    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: name, value: value },
      }),
    );
  };

  const setAutoBet = (button: GameButton, value: number) => {
    const name = button === GameButton.BUTTON_1 ? 'setAutoBet1' : 'setAutoBet2';

    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: name, value: value },
      }),
    );
  };

  const gameButtonClick = (button: GameButton) => {
    if (!gameButtonState.hasOwnProperty(button)) {
      return;
    }

    const gameBtn = gameButtonState[button];

    if (!gameBtn.enabled) {
      return;
    }

    let name = null;

    switch (gameBtn.state) {
      case GAME_BUTTON_MAKE_BET:
        name =
          button === GameButton.BUTTON_1
            ? 'makeBet1_BUTTON'
            : 'makeBet2_BUTTON';
        break;
      case GAME_BUTTON_CANCEL:
        name =
          button === GameButton.BUTTON_1 ? 'cancel1_BUTTON' : 'cancel2_BUTTON';
        break;
      case GAME_BUTTON_TAKE:
        name = button === GameButton.BUTTON_1 ? 'takeWin1' : 'takeWin2';
        break;
    }

    gameButtonState[button] = { state: gameBtn.state, enabled: false };
    document.dispatchEvent(new CustomEvent('tap', { detail: { name: name } }));
    notifyButtonsStateUpdated();
  };

  const getGameUrl = (): string | null | undefined => {
    return gameURL;
  };

  const terminateSession = (): void => {
    window.dispatchEvent(new Event('terminate'));
  };

  const navigateToHistoryURL = (): void => {
    if (window?.parent) {
      window.parent.open(
        initData?.hurl || window.location || '/',
        '_blank',
        'noopener,noreferrer',
      );
      return;
    }

    window?.open(
      initData?.hurl || window.location || '/',
      '_blank',
      'noopener,noreferrer',
    );
  };

  const navigateToLobbyURL = (): void => {
    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: 'home' },
      }),
    );
  };

  const getLurl = (): string => {
    return initData?.lurl || '';
  };

  const getHurl = (): string => {
    return initData?.hurl || '';
  };

  const showLobbyBtn = (): boolean => {
    return isShowLobbyBtn;
  };

  const cancelButtonDisabled = (): boolean => {
    return dcb || false;
  }

  const processServiceMessageLink = (linkType: number, content: string) => {
    document.dispatchEvent(
      new CustomEvent('tap', {
        detail: { name: 'serviceMessage', value: { linkType, content } },
      }),
    );
  };

  const updateMyBets = async (
    betsMap: { [key: number]: TBtsItem },
    roundMultiplier: number,
    roundStartTime: number,
  ) => {
    const arr: any[] = [];

    for (const value of Object.values(betsMap)) {
      if (value?.my) {
        arr.push({
          i: value.i,
          rd: +roundMultiplier ? `×${roundMultiplier?.toFixed(2)}` : '',
          a: +value.a ? `${value.currency}${value.a?.toFixed(toFixed)}` : '',
          m: +value.m ? `×${value.m?.toFixed(2)}` : '',
          wa: +value.wa ? `${value.currency}${value.wa?.toFixed(toFixed)}` : '',
          bm: value.bm,
          bf: value.bf,
          rst: roundStartTime,
        });
      }
    }

    listeners.forEach((l) => l.onAppendToBetsHistory(arr));
  };

  const mergeBets = (bts: TBtsItem[]) => {
    if (!bts || !Array.isArray(bts)) {
      return;
    }

    bts.forEach((b) => {
      if (casinoToken && b?.ct !== casinoToken) {
        return;
      }

      const kInt = b.i;

      b.toFixedValue = toFixed;
      b.my = b.n === aka;
      b.currency = convertCurrencyHexToChar(b.c);

      if (!(kInt in betsMap)) {
        if (!b.r) {
          betsMap[kInt] = b;
        }
        return;
      }

      const prevV = betsMap[kInt];

      if (prevV.t > b.t) {
        return;
      }

      if (b.r) {
        delete betsMap[kInt];
        return;
      }

      betsMap[kInt] = b;
    });
  };

  const notifyBetsUpdated = () => {
    listeners.forEach((l) => l.onBetsUpdate(Object.values(betsMap)));
  };

  const notifyIsReady = () => {
    listeners.forEach((l) => l.onReady());
  };

  const notifyOnInit = () => {
    if (!initData) {
      return;
    }
    listeners.forEach((l) => l.onInit(initData));
  };

  const notifyError = (data: any) => {
    listeners.forEach((l) => l.onErrorMessage(data));
  };

  const notifyButtonsStateUpdated = () => {
    listeners.forEach((l) =>
      l.onUpdateButtonState(gameButtonState[1], gameButtonState[2]),
    );
  };

  const notifyTakeWinValueChanged = (
    button: number,
    value: number,
    freeBets: number,
  ) => {
    listeners.forEach((l) =>
      l.onTakeWinValueChanged(button, value, currency, toFixed, freeBets),
    );
  };

  const notifyShowInfo = (show: boolean) => {
    listeners.forEach((l) => l.onShowInfo(show));
  };

  addDocumentListener('init', (d) => {
    initData = d;
    isShowLobbyBtn =
      getUrlParams()?.home_button_disabled !== 'true' && d?.home?.visible;
    casinoToken = d?.ctoken;
    dcb = d?.dcb;
    toFixed = d?.toFixed || defaultToFixed;
    aka = d?.aka || '';
    currency = d?.currency || '';
    notificationManager.connect(d.notificationUrl, d.notificationToken);
    notifyOnInit();
    const bhl = d.b_h?.length;
    const betsHistory =
      d.b_h?.map((item: any, i: number) => {
        return {
          i: i - bhl,
          rd: item?.round_multiplier?.toFixed(2) || '',
          a: +item?.amount ? `${currency}${item.amount.toFixed(toFixed)}` : '',
          m: +item?.multiplier ? `×${item.multiplier.toFixed(2)}` : '',
          wa: +item?.win_amount
            ? `${currency}${item.win_amount.toFixed(toFixed)}`
            : '',
          bm: item?.bonus_multiplier || 1,
          bf: item?.bonus_freebets || 0,
          rst: item?.round_start_time
            ? new Date(item?.round_start_time).valueOf()
            : 0,
        };
      }) || [];
    listeners.forEach((l) => l.onAppendToBetsHistory(betsHistory));
    document.dispatchEvent(new Event('UIIsReady'));
  });

  addDocumentListener('betsUpdates', (d) => {
    if (!d?.data?.bts) {
      return;
    }

    mergeBets(d.data.bts);
    notifyBetsUpdated();
  });

  addDocumentListener('setAutoCollectValue', (d) => {
    if (
      typeof d.value !== 'number' ||
      (d.side !== GameButton.BUTTON_1 && d.side !== GameButton.BUTTON_2)
    ) {
      return;
    }

    listeners.forEach((l) => l.onCollectAmountChanged(d.side, d.value));
  });
  addDocumentListener('setBet', (d) => {
    if (
      typeof d.value !== 'number' ||
      (d.side !== GameButton.BUTTON_1 && d.side !== GameButton.BUTTON_2)
    ) {
      return;
    }

    listeners.forEach((l) => l.onBetAmountChanged(d.side, d.value));
  });
  addDocumentListener('updateAutoBet1', (d) => {
    if (typeof d.value === 'number') {
      listeners.forEach((l) => l.onUpdateAutoBet(GameButton.BUTTON_1, d.value));
    }
  });
  addDocumentListener('updateAutoBet2', (d) => {
    if (typeof d.value === 'number') {
      listeners.forEach((l) => l.onUpdateAutoBet(GameButton.BUTTON_2, d.value));
    }
  });
  addDocumentListener('updateAutoCollect1', (d) => {
    if (typeof d.value === 'number') {
      listeners.forEach((l) =>
        l.onUpdateAutoCollect(GameButton.BUTTON_1, d.value),
      );
    }
  });
  addDocumentListener('updateAutoCollect2', (d) => {
    if (typeof d.value === 'number') {
      listeners.forEach((l) =>
        l.onUpdateAutoCollect(GameButton.BUTTON_2, d.value),
      );
    }
  });
  addDocumentListener('setBalance', (d) => {
    listeners.forEach((l) => l.onBalance(d.value || ''));
  });
  addDocumentListener('setButtonState', (d) => {
    updateButtons(d);
  });
  addDocumentListener('show', (d) => {
    window.dispatchEvent(new Event('resize'));
    notifyIsReady();
  });

  addDocumentListener('onWin', (d) => {
    let value: any = d?.value || '';

    if (!value) {
      return;
    }

    if (typeof value === 'number') {
      value = value.toFixed(toFixed);
    } else if (typeof value !== 'string') {
      return;
    }

    listeners.forEach((l) => l.onWin(value, currency));
  });

  addDocumentListener('roundEnd', (d) => {
    if (d?.finalMultiplier && typeof d?.finalMultiplier === 'number') {
      const arr = [d.finalMultiplier];
      listeners.forEach((l) => l.onNewMultiplier(arr, false));
    }

    updateMyBets(betsMap, d?.finalMultiplier || 0, d?.roundStartTime || 0);
    betsMap = {};
    notifyBetsUpdated();
  });

  addDocumentListener('multiplierHistory', (d) => {
    if (
      !d?.multiplierHistory?.d?.arr ||
      !Array.isArray(d.multiplierHistory?.d?.arr)
    ) {
      return;
    }

    listeners.forEach((l) =>
      l.onNewMultiplier(d.multiplierHistory.d.arr, true),
    );
  });

  addDocumentListener('takeWin1Update', (d) => {
    const value = d?.value || 0;
    const freebets = d?.freebets || 0;
    const changed = takeWinValue1 !== value || takeWinFreebets1 !== freebets;
    takeWinValue1 = value;
    takeWinFreebets1 = freebets;

    if (changed) {
      notifyTakeWinValueChanged(
        GameButton.BUTTON_1,
        takeWinValue1,
        takeWinFreebets1,
      );
    }
  });
  addDocumentListener('takeWin2Update', (d) => {
    const value = d?.value || 0;
    const freebets = d?.freebets || 0;
    const changed = takeWinValue2 !== value || takeWinFreebets2 !== freebets;
    takeWinValue2 = value;
    takeWinFreebets2 = freebets;

    if (changed) {
      notifyTakeWinValueChanged(
        GameButton.BUTTON_2,
        takeWinValue2,
        takeWinFreebets2,
      );
    }
  });

  addDocumentListener('errorMessage', (d) => {
    notifyError(d?.value || {});
  });

  addDocumentListener('freebets', (d) => {
    const fb = d?.value;

    if (
      freeBet?.updated_at > 0 &&
      fb?.updated_at > 0 &&
      fb.updated_at < freeBet.updated_at
    ) {
      return;
    }

    if (freeBet === null && fb === null) {
      return;
    }

    freeBet = fb;
    listeners.forEach((l) => l.onFreeBet(freeBet || {}));
  });

  addDocumentListener('showinfo', (d) => {
    notifyShowInfo(!!d?.value);
  });

  addDocumentListener('enableUI', (d) => {
    listeners.forEach((l) => l.onEnableUI(!!d.value));
  });

  addDocumentListener('onprogress', (d) => {
    listeners.forEach((l) => l.onProgress(d?.value || 0));
  });
  //Player state document event. Not used currently.
  //addDocumentListener('playersState', (d) => {});

  addDocumentListener('onInternalChatMsg', (d) => {
    listeners.forEach((l) => l.onInternalChatMsg(d));
  });

  const subscribeListener = (listener: IGameManagerListener) => {
    if (listeners.indexOf(listener) >= 0) {
      return;
    }

    listeners.push(listener);
    if (initData) {
      listener.onInit(initData);
    }
    if (freeBet) {
      listener.onFreeBet(freeBet);
    }
    listener.onUpdateButtonState(gameButtonState[1], gameButtonState[2]);
  };

  const unsubscribeListener = (listener: IGameManagerListener) => {
    listeners.filter((l) => l !== listener);
  };

  const attach = (newParent: HTMLDivElement | null) => {
    detach();
    parent = newParent;
    if (parent == null) {
      return;
    }

    parent.appendChild(gameCanvas);

    if (gameURL) {
      loadScript(gameURL);
    }

    window.dispatchEvent(new Event('resize'));
  };

  const detach = () => {
    if (parent != null) {
      parent.removeChild(gameCanvas);
    }
    parent = null;
  };

  return {
    attach,
    detach,
    subscribeListener,
    unsubscribeListener,
    changeSound,
    gameButtonClick,
    setBetAmount,
    setCollectAmount,
    setAutoBet,
    setAutoCollect,
    getGameUrl,
    terminateSession,
    navigateToHistoryURL,
    navigateToLobbyURL,
    getLurl,
    getHurl,
    showLobbyBtn,
    cancelButtonDisabled,
    processServiceMessageLink,
  };
};

const gameManager = createGameManager();
export default gameManager;
