import { useState, FC, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ATotalCounter } from 'store/totalCounter';
import { AModalSelector } from 'store/modal';
import { ABetButton1, ABetButton2 } from 'store/gameButtons';

import { useInterval } from 'shared/hooks/useInterval';

import { GAME_BUTTON_MAKE_BET } from 'constants/gameButtons';

import { IPlayingTimer } from './types';

const PlayingTimer: FC<IPlayingTimer> = ({ time }) => {
  const { t } = useTranslation();

  const { state: makeBtn1Value, enabled: enabledBet1 } =
    useRecoilValue(ABetButton1);
  const { state: makeBtn2Value, enabled: enabledBet2 } =
    useRecoilValue(ABetButton2);

  const setModal = useSetRecoilState(AModalSelector);
  const [{ isGameInited, startTime, bet, won, currency }] =
    useRecoilState(ATotalCounter);
  const [startTimeCounter, setStartTime] = useState<number>(dayjs().valueOf());

  useInterval(
    () => {
      const currentTime = dayjs().valueOf();
      const counter = (currentTime - startTimeCounter) / 1000;

      if (
        time <= counter &&
        enabledBet1 &&
        enabledBet2 &&
        makeBtn1Value === GAME_BUTTON_MAKE_BET &&
        makeBtn2Value === GAME_BUTTON_MAKE_BET
      ) {
        setModal({
          title: (
            <span>
              <span>
                {t('YouHaveBeenPlayingFor', {
                  time: `${dayjs
                    .duration(currentTime - startTime)
                    .format('HH:mm:ss')}s`,
                })}
              </span>
              <br />
              <span>
                {t('InTotalYouWon', {
                  currency,
                  bet: bet.toFixed(2),
                  won: won.toFixed(2),
                })}
              </span>
              <br />
              <span>{t('wouldYouLikeToKeepPlaying')}</span>
            </span>
          ),
          buttons: [
            {
              label: t('keepPlaying'),
              type: 'close',
              action: () => {
                setStartTime(dayjs().valueOf());
              },
            },
            {
              label: t('quitTheGame'),
              type: 'quit',
              action: () => {
                setStartTime(dayjs().valueOf());
              },
            },
          ],
        });
      }
    },
    isGameInited ? 1000 : null,
  );

  useEffect(() => {
    if (isGameInited) {
      setStartTime(dayjs().valueOf());
    }
  }, [isGameInited]);

  return null;
};

export default PlayingTimer;
