import styled from 'styled-components';

import { TSendBtnProps } from './types';

export const Wrapper = styled.div`
  position: relative;
  background-color: rgba(var(--lvl-2), 1);
  display: flex;
  width: 100%;
  border-radius: 0 0 4px 4px;
`;

export const EmojiBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 13px;
  top: 13px;
  cursor: pointer;

  [data-icon] {
    color: rgba(var(--primary-1), 1);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 4px;
`;

export const SendBtn = styled.button<TSendBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  position: absolute;
  background-color: rgba(var(--primary-1), 1);
  top: 8px;
  right: 4px;
  padding: 0;
  margin: 0;
  border: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};

  [data-icon] {
    color: rgba(var(--white), 1);
  }
`;

export const StyledInput = styled.input<{ isSendIcon?: boolean }>`
  width: ${({ isSendIcon }) => (isSendIcon ? '100%' : '80%')};
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 48px 16px 52px;
  color: rgba(var(--txt-input), 1);
  background-color: rgba(var(--lvl-2), 1);
  border: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: auto; /* Chrome/Safari/Opera */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto;

  box-shadow: none;
  :focus {
    outline: none;
  }
`;
