import {
  useState,
  FC,
  ChangeEvent,
  SyntheticEvent,
  KeyboardEvent,
  FocusEvent,
  useEffect,
} from 'react';

import { ProfilePopup } from 'components/Profile';
import { canSendMessage } from 'components/Chat/helpers/checkCanSend';
import { isMessageTextValid } from 'components/Chat/helpers/isMessageTextValid';
import { getRecipientsNameAndText } from 'components/Chat/helpers/getRecipientsNameAndText';
import InfoPopup from 'components/InfoPopup';

import { SendMessageForm, MessageList } from './components';
import { TChatRoomProps } from './types';

const ChatRoom: FC<TChatRoomProps> = ({
  messages,
  handleSendMessage,
  handleReportMessage,
  accountName,
  language,
  stickers,
  nextMessageTimeRef,
  minMessageLength,
  maxMessageLength,
  stickersMap,
  syncTime,
}) => {
  const [profilePopup, setProfilePopup] = useState<{
    name: string;
    avatar?: string;
    isYourProfile?: boolean;
  }>({
    name: '',
  });
  const [infoPopup, setInfoPopup] = useState<{ text: string; show: boolean }>({
    text: '',
    show: false,
  });
  const [newMessage, setNewMessage] = useState<string>('');
  const [isValidMessage, setIsValidMessage] = useState<boolean>(false);
  const [, setIsShowSendBtn] = useState<boolean>(
    canSendMessage(nextMessageTimeRef.current, syncTime),
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString();
    setNewMessage(
      value.length > maxMessageLength
        ? value.substring(0, maxMessageLength)
        : value,
    );
  };

  useEffect(() => {
    const { text } = getRecipientsNameAndText(newMessage);
    setIsValidMessage(
      isMessageTextValid(text, minMessageLength, maxMessageLength),
    );
  }, [newMessage]);

  const handleGetRecipient = (e: SyntheticEvent, recipient: string) => {
    e.preventDefault();
    setNewMessage(
      (prevState) =>
        `@${recipient} ${getRecipientsNameAndText(prevState).text}`,
    );
  };

  const sendTextMessage = () => {
    const { text, recipient } = getRecipientsNameAndText(newMessage);

    if (!isMessageTextValid(text, minMessageLength, maxMessageLength)) {
      setIsValidMessage(false);
      return;
    }

    setIsShowSendBtn(false);
    handleSendMessage({ recipient, text });
    setNewMessage('');
  };

  const handleSend = (e: SyntheticEvent, stickerValue?: number) => {
    e.preventDefault();

    if (!canSendMessage(nextMessageTimeRef.current, syncTime)) {
      return;
    }

    if (stickerValue) {
      setIsShowSendBtn(false);
      handleSendMessage({ stickerValue });
      return;
    }

    sendTextMessage();
  };

  const handleOnKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!canSendMessage(nextMessageTimeRef.current, syncTime)) {
        return;
      }

      sendTextMessage();
    }
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    value.length && setNewMessage(value.trim());
  };

  const handleProfilePopupClose = () => {
    setProfilePopup({
      name: '',
    });
  };

  const onShowProfile = ({
    userName,
    icon,
  }: {
    userName: string;
    icon?: string;
  }) => {
    setProfilePopup({
      name: userName,
      avatar: icon,
      isYourProfile: accountName === userName,
    });
  };

  const handleInfoPopupClose = () => {
    setInfoPopup({ text: '', show: false });
  };

  const onShowInfoPopup = ({ text }: { text: string }) => {
    setInfoPopup({ text: text, show: true });
  };

  return (
    <>
      <MessageList
        language={language}
        messages={messages}
        accountName={accountName}
        handleGetRecipient={handleGetRecipient}
        handleReportMessage={handleReportMessage}
        stickers={stickers}
        stickersMap={stickersMap}
        onShowProfile={onShowProfile}
        onShowInfoPopup={onShowInfoPopup}
      />

      <SendMessageForm
        handleSend={handleSend}
        newMessage={newMessage}
        onChange={onChange}
        handleOnKeydown={handleOnKeydown}
        stickers={stickers}
        nextMessageTimeRef={nextMessageTimeRef}
        handleOnBlur={handleOnBlur}
        isValidMessage={isValidMessage}
        syncTime={syncTime}
        setIsShowSendBtn={setIsShowSendBtn}
      />

      {profilePopup.name && (
        <ProfilePopup {...profilePopup} onClose={handleProfilePopupClose} />
      )}

      {infoPopup.show && (
        <InfoPopup {...infoPopup} onClose={handleInfoPopupClose} />
      )}
    </>
  );
};

export default ChatRoom;
