import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { AAutoButtons } from 'store/gameButtons/autoButtons';

import Icon from 'shared/components/Icon';

import { SButton, SSubTitle, STitle } from './style';
import { TAutoOffBtnProps } from './types';

const AutoBtn: FC<TAutoOffBtnProps> = ({
  borderRStyle,
  onClickHandler,
  btnType,
  groupBtnType: groupBtn,
  disabled,
}) => {
  const stateAutoBtns = useRecoilValue(AAutoButtons);
  const { t } = useTranslation();
  return (
    <>
      {stateAutoBtns.map(
        ({ groupBtnType, type, value }) =>
          groupBtnType === groupBtn &&
          type === btnType && (
            <SButton
              key={`${groupBtnType}${type}`}
              borderRStyle={borderRStyle}
              onClick={onClickHandler}
              autoOn={!!value}
              disabled={disabled}
            >
              <SSubTitle autoOn={!!value}>{t('auto')}</SSubTitle>
              <STitle className="no-scrollbars">
                {value === -1 ? (
                  <Icon name="ic_infinitysign" />
                ) : value && value > 0 ? (
                  value
                ) : (
                  t('off')
                )}
              </STitle>
            </SButton>
          ),
      )}
    </>
  );
};

export default AutoBtn;
