import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { SLockScreen, SImage, SText } from './style';

const LockScreen: FC = () => {
  const portalEL = document.getElementById('portal');
  const { t } = useTranslation();

  return portalEL
    ? createPortal(
        <SLockScreen>
          <SImage />
          <SText>{t('rotatePhone')}</SText>
        </SLockScreen>,
        portalEL,
      )
    : null;
};

export default LockScreen;
