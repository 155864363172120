import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 100px);
  transition: height 0.2s;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 50px 0;
  background-color: rgba(var(--lvl-0), 1);

  @media (max-width: 720px) {
    height: calc(100% - 50px);
    margin-top: 0;
  }
`;
