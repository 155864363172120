import { atom } from 'recoil';

import { AVAILABLE_CONTENT } from '../constants';

import { TSectionPermission } from './types';

export const AVAILABLE_CHAT = 'chat';

export const AAvailableContent = atom<TSectionPermission[]>({
  key: AVAILABLE_CONTENT,
  default: [],
});
