import { atom } from 'recoil';

import {
  BET_BUTTON_1,
  BET_BUTTON_2,
  TAKE_WIN_1,
  TAKE_WIN_2,
  CONTROLLER_BET_1,
  CONTROLLER_BET_2,
  CONTROLLER_COLLECT_1,
  CONTROLLER_COLLECT_2,
} from 'store/constants';

import {
  TBetButtonState,
  TGameControllerOptions,
  GAME_BUTTON_MAKE_BET,
  DEFAULT_GAME_CONTROLLER_OPTIONS,
} from 'constants/gameButtons';

export type TButtonsStatuses = 1 | 2 | 3;

export const ABetButton1 = atom<TBetButtonState>({
  key: BET_BUTTON_1,
  default: { state: GAME_BUTTON_MAKE_BET, enabled: false },
});

export const ABetButton2 = atom<TBetButtonState>({
  key: BET_BUTTON_2,
  default: { state: GAME_BUTTON_MAKE_BET, enabled: false },
});

export const ATakeWin1 = atom<{
  win: string;
  freeBets?: number;
}>({
  key: TAKE_WIN_1,
  default: {
    win: '',
    freeBets: 0,
  },
});

export const ATakeWin2 = atom<{
  win: string;
  freeBets?: number;
}>({
  key: TAKE_WIN_2,
  default: {
    win: '',
    freeBets: 0,
  },
});

export const AControllerBet1 = atom<TGameControllerOptions>({
  key: CONTROLLER_BET_1,
  default: DEFAULT_GAME_CONTROLLER_OPTIONS,
});

export const AControllerBet2 = atom<TGameControllerOptions>({
  key: CONTROLLER_BET_2,
  default: DEFAULT_GAME_CONTROLLER_OPTIONS,
});

export const AControllerCollect1 = atom<TGameControllerOptions>({
  key: CONTROLLER_COLLECT_1,
  default: DEFAULT_GAME_CONTROLLER_OPTIONS,
});

export const AControllerCollect2 = atom<TGameControllerOptions>({
  key: CONTROLLER_COLLECT_2,
  default: DEFAULT_GAME_CONTROLLER_OPTIONS,
});
