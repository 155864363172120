import styled from 'styled-components';

export const PopoverWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 166px;
  background-color: rgba(var(--primary-1), 1);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: rgba(var(--menu-txt-normal), 1);

  [data-icon] {
    height: 24px;
    color: rgba(var(--menu-icon), 1);
  }
  box-shadow: 0 3px 8px 2px rgba(var(--shdw-primary-1));
  cursor: pointer;
  z-index: 100;
`;

export const Text = styled.span`
  font-size: 15px;
  font-weight: 400;
`;
