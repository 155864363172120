import { FC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { ACurrentTab } from 'store/tabs';
import { AControllersVisible } from 'store/controllers';
import {
  ABetButton1,
  ABetButton2,
  AControllerBet1,
  AControllerBet2,
  AControllerCollect1,
  AControllerCollect2,
  ATakeWin1,
  ATakeWin2,
} from 'store/gameButtons';
import { AFreeBet } from 'store/freebet';
import { AUI } from 'store/ui';

import gameManager from 'api/gameManager/GameManager';

import ResultsHeader from 'components/ResultsHeader';

import Icon from 'shared/components/Icon';

import {
  GAME_BUTTON_MAKE_BET,
  GAME_BUTTON_CANCEL,
  GAME_BUTTON_TAKE,
} from 'constants/gameButtons';
import { GAME, BETS_MOBILE } from 'constants/nameTabs';
import { GameButton } from 'constants/gameButtons';

import GroupControllers from './components/GroupControllers';
import MobileBtn from './components/MobileBtn';
import {
  SWrapper,
  SMobileDisabled,
  SDesktop,
  SAddControlBtn,
  SMobileFooter,
  SMobileExpand,
} from './style';
import { IControllersProps } from './types';

const Controllers: FC<IControllersProps> = () => {
  const { t } = useTranslation();

  const [isControllersVisible, setControllersVisible] =
    useRecoilState(AControllersVisible);
  const { state: makeBtn1Value, enabled: enabledBet1 } =
    useRecoilValue(ABetButton1);
  const { state: makeBtn2Value, enabled: enabledBet2 } =
    useRecoilValue(ABetButton2);
  const priceWin1 = useRecoilValue(ATakeWin1);
  const priceWin2 = useRecoilValue(ATakeWin2);
  const currentTab = useRecoilValue(ACurrentTab);

  const controllerBet1 = useRecoilValue(AControllerBet1);
  const controllerBet2 = useRecoilValue(AControllerBet2);
  const controllerCollect1 = useRecoilValue(AControllerCollect1);
  const controllerCollect2 = useRecoilValue(AControllerCollect2);
  const enabledUI = useRecoilValue(AUI);
  const freeBet = useRecoilValue(AFreeBet);

  const showExtraHandler = () => {
    setControllersVisible((prev) => !prev);
  };

  const btnClickHandler1 = () => {
    gameManager.gameButtonClick(GameButton.BUTTON_1);
  };

  const btnClickHandler2 = () => {
    gameManager.gameButtonClick(GameButton.BUTTON_2);
  };

  const handleChange = (
    group: string,
    name: string,
    val: number,
    mute = false,
  ) => {
    if (group === 'group1') {
      name === 'bet'
        ? gameManager.setBetAmount(GameButton.BUTTON_1, val, mute)
        : gameManager.setCollectAmount(GameButton.BUTTON_1, val, mute);
    } else {
      name === 'bet'
        ? gameManager.setBetAmount(GameButton.BUTTON_2, val, mute)
        : gameManager.setCollectAmount(GameButton.BUTTON_2, val, mute);
    }
  };

  return (
    <SWrapper>
      <SDesktop
        isHide={currentTab !== GAME}
        isFullWidth={controllerBet2.isHidden}
      >
        <GroupControllers
          bet={controllerBet1}
          collect={controllerCollect1}
          freeBet={freeBet}
          onChange={(name, val, mute) =>
            handleChange('group1', name, val, mute)
          }
          btnClickHandler={btnClickHandler1}
          status={makeBtn1Value}
          price={priceWin1.win}
          priceFreeBets={priceWin1.freeBets}
          disabled={
            !enabledBet1 ||
            (!enabledUI && makeBtn1Value === GAME_BUTTON_MAKE_BET)
          }
          changeDisabled={makeBtn1Value !== GAME_BUTTON_MAKE_BET}
          autoBtnType={1}
        />
      </SDesktop>

      {!controllerBet2.isHidden && (
        <SMobileDisabled
          isVisible={isControllersVisible && currentTab === GAME}
        >
          <GroupControllers
            bet={controllerBet2}
            collect={controllerCollect2}
            freeBet={freeBet}
            onChange={(name, val, mute) =>
              handleChange('group2', name, val, mute)
            }
            btnClickHandler={btnClickHandler2}
            status={makeBtn2Value}
            price={priceWin2.win}
            priceFreeBets={priceWin2.freeBets}
            disabled={
              !enabledBet2 ||
              (!enabledUI && makeBtn2Value === GAME_BUTTON_MAKE_BET)
            }
            changeDisabled={makeBtn2Value !== GAME_BUTTON_MAKE_BET}
            autoBtnType={2}
          />
        </SMobileDisabled>
      )}

      <SMobileExpand isHide={currentTab === GAME}>
        <MobileBtn
          onClickHandler={btnClickHandler1}
          status={makeBtn1Value}
          price={
            makeBtn1Value === GAME_BUTTON_TAKE
              ? `${priceWin1.win}`
              : `${controllerBet1.symbol}${
                  freeBet.isActive
                    ? freeBet.coinValue
                    : controllerBet1.currentValue.toFixed(
                        controllerBet1.toFixed,
                      )
                }`
          }
          autoCollect={`${
            controllerCollect1.symbol
          }${controllerCollect1.currentValue.toFixed(
            controllerCollect1.toFixed,
          )}`}
          disabled={
            !enabledBet1 ||
            (!enabledUI && makeBtn1Value === GAME_BUTTON_MAKE_BET)
          }
          firstControl={
            makeBtn1Value === GAME_BUTTON_MAKE_BET
              ? {
                  title: t('make'),
                  subTitle: t('bet'),
                }
              : makeBtn1Value === GAME_BUTTON_CANCEL
              ? {
                  title: t('cancel'),
                }
              : { title: t('take') }
          }
          lastControl={{
            title: t('on'),
            subTitle: t('off'),
          }}
          autoBtnType={1}
          isFullWidth={controllerBet2.isHidden}
        />
        {!controllerBet2.isHidden && (
          <MobileBtn
            onClickHandler={btnClickHandler2}
            status={makeBtn2Value}
            price={
              makeBtn2Value === GAME_BUTTON_TAKE
                ? `${priceWin2.win}`
                : `${controllerBet2.symbol}${
                    freeBet.isActive
                      ? freeBet.coinValue
                      : controllerBet2.currentValue.toFixed(
                          controllerBet2.toFixed,
                        )
                  }`
            }
            autoCollect={`${
              controllerCollect2.symbol
            }${controllerCollect2.currentValue.toFixed(
              controllerCollect2.toFixed,
            )}`}
            disabled={
              !enabledBet2 ||
              (!enabledUI && makeBtn2Value === GAME_BUTTON_MAKE_BET)
            }
            firstControl={
              makeBtn2Value === GAME_BUTTON_MAKE_BET
                ? {
                    title: t('make'),
                    subTitle: t('bet'),
                  }
                : makeBtn2Value === GAME_BUTTON_CANCEL
                ? {
                    title: t('cancel'),
                  }
                : { title: t('take') }
            }
            lastControl={{
              title: t('on'),
              subTitle: t('off'),
            }}
            autoBtnType={2}
          />
        )}
      </SMobileExpand>

      <SMobileFooter>
        {!controllerBet2.isHidden && (
          <SAddControlBtn
            onClick={showExtraHandler}
            isHide={currentTab !== GAME}
          >
            <Icon name={!isControllersVisible ? 'ic_plus' : 'ic_minus'} />
          </SAddControlBtn>
        )}
        {(currentTab === GAME || currentTab === BETS_MOBILE) && (
          <ResultsHeader forceFullWidth={controllerBet2.isHidden} />
        )}
      </SMobileFooter>
    </SWrapper>
  );
};

export default Controllers;
