import { TAutoBtn } from 'store/gameButtons/autoButtons';

export const BET = 'bet';
export const COLLECT = 'collect';

export const autoButtons: TAutoBtn[] = [
  {
    groupBtnType: 1,
    type: BET,
  },
  {
    groupBtnType: 1,
    type: COLLECT,
  },
  {
    groupBtnType: 2,
    type: BET,
  },
  {
    groupBtnType: 2,
    type: COLLECT,
  },
];
