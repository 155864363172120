import { TInitData } from '../types';

export type TStickers = {
  id: number;
  m: boolean;
  t: string;
  l: string;
};

export type TStickersMap = {
  [key: number]: TStickers;
};

type TGetChatUserData = (data: TInitData) => {
  accountName: string;
  st: TStickers[];
  language: string;
  minMessageLength: number;
  maxMessageLength: number;
  nextMessageTime: number;
  dict: { [key: string]: string };
  stickersMap: TStickersMap;
};

export const getChatUserData: TGetChatUserData = (data) => {
  const { chat, dict } = data;
  const { aka, st, lc, ma_ml, mi_ml, ncmt } = chat;
  const stickersMap: TStickersMap = {};
  const stickers: TStickers[] = [];

  st?.forEach((s) => {
    stickersMap[s.id] = s;
    stickers.push(s);
  });

  return {
    accountName: aka,
    st: stickers,
    language: lc,
    maxMessageLength: ma_ml,
    minMessageLength: mi_ml,
    nextMessageTime: ncmt,
    dict,
    stickersMap,
  };
};
