export const LAST_RESULTS_LIMIT = 100;
export const LOW_RESULT_MAX = 1.4;

export const mocResults: { id: number; m: number }[] = [
  { id: 1, m: 1.0 },
  { id: 2, m: 1.0 },
  { id: 3, m: 10.39 },
  { id: 4, m: 8.5 },
  { id: 5, m: 2000.84 },
  { id: 6, m: 1.0 },
  { id: 7, m: 13.81 },
  { id: 8, m: 1.0 },
  { id: 9, m: 1000.0 },
  { id: 10, m: 134.0 },
  { id: 11, m: 134.0 },
  { id: 12, m: 1323.0 },
  { id: 13, m: 100000.0 },
];

export const COLOR_RANGE_1 = 2;
export const COLOR_RANGE_2 = 10;
export const COLOR_RANGE_3 = 100;
export const COLOR_RANGE_4 = 1000;

export enum Colors {
  FIRST_COLOR = 'firstColor',
  SECOND_COLOR = 'secondColor',
  THIRD_COLOR = 'thirdColor',
  FOURTH_COLOR = 'fourthColor',
  DEFAULT_COLOR = 'defaultColor',
}
