import styled from 'styled-components';

export const SContainer = styled.div`
  width: 100%;
  max-width: var(--game-canvas-width-d);
  margin: 0px auto;
  padding: 0 20px;
  position: relative;

  @media (min-height: 1000px) {
    max-width: var(--game-canvas-width-1000);
  }

  @media (min-height: 1200px) {
    max-width: var(--game-canvas-width-1200);
  }

  @media (min-height: 1400px) {
    max-width: var(--game-canvas-width-1400);
  }

  @media (min-height: 1600px) {
    max-width: var(--game-canvas-width-1600);
  }

  @media (max-width: 991.98px) {
    padding: 0 5px;
  }

  @media (max-width: 415px) {
    margin: 0;
    padding: 0;
  }
`;

export const SInner = styled.main`
  display: flex;

  @media (max-width: 991.98px) {
    height: var(--app-height);
  }

  @media (max-width: 720px) {
    flex-direction: column;
    height: var(--app-height);
  }

  @media (max-width: 415px) {
    height: var(--app-height);
  }
`;

export const SScreen = styled.div`
  flex: 0 0 calc(100% - var(--sidebar-width) - var(--offset-sidebar));
  max-width: calc(100% - var(--sidebar-width) - var(--offset-sidebar));

  @media (max-width: 720px) {
    flex: 0 0 auto;
    max-width: 100%;
  }
`;

export const SSidebar = styled.aside<{ screenHeight?: number }>`
  flex: 0 0 var(--sidebar-width);
  max-width: var(--sidebar-width);
  margin-left: var(--offset-sidebar);
  background-color: rgba(var(--lvl-0), 1);
  position: sticky;

  @media (max-width: 991.98px) {
    position: relative;
    top: 0;
    margin: 0;
    flex: 1 0 auto;
    // For virtual window
    height: auto;
  }

  @media (max-width: 720px) {
    max-width: 100%;
  }
`;

export const SResultsDesktop = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`;
